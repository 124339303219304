export const getVideoMeta = (file: File) => new Promise<{width: number, height: number}>((resolve, reject) => {
    const url = URL.createObjectURL(file)
    const videoElement = document.createElement("video")
    videoElement.src = url
    videoElement.addEventListener("loadedmetadata", () => {
        resolve({width: videoElement.videoWidth, height: videoElement.videoHeight})
    })
})

export const getImageMeta = (file: File) => new Promise<{width: number, height: number}>((resolve, reject) => {
    const img = new Image()
    const url = URL.createObjectURL(file)
    img.onload = () => {
        resolve({width: img.width, height: img.height})
    }
    img.src = url
})
