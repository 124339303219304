import { useNavigate, useLocation, useParams } from "react-router-dom";

export const withRouter = (ComponentWithRouter: any) => (props: any) => {
    const location = useLocation();
    const match = { params: useParams() };
    const navigate = useNavigate();
    const history = {
        back: () => navigate(-1),
        goBack: () => navigate(-1),
        location,
        push: (url: any, state: any) => navigate(url, { state }),
        replace: (url: any, state: any) => navigate(url, { replace: true, state }),
    };
    return (
        <ComponentWithRouter
            location={location}
            match={match}
            navigate={navigate}
            history={history}
            {...props}
        />
    );
};