import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"

// Organization
import Organization from "./organization/reducer"

// Editor
import { Editor } from "./editor/reducer"

// Permissions
import { Permissions } from "./permissions/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  Organization,
  Editor,
  Permissions,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer