import axios from "axios";

import { GraffityConsoleBackendURL } from './const';
import { AggregatedData, RequestLog } from "pages/Dashboard/data"
import { generateBaseHeader } from './apiHelper';

export const getMonthlyApiUsage = async (organizationId: string): Promise<AggregatedData[]> => {
    const header = await generateBaseHeader()

    const resp = await axios({
        method: "POST",
        url: GraffityConsoleBackendURL + "/private-api/v1/log/get-monthly-logs-count",
        headers: {
            ...header,
        },
        data: {
            organizationId,
        }
    })

    if (resp.status === 200) {
        return resp.data
    }

    return [];
}

export const getHourlyUsage = async (organizationId: string): Promise<AggregatedData[]> => {
    const header = await generateBaseHeader()

    const resp = await axios({
        method: "POST",
        url: GraffityConsoleBackendURL + "/private-api/v1/log/get-hourly-logs",
        headers: {
            ...header,
        },
        data: {
            organizationId,
        }
    })

    if (resp.status === 200) {
        return resp.data
    }

    return [];
}

export const getRawLogs = async (organizationId: string): Promise<RequestLog[]> => {
    const header = await generateBaseHeader()

    const resp = await axios({
        method: "POST",
        url: GraffityConsoleBackendURL + "/private-api/v1/log/get-raw-logs",
        headers: {
            ...header,
        },
        data: {
            organizationId,
        }
    })

    if (resp.status === 200) {
        return resp.data
    }

    return [];
}

export const getGeoLogs = async (organizationId: string): Promise<RequestLog[]> => {
    const header = await generateBaseHeader()

    const resp = await axios({
        method: "POST",
        url: GraffityConsoleBackendURL + "/private-api/v1/log/get-geo-logs",
        headers: {
            ...header,
        },
        data: {
            organizationId,
        }
    })

    if (resp.status === 200) {
        return resp.data
    }

    return [];
}
