import { Button, Col, Container, Input, Modal, ModalBody, ModalHeader, Row } from "reactstrap"

const editModal = (
    title: string,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    value: string,
    buttonText: string,
    onButtonClick: () => void,
    errorText: string,
    successText: string,
    placeholder: string,
    isOpen: boolean,
    toggleModal: () => void,
    disableButton: boolean,
) => <Modal isOpen={isOpen} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>{title}</ModalHeader>
        <ModalBody>
            <Container fluid>
                <Row>
                    <Col lg={9}>
                        <Input
                            type="text"
                            onChange={onChange}
                            value={value}
                            placeholder={placeholder}
                        />
                    </Col>
                    <Col lg={3}>
                        <Button
                            color="primary"
                            disabled={disableButton}
                            onClick={onButtonClick}
                            block
                        >
                            {buttonText}
                        </Button>
                    </Col>
                </Row>
                {errorText !== "" && <p className="text-danger">{errorText}</p>}
                {successText !== "" && <p className="text-success">{successText}</p>}
            </Container>
        </ModalBody>
    </Modal>

export {editModal}
