import React, { useEffect, useRef, useState } from "react";
import {
    Label,
    Row,
    Col,
    Input,
    Container,
} from "reactstrap";
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import mapboxgl from 'mapbox-gl';

import { withRouter } from "../../tsxParser/router-v6";
import { ARAnchorType, Location2D, Project, VersionType } from "./data";
import { createOrUpdateProject } from "../../apis/project";

import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import "./Projects.css";
import { OrganizationType } from "store/organization/types";


const bangkokCoor = [100.501227, 13.757226]

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN!

export const anchorTypes = [
    {
        anchorName: "World Anchor",
        desc: "AR contents in real-world location",
        img: "bi bi-globe-asia-australia",
        disabled: false,
        type: ARAnchorType.WorldAnchor,
    },
    {
        anchorName: "Image Anchor",
        desc: "Show AR contents on label images",
        img: "bi bi-images",
        disabled: false,
        type: ARAnchorType.ImageAnchor,
    },
    {
        anchorName: "Face Anchor",
        desc: "Detect people face to render AR",
        img: "bi bi-person-circle",
        disabled: false,
        type: ARAnchorType.FaceAnchor,
    },
    {
        anchorName: "Plane Anchor",
        desc: "Render AR contents on any plan",
        img: "bi bi-grid-3x3",
        disabled: true,
        type: ARAnchorType.HorizontalPlaneAnchor,
    }
]

interface CreateProjectProps {
    openNewProjectModel: boolean
    setOpenNewProjectModel: React.Dispatch<React.SetStateAction<boolean | undefined>>
    organization: OrganizationType
}

const CreateProjectModal = ({
    openNewProjectModel,
    setOpenNewProjectModel,
    organization,
}: CreateProjectProps) => {

    const defaultAnchor = ARAnchorType.WorldAnchor; // Don't change until you solve mapbox loader to display correctly while change anchor type radio

    const [isAllowedCreateProject, setIsAllowedCreateProject] = useState(false);

    // FORMS
    const [projectName, setProjectName] = useState<string>();
    const [pickedARAnchorType, setPickedARAnchorType] = useState<ARAnchorType>(defaultAnchor);
    // const [projectOptions, setProjectOptions] = useState<OptionType[]>([]);

    // PROJECT LOCATION
    const mapContainer = useRef<HTMLDivElement>(null);
    const geocoderContainer = useRef<HTMLDivElement>(null);
    const [map, setMap] = useState<mapboxgl.Map>();
    const [marker, setMarker] = useState<mapboxgl.Marker>();
    const [geocoder, setGeocoder] = useState<MapboxGeocoder>();
    const [projLocName, setProjLocName] = useState<string>();
    const [projCenter, setProjCenter] = useState<Location2D>();

    const initMap = () => {
        console.log("initMap");
        const attachMap = (setMap: React.Dispatch<React.SetStateAction<any>>, mapContainer: React.RefObject<HTMLDivElement>) => {
            const mapInit = new mapboxgl.Map({
                container: mapContainer.current || '', // NO ERROR
                style: 'mapbox://styles/mapbox/streets-v11',
                center: projCenter ? [projCenter.coordinates[0], projCenter.coordinates[1]] : [bangkokCoor[0], bangkokCoor[1]],
                zoom: 10,

                // pitch: 60, // VIEW ORIENTATION
                customAttribution: "© Graffity Technologies Co., Ltd.",
                // doubleClickZoom: false,
                // interactive: false
            });
            // mapInit.addControl(new mapboxgl.FullscreenControl());
            const marker = new mapboxgl.Marker({
                draggable: true
            })
                .setLngLat([bangkokCoor[0], bangkokCoor[1]])
                .addTo(mapInit);

            setMarker(marker);
            setMap(mapInit);
        }

        !map && attachMap(setMap, mapContainer);
    }

    const addMapSearchBox = () => {
        if (!map) return;
        // const marker = new mapboxgl.Marker({
        //     color: "#1aa6b7"
        // });
        const geocoder = new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            mapboxgl: mapboxgl,
            // countries: 'th',
            // marker: marker,
            language: 'en-US',
            placeholder: "Search to pin",
        });
        // map.addControl(geocoder);
        geocoderContainer.current?.appendChild(geocoder.onAdd(map));
        setGeocoder(geocoder);

        map.on('click', (e) => {
            const coordinates = e.lngLat;
            marker?.setLngLat([coordinates.lng, coordinates.lat]);
            setProjLocName("Custom Point");
            setProjCenter({
                type: "Point",
                coordinates: [coordinates.lng, coordinates.lat],
            });
            geocoder?.clear();
        });
    }

    const onSearchBoxResult = () => {
        if (!geocoder || !marker) return;
        geocoder.on('result', function (result) {
            // console.log(result);
            marker.setLngLat(result["result"]["center"]);

            setProjLocName(result["result"]["place_name_en-US"].split(", ")[0]);
            setProjCenter({
                type: "Point",
                coordinates: result["result"]["center"],
            });
            // console.log(result["result"]["place_name_en-US"].split(", ")[0], result["result"]["center"]);
        });
    }

    const onMarkerDragEnd = () => {
        if (!marker) return;
        marker.on('dragend', () => {
            const lngLat = marker.getLngLat();
            // console.log("lngLat", lngLat)
            setProjLocName("Custom Point");
            setProjCenter({
                type: "Point",
                coordinates: [lngLat.lng, lngLat.lat],
            });
        });
    }

    const handleProjectNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        // console.log("handleProjectNameChange", e.target.value)
        setProjectName(e.target.value);
    }

    // const handleProjectAccessTokenChange = (newValue: SingleValue<OptionType>, actionMeta: ActionMeta<OptionType>) => {
    //     setSelectedProjectAccessToken(newValue);
    // }

    const onClickLoadEditor = async () => {
        if (projectName && pickedARAnchorType && projLocName && projCenter && organization._id !== "") {
            // Parse object from str.
            let newProject: Project

            newProject = {
                name: projectName,
                // locationName: projLocName,
                // accessTokenRefId: token._id!,
                version: VersionType.Draft,
                lastModified: Date.now(),
                createdAt: Date.now(),
                organizationId: organization._id,
                lastOpenedAnchorType: pickedARAnchorType,
                previewLocations: [{
                    name: projLocName,
                    description: "",
                    location2D: projCenter,
                    altitude: 0,
                    isDefault: true
                }]
            }
            const projectId = await createOrUpdateProject(newProject);
            // console.log("onClickLoadEditor 3")
            if (projectId) {
                // console.log("onClickLoadEditor 4")
                window.open(`/projects/${projectId}?anchor_type=${pickedARAnchorType}`)
                setOpenNewProjectModel(false)
                window.location.reload()
                // setLoadProjectEditor(true);
            }
        }
    }

    useEffect(() => {
        initMap();
    }, []); //selectedProjectAccessToken // pickedARAnchorType

    useEffect(() => {
        addMapSearchBox();
    }, [map]);

    useEffect(() => {
        onSearchBoxResult();
    }, [geocoder]);

    useEffect(() => {
        onMarkerDragEnd();
    }, [marker])

    // Check is allowed to create new project
    useEffect(() => {
        switch (pickedARAnchorType) {
            case ARAnchorType.WorldAnchor:
                if (projectName && pickedARAnchorType && projLocName && projCenter) {
                    setIsAllowedCreateProject(true);
                }
                break;
            case ARAnchorType.ImageAnchor:
            case ARAnchorType.FaceAnchor:
            case ARAnchorType.HorizontalPlaneAnchor:
            case ARAnchorType.VerticalPlaneAnchor:
                if (projectName && pickedARAnchorType) {
                    setIsAllowedCreateProject(true);
                }
                break;
            default:
                setIsAllowedCreateProject(false);
                break;
        }
    }, [projectName, pickedARAnchorType, projLocName, projCenter]);

    return (
        <div id="create-project-model">
            <div className="modal-header">
                <h5 className="modal-title mt-0">Create new Project</h5>
                <button
                    type="button"
                    onClick={() => {
                        setOpenNewProjectModel(false);
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div className="modal-body">
                <div className="row mb-3">
                    <Label
                        htmlFor="horizontal-firstname-Input"
                        className="col-sm-2 col-form-label"
                    >
                        Project Name
                    </Label>
                    <Col sm={10}>
                        <input
                            className="form-control"
                            type="text"
                            defaultValue=""
                            onChange={handleProjectNameChange}
                        />
                    </Col>
                </div>

                <div className="row mb-4">
                    <Label
                        className="col-sm-2 col-form-label mt-3"
                    >
                        Choose anchor type
                        <p className="text-muted text-label-desc">
                            You can change it later.
                        </p>
                    </Label>
                    <Col sm={10}>
                        <Row className="mt-4">
                            {anchorTypes.map((anchor, i) =>
                                <Col key={"anchor-type-" + i} xl="3" sm="6" className="anchor-type-card">
                                    {anchor.disabled && <span className="badge rounded-pill bg-secondary soon">
                                        {"SOON"}
                                    </span>}
                                    <Label className="card-radio-label">
                                        <Input
                                            type="radio"
                                            name="pay-method"
                                            id="pay-methodoption3"
                                            className="card-radio-input"
                                            disabled={anchor.disabled}
                                            readOnly={anchor.disabled}
                                            defaultChecked={(anchor.type === defaultAnchor) ? true : false}
                                            value={anchor.type}
                                            onChange={(e) => {
                                                // console.log(e.target.value as ARAnchorType)
                                                setPickedARAnchorType(e.target.value as ARAnchorType)
                                            }}
                                        />
                                        <div className="card-radio">
                                            <div className="text-lg-center">
                                                {/* <img
                                                src={""}
                                                className="avatar-sm me-3 mx-lg-auto mb-3 mt-1 float-start float-lg-none rounded-circle"
                                                alt="img"
                                            /> */}
                                                <i className={`${anchor.img} text-primary align-middle`} />
                                                <h6 className="mb-1 font-size-15 text-truncate">
                                                    {anchor.anchorName}
                                                </h6>
                                                <div className="text-muted anchor-type-desc">
                                                    {anchor.desc}
                                                </div>
                                            </div>
                                        </div>
                                    </Label>
                                </Col>
                            )}
                        </Row>
                    </Col>
                </div>
                <div
                    className="row mb-4"
                    style={(pickedARAnchorType !== ARAnchorType.WorldAnchor) ? { display: "none" } : {}}
                >
                    <Label
                        htmlFor="horizontal-firstname-Input"
                        className="col-sm-2 col-form-label"
                    >
                        Pin Preview Location
                        <p className="text-muted text-label-desc">
                            You can change this later.
                        </p>
                    </Label>
                    <Col sm={10}>
                        <div className="row mb-3">
                            <div ref={geocoderContainer}></div>
                        </div>
                        <div className="row mb-4">
                            <Col sm={6}>
                                <Input
                                    type="text"
                                    className="form-control"
                                    id="project-place"
                                    placeholder="Preview Location Name"
                                    value={projLocName}
                                    onChange={(e) => setProjLocName(e.target.value)}
                                />
                            </Col>
                            <Col sm={3}>
                                <Input
                                    type="text"
                                    className="form-control"
                                    id="project-longitude"
                                    placeholder="Longitude"
                                    value={projCenter ? "Longitude " + projCenter.coordinates[0] : "Longitude 0"}
                                    disabled />
                            </Col>
                            <Col sm={3}>
                                <Input
                                    type="text"
                                    className="form-control"
                                    id="project-latitude"
                                    placeholder="Latitude"
                                    value={projCenter ? "Latitude " + projCenter.coordinates[1] : "Latitude 0"}
                                    disabled />
                            </Col>
                        </div>
                        <div className="row mb-4">
                            <Col sm={12}>
                                <div ref={mapContainer} className="map-container-for-init-proj" />
                            </Col>

                        </div>
                    </Col>
                </div>
            </div>

            <div className="modal-footer">
                <button
                    type="button"
                    onClick={() => {
                        setOpenNewProjectModel(false);
                    }}
                    className="btn btn-secondary "
                    data-dismiss="modal"
                >
                    Close
                </button>
                <button
                    type="button"
                    className="btn btn-primary btn-label align-end"
                    disabled={!isAllowedCreateProject}
                    onClick={() => {
                        onClickLoadEditor();
                    }}
                >
                    Create & Load Project Editor <i className="bi bi-geo-alt label-icon"></i>
                </button>
            </div>
        </div>
    );
};

CreateProjectModal.displayName = "Create Project Modal"

export default withRouter(CreateProjectModal);