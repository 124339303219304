import { Routes, Route, Navigate, useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { initOrganizationData, initPermissionsData } from 'store/utils'

// Import all middleware
import { PrivateRoute } from "./routes/route"

// Import Firebase Configuration file
import { initFirebaseBackend, firebaseConfig, getFirebaseBackend } from "./helpers/firebase_helper"
import firebase from 'firebase/compat/app';

// Private Pages
import Documents from "./pages/Documents/Documents"
// import AccessTokens from "./pages/AccessTokens/AccessTokens"
import SDK from "./pages/SDK/SDK"
import Projects from "./pages/Projects/Projects"
import Account from "./pages/Account/Account"
import Payment from "./pages/Payment/Payment"
import Dashboard from "./pages/Dashboard/Dashboard"
import Analytics from "./pages/Analytics/Analytics"
import { Developer } from "./pages/Developer/Developer"

// Public Pages
import Login from "./pages/Authentication/Login"
import Logout from "./pages/Authentication/Logout"
import Register from "./pages/Authentication/Register"
import ForgetPwd from "./pages/Authentication/ForgetPassword"
import Pages404 from "./pages/NotFound"
import VerifyEmail from "./pages/VerifyEmail"
import { Ar } from "./pages/Ar/Ar"

// Import scss
import "./assets/scss/theme.scss"
import ProjectEditor from "./pages/Projects/Editor/Editor"
import Invite from "pages/Invite"

// init firebase backend
initFirebaseBackend(firebaseConfig)

const App = () => {
  const dispatch = useDispatch()
  const firebaseBackend = getFirebaseBackend();
  const navigate = useNavigate();

  firebaseBackend.authChangeCallback = (user: firebase.User | null) => {
    if (!user) {
      return
    }
    if (!user.emailVerified && user.providerData[0]?.providerId === "password") {
      navigate("/verify-email")
    }
    initOrganizationData(dispatch)
    initPermissionsData(dispatch)
  }

  return (
    <Routes>
      {/* PRIVATE PAGES */}
      <Route element={<PrivateRoute />}>
        <Route path='/' element={<Navigate to="/dashboard" />} />
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/analytics' element={<Analytics />} />
        <Route path='/documents' element={<Documents />} />
        {/* <Route path='/access-tokens' element={<AccessTokens />} /> */}
        <Route path='/sdk' element={<SDK />} />
        <Route path='/projects' element={<Projects />} />
        <Route path='/projects/:projectId' element={<ProjectEditor />} />
        <Route path='/payment' element={<Payment />} />
        <Route path='/account' element={<Account />} />
        <Route path='/developer' element={<Developer />} />
      </Route>

      {/* PUBLIC PAGES */}
      <Route path='/login' element={<Login />} />
      <Route path='/register' element={<Register />} />
      <Route path='/logout' element={<Logout />} />
      <Route path='/forgot-password' element={<ForgetPwd />} />
      <Route path='/verify-email' element={<VerifyEmail />} />
      <Route path='/invite' element={<Invite />} />
      <Route path='/ar' element={<Ar />} />
      <Route path='*' element={<Pages404 />} />
    </Routes>
  );
}


export default App
export { App };
