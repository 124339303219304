import { useState } from 'react'
import { ReactComponent as MouseLeft } from "assets/icons/mouse-button-left.svg";
import { ReactComponent as MouseRight } from "assets/icons/mouse-button-right.svg";
import { ReactComponent as MouseScroll } from "assets/icons/mouse-scroll-wheel.svg";
import './usageTip.scss'
import { Button, Popover } from 'reactstrap';

export enum TipVariant {
    Regular = "regular",
    Map = "map",
}

interface usageTipProps {
    variant?: TipVariant
    defaultOpen?: boolean
}

const usageTipId = 'usage-tip-id'

const UsageTip = ({ defaultOpen = false, variant = TipVariant.Regular }: usageTipProps) => {
    const [expand, setExpand] = useState(defaultOpen);

    const toggleExpand = () => {
        setExpand(!expand)
    }

    return <div className="usageTipContainer">
        <Button id={usageTipId} onClick={toggleExpand} color="white" className={`rounded-circle ${variant === TipVariant.Regular ? "bg-light" : "bg-white"} p-0 ${expand ? "opacity-100" : "opacity-75"}`} style={{ width: 40, height: 40 }}>
            <i className='bi bi-question-lg fs-3' />
        </Button>
        {
            <Popover
                target={usageTipId}
                isOpen={expand}
                toggle={toggleExpand}
                placement='bottom'
                popperClassName='bg-light border-light rounded'
                hideArrow
                offset={[-70,8]}
            >
                <div onClick={toggleExpand} className={`p-2`}>
                    <h6>Quick Tip</h6>
                    <p className='mb-2'><MouseLeft height={24} className='me-1' />Hold left-mouse to <span className='text-primary'>{variant === TipVariant.Regular ? "Orbit" : "Pan"}</span></p>
                    <p className='mb-2'><MouseRight height={24} className='me-1' />Hold right-mouse to <span className='text-primary'>{variant === TipVariant.Regular ? "Pan" : "Orbit"}</span></p>
                    <p className='mb-0'><MouseScroll height={24} className='me-1' />Scroll mouse to <span className='text-primary'>Zoom</span></p>
                </div>
            </Popover>
        }
    </div>
}

export { UsageTip }
