import { useState } from "react";
import {
    Container,
    Card,
    CardBody,
    Table,
    Button,
    Modal,
    Row,
    Col,
    CardFooter,
    Alert,
    ModalBody,
    ModalHeader,
} from "reactstrap";

import { Project, useAllProjectsData } from "./data";
import { withRouter } from "../../tsxParser/router-v6";
import VerticalLayout from "../../components/VerticalLayout";
import Breadcrumb from "../../components/Common/Breadcrumb";

import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import "./Projects.css";
import moment from "moment";
import CreateProjectModal from "./CreateProjectModal";
import { ProjectSetting } from "./Settings/ProjectSetting";
import { ApplicationState } from "store/data";
import { connect } from "react-redux";
import { OrganizationType } from "store/organization/types";
import { Box } from "components/Common/Layout/Layout";
import { PageMeta } from "components/Common/Header";
import { Link } from "react-router-dom";
import { isConsoleAccessAllowed } from "constants/const";
import { PermissionType, UserPermission } from "store/permissions/types";


interface StateProps {
    organization: OrganizationType
    permission?: PermissionType
}

const Projects = ({ organization, permission }: StateProps) => {
    const [settingProject, setSettingProject] = useState<Project>();
    const [allProjects] = useAllProjectsData(organization._id);
    const shouldAllowAccess = isConsoleAccessAllowed(organization);
    const isOwnerPermission = permission?.userPermission === UserPermission.Owner;

    // MODAL
    const [openNewProjectModel, setOpenNewProjectModel] = useState(false);
    const [openSubscribeModal, setOpenSubscribeModal] = useState(false);

    const toggleNewProjectModel = () => {
        if (!shouldAllowAccess) {
            setOpenSubscribeModal(true)
            return
        }
        setOpenNewProjectModel(!openNewProjectModel);
    }

    const toggleProjectSetting = (project?: Project) => {
        if (!shouldAllowAccess) {
            setOpenSubscribeModal(true)
            return
        }
        if (settingProject) setSettingProject(undefined);
        else setSettingProject(project);
    }

    const projectsTableBody = () => {
        let table = allProjects.data.map((val, i) => {
            let createdAt = moment(val.createdAt).startOf('hour').fromNow();
            return (
                <tr key={"token-table-" + i}>
                    <td>{i + 1}</td>
                    <td>{val.name}</td>
                    <td>{val.previewLocations[0].name}</td>
                    <td>{createdAt}</td>
                    <td>
                        <Button
                            className="me-4"
                            color="light" outline
                            onClick={() => toggleProjectSetting(val)}
                        >
                            Settings
                        </Button>
                        <Button
                            color="secondary"
                            onClick={() => {
                                if (!shouldAllowAccess) {
                                    setOpenSubscribeModal(true)
                                    return
                                }
                                window.open(`/projects/${val._id}?anchor_type=${val.lastOpenedAnchorType}`)
                            }}
                        >
                            Edit Project
                        </Button>
                    </td>
                </tr>
            );
        });
        return table;
    }

    const alert = !shouldAllowAccess ?
        <Alert color="danger">
            <div className="d-flex align-items-center text-danger">
                <i className="bi bi-exclamation-circle fs-4 me-2" />
                <span className="me-2">
                    Please subscribe to access full functionalities of Graffity Console.
                </span>
                {isOwnerPermission &&
                    <Link to="/payment">
                        <Button color="danger">
                            Subscribe
                        </Button>
                    </Link>
                }
            </div>
        </Alert>
        : <></>

    const toggleSubscribeModal = () => {
        setOpenSubscribeModal(!openSubscribeModal)
    }

    const subscribeModal = () => (<Modal
        isOpen={openSubscribeModal}
        toggle={toggleSubscribeModal}
    >
        <ModalHeader toggle={toggleSubscribeModal} />
        <ModalBody>
            <Box display="flex" justifyContent="center">
                <i className="bi bi-exclamation-circle text-warning mb-2" style={{ fontSize: 96 }} />
            </Box>
            <h3 className="mx-auto text-center">Warning!</h3>
            <p className="mx-auto text-center">Your Organization haven't subscribe to Graffity Console yet.<br />
                Please subscribe to access all functionalities.</p>
            <Box display="flex" justifyContent="center">
                <Button color="light" onClick={toggleSubscribeModal} className="me-3">
                    Later
                </Button>
                {isOwnerPermission &&
                    <Link to="/payment">
                        <Button color="primary">
                            Subscribe
                        </Button>
                    </Link>
                }

            </Box>
        </ModalBody>
    </Modal>)

    return (
        <VerticalLayout>
            <div className="page-content" id="projects-page">
                <PageMeta title="Projects | Graffity Console" />
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb
                        title="Console"
                        breadcrumbItem="Projects"
                        alert={alert}
                    />
                    <Modal
                        isOpen={openNewProjectModel}
                        toggle={() => setOpenNewProjectModel(!openNewProjectModel)}
                        size="xl"
                    >
                        <CreateProjectModal
                            openNewProjectModel={openNewProjectModel}
                            setOpenNewProjectModel={setOpenNewProjectModel}
                            organization={organization}
                        />
                    </Modal>
                    {subscribeModal()}
                    {settingProject &&
                        <Card>
                            <CardBody className="pb-0">
                                <ProjectSetting
                                    project={settingProject}
                                />
                            </CardBody>
                            <CardFooter className="bg-white">
                                <Box display="flex" justifyContent="flex-end">
                                    <Button
                                        color="primary"
                                        onClick={() => toggleProjectSetting()}
                                    >
                                        Close
                                    </Button>
                                </Box>
                            </CardFooter>
                        </Card>
                    }

                    {allProjects.isFinished && !settingProject &&
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col lg={3}>
                                        <div className="text-start mb-4">
                                            <button
                                                type="button"
                                                className="btn btn-primary btn-label align-end"
                                                onClick={() => {
                                                    toggleNewProjectModel();
                                                }}
                                            >
                                                New Project <i className="bi bi-plus label-icon"></i>
                                            </button>
                                        </div>
                                    </Col>
                                </Row>

                                <Table hover>
                                    <thead>
                                        <tr>
                                            <th style={{ width: "5%" }}>#</th>
                                            <th style={{ width: "25%" }}>Project Name</th>
                                            <th style={{ width: "25%" }}>Default Locations</th>
                                            <th style={{ width: "20%" }}>Created at</th>
                                            <th style={{ width: "25%" }}>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {projectsTableBody()}
                                    </tbody>
                                </Table>

                            </CardBody>
                        </Card>
                    }
                </Container>
            </div>
        </VerticalLayout>
    );
}

const mapStateToProps = (state: ApplicationState): StateProps => {
    return {
        organization: state.Organization,
        permission: state.Permissions.find(permission => permission.organizationId === state.Organization._id)
    }
}

export default connect(mapStateToProps)(withRouter(Projects));