const state = process.env.REACT_APP_STAGE

export const GraffityConsoleBackendURL =
    (state === "prod") ? "https://console-backend.graffity.tech" :
    (state === "staging") ? "https://staging-console-backend.graffity.tech" :
    "http://localhost:8080"

export const MapperIosAppUrl = "https://apps.apple.com/th/app/graffity-mappers/id1613154469"
export const MapperAndroidAppUrl = "https://play.google.com/store/apps/details?id=com.graffity.mappers"
export const ViewerIosAppUrl = "https://apps.apple.com/th/app/graffity-viewer/id6451207164"
export const ViewerAndroidAppUrl = "https://play.google.com/store/apps/details?id=com.graffity.ar_viewer"