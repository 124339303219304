import { Card, CardBody, Container } from "reactstrap"
import { withRouter } from "../../tsxParser/router-v6";
import VerticalLayout from "../../components/VerticalLayout";
import Breadcrumbs from "../../components/Common/Breadcrumb"

// import Component
import FileList from "./FileList"
import RecentFile from "./RecentFile"
import { PageMeta } from "components/Common/Header";

const SDK = () => {
  return (
    <VerticalLayout>
      <div className="page-content">
        <PageMeta title="SDK | Graffity Console"/>
        <Container fluid>

          <Breadcrumbs title="Console" breadcrumbItem="SDK" />
          <div className="d-xl-flex">
            <div className="w-100">
              <div className="d-md-flex">
                <div className="w-100">
                  <Card>
                    <CardBody>
                      <FileList />
                      <RecentFile />
                    </CardBody>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </VerticalLayout>
  )
}
export default withRouter(SDK);
