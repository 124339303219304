import axios from "axios";

import { GraffityConsoleBackendURL } from './const';
import { ARContent, Project } from "../pages/Projects/data";
import { generateBaseHeader } from './apiHelper';

export const getAllProjects = async (organizationId: string): Promise<Project[]> => {
    const header = await generateBaseHeader()
    const resp = await axios({
        method: "POST",
        url: GraffityConsoleBackendURL + "/private-api/v1/project/get",
        headers: {
            ...header,
        },
        data: JSON.stringify({
            organizationId: organizationId,
        }),
    })

    if (resp.status === 200) {
        // console.log("getAllAccessTokens success");
        return resp.data
    }

    return [];
}

export const getProjectByProjectId = async (projectId: string): Promise<Project> => {
    const header = await generateBaseHeader()
    const resp = await axios({
        method: "POST",
        url: GraffityConsoleBackendURL + "/private-api/v1/project/get-by-project-id",
        headers: {
            ...header,
        },
        data: {
            _id: projectId
        }
    })

    if (resp.status === 200) {
        // console.log("getAllAccessTokens success");
        return resp.data
    }

    return {} as Project;
}

export const createOrUpdateProject = async (project: Project): Promise<string | undefined> => {
    const header = await generateBaseHeader()

    const obj = JSON.parse(localStorage.getItem("graffity-console-user")!);
    if (!obj.firebaseAuthUid) {
        return undefined;
    }

    project.firebaseAuthUid = obj.firebaseAuthUid;
    const resp = await axios({
        method: "POST",
        url: GraffityConsoleBackendURL + "/private-api/v1/project/create-or-update",
        headers: {
            ...header,
        },
        data: JSON.stringify(project)
    });

    if (resp.status === 200) {
        return resp.data
    }

    return undefined;
}

export const publishProject = async (projectId: string): Promise<ARContent[]> => {
    const header = await generateBaseHeader()

    const resp = await axios({
        method: "POST",
        url: GraffityConsoleBackendURL + "/private-api/v1/project/publish",
        headers: {
            ...header,
        },
        data: {
            _id: projectId
        }
    });

    if (resp.status === 200) {
        return resp.data
    }

    return [];
}

export const deleteProjectByProjectId = async (projectId: string): Promise<boolean> => {
    const header = await generateBaseHeader()
    if (projectId === undefined) {
        return false // "please specify id or accessTokenRefId value"
    }

    const obj = JSON.parse(localStorage.getItem("graffity-console-user")!);
    if (!obj.firebaseAuthUid) {
        return false;
    }

    const reqBody = {
        _id: projectId,
        firebaseAuthUid: obj.firebaseAuthUid,
    }
    const resp = await axios({
        method: "POST",
        url: GraffityConsoleBackendURL + "/private-api/v1/project/delete",
        headers: {
            ...header,
        },
        data: JSON.stringify(reqBody)
    });

    if (resp.status === 200) {
        // console.log("createOrUpdateProject success");
        return true // "createOrUpdateProject success"
    }

    return false; // "createOrUpdateProject fail"
}
