import { OrganizationType } from "store/organization/types"

const stage = process.env.REACT_APP_STAGE

export const customerPortalUrl = stage === "prod"
    ? "https://billing.stripe.com/p/login/3cs8yV31L1qR8yQ144"
    : "https://billing.stripe.com/p/login/test_cN26qNeJO1AV6TC288"
export const feedbackURL = "https://forms.gle/cyP5BSRPruCGRCrw5"
export const pricingTableId = stage === "prod"
    ? "prctbl_1OKWdEA7n4ihKAu810HpZuEK"
    : "prctbl_1NDMQ4A7n4ihKAu8G8SBdIcY"

export const pricingTablePk = stage === "prod"
    ? "pk_live_51LkidXA7n4ihKAu8ajBEE0FyptkgS6GvjbUH7NrVyekeZxJgtzRATzDwkwgIXE8WIXOjKIO3TehZ9Pk0e50M17k300nDiJhoTd"
    : "pk_test_51LkidXA7n4ihKAu8nQDqs61Og82y0A6KhFXhZQChyH6cKWs9i3CO7A5t76mafwQfrHpBFSugg2iOR56CVrZHtoOm009wLiQ4fW"

export const isAdmin = () => {
    const obj = JSON.parse(localStorage.getItem("graffity-console-user")!);
    return obj && obj.firebaseAuthUid === "3wrGUxxsnqbnZlTnXMfOb0GII4v1" // isAdmin is to be removed
}

export const isConsoleAccessAllowed = (organization: OrganizationType) => !organization._id || (new Date(organization.subscriptionExpireAt) > new Date(Date.now()) || organization.isSubscriptionActive)

export const defaultOrganizationName = "My First Team"

export const isV2Token = true

export const imageScale = 1000 // 1 pixel = 1mm.

export const uploadDistance = 10 // ar content distance from camera on upload
