import { customAlphabet } from "nanoid";
import React, { ChangeEvent } from "react";

import { createOrUpdateARContent, getARContentsByProjectId } from "../../apis/arContent"

const nanoid = customAlphabet('1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ', 12);

interface FileUploaderProps {
    uploadFileTypes: string
    isHide: boolean
    setSelectedFiles: React.Dispatch<React.SetStateAction<File | undefined>>
    inputRef: React.RefObject<HTMLInputElement>
}

export const FileUploader = ({
    uploadFileTypes,
    isHide,
    setSelectedFiles,
    inputRef
}: FileUploaderProps) => {

    const onUploadFilesChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files!.length > 0) {
            console.log(event.target.files)

            setSelectedFiles(event.target.files![0])
        }
    };

    return (
        <React.Fragment>
            <input
                type='file'
                accept={uploadFileTypes}
                multiple={false}
                id={nanoid()}
                ref={inputRef}
                onChange={onUploadFilesChange}
                style={{ display: isHide ? 'none' : 'block' }}
            />
        </React.Fragment>
    )

}

export const updateARContentAccessTokenByProjectId = async (projectId: string, accessTokenRefId: string) => {

    let arContents = await getARContentsByProjectId(projectId);

    if (arContents) {
        arContents.forEach((obj) => {
            let updateObject = obj
            updateObject.accessTokenRefId = accessTokenRefId
            delete updateObject._id

            createOrUpdateARContent(updateObject)
        })
    }
}