import { getStaticMapImage } from "apis/map"
import { MercatorCoordinate } from "mapbox-gl"
import { PointCloudPlace, useFloorsData } from "pages/Projects/data"
import { useEffect, useState } from "react"
import { Dispatch } from "redux"
import { changeSelectedFloor, initFloors, toggleMapOverlayAvailable } from "store/actions"
import { Box3, Vector3 } from "three"

const useFloor = (pointCloudId: string, dispatch: Dispatch) => {
    const [floors] = useFloorsData(pointCloudId)

    useEffect(() => {
        if (floors.length === 0) return
        const defaultFLoor = floors.find((floor) => floor.isDefault)
        dispatch(initFloors(floors))
        dispatch(changeSelectedFloor(defaultFLoor))
    }, [floors])

    return { floors }
}

const useStaticMap = (place: PointCloudPlace, BoundingBox: Box3 | undefined, dispatch: Dispatch) => {
    const [scale, setScale] = useState(0)
    const [image, setImage] = useState<string>()
    const mapResolution = 1280
    useEffect(() => {
        if (!place.originCoordinate || !BoundingBox) {
            dispatch(toggleMapOverlayAvailable(false))
            return
        }
        const origin = place.originCoordinate.displayOriginCoordinates
        const mercatorCoord = MercatorCoordinate.fromLngLat([origin[0], origin[1]], origin[2] || 0)
        const meterScale = mercatorCoord.meterInMercatorCoordinateUnits()
        const center = new Vector3()
        const size = new Vector3()
        BoundingBox.getCenter(center)
        BoundingBox.getSize(size)
        // const mapOrigin = {
        //     x: mercatorCoord.x + center.x * meterScale,
        //     y: mercatorCoord.y + center.y * meterScale,
        // }
        const mapOrigin = {
            x: mercatorCoord.x,
            y: mercatorCoord.y,
        }
        const borderOffset = Math.max(...size.toArray()) * 0.5
        const borderOffsetMercator = borderOffset * meterScale
        const maxCoord = new MercatorCoordinate(mapOrigin.x + borderOffsetMercator, mapOrigin.y + borderOffsetMercator).toLngLat()
        const minCoord = new MercatorCoordinate(mapOrigin.x - borderOffsetMercator, mapOrigin.y - borderOffsetMercator).toLngLat()
        const image = getStaticMapImage([minCoord.lng, maxCoord.lat, maxCoord.lng, minCoord.lat], mapResolution)
        setScale(borderOffset * 2 / mapResolution)
        setImage(image)
        dispatch(toggleMapOverlayAvailable(true))
    }, [place, BoundingBox])

    return {
        scale,
        image,
    }
}

export { useFloor, useStaticMap }
