import { List, ListInlineItem } from "reactstrap"

interface StarterAsset {
    name: string,
    iconUrl: string,
    downloadUrl: string,
    downloadUrlIos: string,
}

const starterAssetList: StarterAsset[] = [
    {
        name: "campfire",
        iconUrl: "https://graffity-public-assets.s3.ap-southeast-1.amazonaws.com/3D-icon/campfire.png",
        downloadUrl: "https://graffity-public-assets.s3.ap-southeast-1.amazonaws.com/3D/campfire.glb",
        downloadUrlIos: "https://graffity-public-assets.s3.ap-southeast-1.amazonaws.com/3D/campfire.usdz",
    },
    {
        name: "dragon_fino",
        iconUrl: "https://graffity-public-assets.s3.ap-southeast-1.amazonaws.com/3D-icon/dragon_fino.png",
        downloadUrl: "https://graffity-public-assets.s3.ap-southeast-1.amazonaws.com/3D/dragon_fino.glb",
        downloadUrlIos: "https://graffity-public-assets.s3.ap-southeast-1.amazonaws.com/3D/dragon_fino.usdz",
    },
    {
        name: "ghost",
        iconUrl: "https://graffity-public-assets.s3.ap-southeast-1.amazonaws.com/3D-icon/ghost.png",
        downloadUrl: "https://graffity-public-assets.s3.ap-southeast-1.amazonaws.com/3D/ghost.glb",
        downloadUrlIos: "https://graffity-public-assets.s3.ap-southeast-1.amazonaws.com/3D/ghost.usdz",
    },
    {
        name: "box-1m",
        iconUrl: "https://graffity-public-assets.s3.ap-southeast-1.amazonaws.com/3D-icon/box-1m.png",
        downloadUrl: "https://graffity-public-assets.s3.ap-southeast-1.amazonaws.com/3D/box-1m.glb",
        downloadUrlIos: "https://graffity-public-assets.s3.ap-southeast-1.amazonaws.com/3D/box-1m.usdz",
    },
    {
        name: "box-green-1m",
        iconUrl: "https://graffity-public-assets.s3.ap-southeast-1.amazonaws.com/3D-icon/box-green-1m.png",
        downloadUrl: "https://graffity-public-assets.s3.ap-southeast-1.amazonaws.com/3D/box-green-1m.glb",
        downloadUrlIos: "https://graffity-public-assets.s3.ap-southeast-1.amazonaws.com/3D/box-green-1m.usdz",
    },
    {
        name: "box-red-1m",
        iconUrl: "https://graffity-public-assets.s3.ap-southeast-1.amazonaws.com/3D-icon/box-red-1m.png",
        downloadUrl: "https://graffity-public-assets.s3.ap-southeast-1.amazonaws.com/3D/box-red-1m.glb",
        downloadUrlIos: "https://graffity-public-assets.s3.ap-southeast-1.amazonaws.com/3D/box-red-1m.usdz",
    },
    {
        name: "cone-green-50cm",
        iconUrl: "https://graffity-public-assets.s3.ap-southeast-1.amazonaws.com/3D-icon/cone-green-80cm.png",
        downloadUrl: "https://graffity-public-assets.s3.ap-southeast-1.amazonaws.com/3D/cone-green-50cm.glb",
        downloadUrlIos: "https://graffity-public-assets.s3.ap-southeast-1.amazonaws.com/3D/cone-green-50cm.usdz",
    },
]

interface OwnProps {
    onAssetClick: (name: string, downloadUrl: string, downloadUrlIos: string) => void;
}

const StarterAssets = ({ onAssetClick }: OwnProps) => {
    return <List type="inline">
        {
            starterAssetList.map(asset => <ListInlineItem key={asset.name}>
                <img
                    width="64"
                    height="64"
                    src={asset.iconUrl}
                    alt={asset.name}
                    onClick={() => { onAssetClick(asset.name, asset.downloadUrl, asset.downloadUrlIos) }}
                    style={{ cursor: "pointer", border: "2px solid #DCDCDC" }}
                    className="rounded"
                />
            </ListInlineItem>)
        }
    </List>
}

export { StarterAssets }