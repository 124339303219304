import React from "react";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { MapperAndroidAppUrl, MapperIosAppUrl, ViewerAndroidAppUrl, ViewerIosAppUrl } from "apis/const";

const appIcon = (variant: "Android" | "Ios", url: string) => (
  <a href={url}>
    <div style={{ width: 40, height: 40 }} className="bg-body-secondary rounded-circle ms-2 d-flex justify-content-center align-items-center">
      <i className={`bi ${variant === "Android" ? "bi-google-play" : "bi-apple"} font-size-20 text-primary`} />
    </div>
  </a>
)

const ActivityComp = () => {
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-3">Starter Guide</CardTitle>
          <ul className="verti-timeline list-unstyled">
            <li className="event-list">
              <div className="event-timeline-dot">
                <i className="bi bi-1-circle font-size-18" />
              </div>
              <Row className="d-flex justify-content-between bg-light rounded p-2">
                <Col sm={10}>
                  <h5 className="font-size-14">
                    Use Mappers to scan and upload
                  </h5>
                  <p className="mb-0">Application for recording videos to create Point Cloud</p>
                </Col>
                <Col sm={2} className="d-flex justify-content-end align-items-center">
                  {appIcon("Ios", MapperIosAppUrl)}
                  {appIcon("Android", MapperAndroidAppUrl)}
                </Col>
              </Row>
            </li>
            <li className="event-list">
              <div className="event-timeline-dot">
                <i className="bi bi-2-circle font-size-18" />
              </div>
              <Row className="d-flex justify-content-between bg-light rounded p-2">
                <Col sm={12}>
                  <h5 className="font-size-14">
                    Create project
                  </h5>
                  <p className="mb-0">Create AR project using any anchor types.</p>
                </Col>
              </Row>
            </li>
            <li className="event-list">
              <div className="event-timeline-dot">
                <i className="bi bi-3-circle font-size-18" />
              </div>
              <Row className="d-flex justify-content-between bg-light rounded p-2">
                <Col sm={12}>
                  <h5 className="font-size-14">
                    Upload assets
                  </h5>
                  <p className="mb-0">Uploads any model files in Console Editor mode.</p>
                </Col>
              </Row>
            </li>
            <li className="event-list">
              <div className="event-timeline-dot">
                <i className="bi bi-4-circle font-size-18" />
              </div>
              <Row className="d-flex justify-content-between bg-light rounded p-2">
                <Col sm={10}>
                  <h5 className="font-size-14">
                    Publish & Preview project
                  </h5>
                  <p className="mb-0">Publish your works and let the world see your contents.</p>
                </Col>
                <Col sm={2} className="d-flex justify-content-end align-items-center">
                  {appIcon("Ios", ViewerIosAppUrl)}
                  {appIcon("Android", ViewerAndroidAppUrl)}
                </Col>
              </Row>
            </li>
            <li className="event-list">
              <div className="event-timeline-dot">
                <i className="bi bi-5-circle font-size-18" />
              </div>
              <Row className="d-flex justify-content-between bg-light rounded p-2">
                <Col sm={12}>
                  <h5 className="font-size-14">
                    Invite members
                  </h5>
                  <p className="mb-0">Invite your team to help you create wonderful 3D contents.</p>
                </Col>
              </Row>
            </li>
          </ul>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default ActivityComp;