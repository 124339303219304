import React, { useEffect, useState } from "react"
import {
    Row,
    Col,
    Container,
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
} from "reactstrap"

import ApiUsageChart from "../Dashboard/components/ApiUsageChart"

import { withRouter } from "../../tsxParser/router-v6";
import VerticalLayout from "../../components/VerticalLayout/";
import Breadcrumb from "../../components/Common/Breadcrumb";
import { useApiUsageData, useGeoLogsData } from "../Dashboard/data";
// import "./Dashboard.css";
import ClusterMap from "./ClusterMap";
import Heatmap from "./Heatmap";
import { PageMeta } from "components/Common/Header";
import { ApplicationState } from "store/data";
import { connect } from "react-redux";
import { OrganizationType } from "store/organization/types";

interface StateProps {
    organization: OrganizationType
}

const Analytics: React.FC<StateProps> = ({ organization }: StateProps) => {
    const [geoLogsData, isRawLogsDataFetching] = useGeoLogsData(organization._id);
    const [apiUsageData, isApiUsageFetching] = useApiUsageData(organization._id);

    return (
        <VerticalLayout>
            <div className="page-content">
                <PageMeta title="Analytics | Graffity Console" />
                <Container fluid>
                    <Breadcrumb title="Console" breadcrumbItem="Analytics" />

                    <Row>
                        <Col lg={5}>
                            <Card>
                                <CardBody>
                                    <CardTitle>Usage Heatmap</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        {/* Display by country */}
                                    </CardSubtitle>
                                    <div id="world-map-markers" className="vector-map-height">
                                        <Heatmap
                                            rawLogsData={geoLogsData}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={7}>
                            <ApiUsageChart apiUsageData={apiUsageData} />
                            <Card>
                                <CardBody>
                                    <CardTitle>Usage Clusters</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        {/* Display by country */}
                                    </CardSubtitle>
                                    <div id="world-map-markers" className="vector-map-height">
                                        <ClusterMap
                                            rawLogsData={geoLogsData}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </VerticalLayout>
    )
}

const mapStateToProps = (state: ApplicationState): StateProps => {
    return {
        organization: state.Organization,
    }
};

export default connect(mapStateToProps)(withRouter(Analytics));
