import axios from "axios";

import { GraffityConsoleBackendURL } from './const';
import { AccessToken } from '../pages/AccessTokens/helpers';
import { generateBaseHeader } from './apiHelper';

export const createAccessToken = async (token: AccessToken) => {
    const header = await generateBaseHeader()
    if (token.raw === undefined || token.firebaseAuthUid === undefined) {
        return "please specify hash for token value"
    }

    const resp = await axios({
        method: "POST",
        url: GraffityConsoleBackendURL + "/private-api/v1/access-token/create",
        headers: {
            ...header,
        },
        data: JSON.stringify(token)
    })

    if (resp.status === 200) {
        // console.log("createAccessToken success");
        return resp.data["_id"]
    }

    return "createAccessToken fail";
}

export const getAccessTokenByDatabaseId = async (databaseId: string): Promise<AccessToken | undefined> => {
    const header = await generateBaseHeader()

    const resp = await axios({
        method: "POST",
        url: GraffityConsoleBackendURL + "/private-api/v1/access-token/get-by-database-id",
        headers: {
            ...header,
        },
        data: JSON.stringify({
            _id: databaseId
        })
    })

    if (resp.status === 200) {
        // console.log("getAllAccessTokens success");
        return resp.data
    }

    return undefined
}

export const getAllAccessTokens = async (firebaseAuthUid: string): Promise<AccessToken[]> => {
    const header = await generateBaseHeader()
    const resp = await axios({
        method: "POST",
        url: GraffityConsoleBackendURL + "/private-api/v1/access-token/get",
        headers: {
            ...header,
        },
        data: JSON.stringify({
            firebaseAuthUid: firebaseAuthUid
        })
    })

    if (resp.status === 200) {
        // console.log("getAllAccessTokens success");
        return resp.data
    }

    return [];
}

export const deleteAccessToken = async (accessTokenId: string) => {
    const header = await generateBaseHeader()
    await axios({
        method: "POST",
        url: GraffityConsoleBackendURL + "/private-api/v1/access-token/delete",
        headers: {
            ...header,
        },
        data: JSON.stringify({
            accessTokenId: accessTokenId
        })
    })
}

export const generateV2AccessToken = async (projectId: string, isPermanent = false): Promise<string> => {
    const header = await generateBaseHeader()

    const resp = await axios({
        method: "POST",
        url: GraffityConsoleBackendURL + "/private-api/v1/access-token/create-v2",
        headers: {
            ...header,
        },
        data: JSON.stringify({
            projectId,
            isPermanent,
        })
    })

    if (resp.status === 200) {
        return resp.data
    }

    return "";
}
