import React, { useEffect } from "react";
import { withRouter } from "../../tsxParser/router-v6";
import { connect } from 'react-redux'

import VerticalLayout from "../../components/VerticalLayout";
import { OrganizationType } from "store/organization/types";
import { customerPortalUrl, pricingTableId, pricingTablePk, isConsoleAccessAllowed } from 'constants/const';
import { ApplicationState } from "store/data";
import { PageMeta } from "components/Common/Header";
import { PermissionType, UserPermission } from "store/permissions/types";

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'stripe-pricing-table': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
        }
    }
}

interface StateProps {
    organization: OrganizationType
    permission: PermissionType
}

const Payment = (props: StateProps) => {
    const user = JSON.parse(localStorage.getItem("graffity-console-user")!);
    const isSubscriptionExpired = !isConsoleAccessAllowed(props.organization)
    const isOwnerPermission = props.permission?.userPermission === UserPermission.Owner;

    useEffect(() => {
        if (!isOwnerPermission) {
            window.location.replace('/');
            return
        }
        if (!isSubscriptionExpired) {
            const userEmail = encodeURIComponent(user?.email)
            window.open(`${customerPortalUrl}?prefilled_email=${userEmail}`);
            window.location.replace('/');
        }
    }, [isSubscriptionExpired])

    if (!user || !isSubscriptionExpired) {
        return <></>
    }
    const firebaseAuthUid = user.firebaseAuthUid;
    const email = user.email;

    return (
        <VerticalLayout>
            <div className="page-content">
                <PageMeta title="Payment | Graffity Console"/>
                <stripe-pricing-table
                    pricing-table-id={pricingTableId}
                    publishable-key={pricingTablePk}
                    customer-email={email}
                    client-reference-id={firebaseAuthUid}>
                </stripe-pricing-table>
            </div>
        </VerticalLayout >
    );
};

const mapStateToProps = (state: ApplicationState) => {
    return {
        organization: state.Organization,
        permission: state.Permissions.find(permission => permission.organizationId === state.Organization._id)
    }
};

export default connect(mapStateToProps)(withRouter(Payment));
