import "./layout.scss"

type justifyContentType = "flex-start" | "flex-end" | "center" | "space-around" | "space-between"
type displayType = "block" | "flex"
type spacingType = "S" | "M" | "L" | "XL"
type alignItemType = "center"
type bootstrapColorType =
    | "primary"
    | "secondary"
    | "success"
    | "danger"
    | "warning"
    | "info"
    | "light"
    | "dark"
    | "muted"
    | "white"

interface BoxProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    display?: displayType;
    justifyContent?: justifyContentType;
    padding?: spacingType;
    marginBottom?: spacingType;
    alignItem?: alignItemType;
    fullWidth?: boolean;
    background?: bootstrapColorType;
    borderRadius?: "rounded";
    borderColor?: bootstrapColorType | "gray-400";
}

// Basically a <div/> but with props instead of CSS
const Box = (props: BoxProps) => {
    const constructClassName = (propsClass: string | undefined) => {
        let ClassString = `Box ${propsClass}`
        switch (props.display) {
            case "flex":
                ClassString += " displayFlex"
                break;
            default:
                break;
        }
        switch (props.justifyContent) {
            case "flex-start":
                ClassString += " justifyStart"
                break;
            case "flex-end":
                ClassString += " justifyEnd"
                break;
            case "center":
                ClassString += " justifyCenter"
                break;
            case "space-around":
                ClassString += " justifySpaceAround"
                break;
            case "space-between":
                ClassString += " justifySpaceBetween"
                break;
            default:
                break;
        }
        switch (props.marginBottom) {
            case "S":
                ClassString += " marginBottomS"
                break;
            case "M":
                ClassString += " marginBottomM"
                break;
            case "L":
                ClassString += " marginBottomL"
                break;
            default:
                break;
        }
        switch (props.alignItem) {
            case "center":
                ClassString += " alignItemCenter"
                break;
            default:
                break;
        }
        switch (props.padding) {
            case "S":
                ClassString += " paddingS"
                break;
            case "M":
                ClassString += " paddingM"
                break;
            case "L":
                ClassString += " paddingL"
                break;
            case "XL":
                ClassString += " paddingXL"
                break;
            default:
                break;
        }
        if (props.fullWidth) ClassString += " w-100";
        if (props.background) ClassString += ` bg-${props.background}`
        if (props.borderRadius) ClassString += " rounded-3"
        if (props.borderColor) ClassString += ` border border-${props.borderColor}`
        return ClassString
    }
    return <div className={constructClassName(props.className)}>
        {props.children}
    </div>
}

export { Box }
