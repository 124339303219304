import { useState, useEffect } from "react";
import {
    Container,
    Row,
    Col,
    CardBody,
    Form,
    Label,
    Input,
    FormFeedback,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useDispatch } from "react-redux";

import avatar from "../../assets/images/dummy-user.png";
// actions
import { resetProfileFlag } from "../../store/actions";
import { Box } from "components/Common/Layout/Layout";
import { User, updateUserInfo } from "apis/user";
import { getFirebaseBackend } from "helpers/firebase_helper";

const Profile = () => {
    const dispatch = useDispatch();
    const firebaseBackend = getFirebaseBackend()

    const [user, setUser] = useState<User>({
        displayName: "",
        email: "",
        createdAt: 0,
        firebaseAuthUid: "",
    });
    const [userProfileImageURL, setUserProfileImageURL] = useState(avatar)
    const [updateSuccess, setUpdateSuccess] = useState(false)

    useEffect(() => {
        if (localStorage.getItem("graffity-console-user")) {
            const obj = JSON.parse(localStorage.getItem("graffity-console-user")!);
            setUser(obj)

            if (obj.photoURL) setUserProfileImageURL(obj.photoURL)

            setTimeout(() => {
                dispatch(resetProfileFlag());
            }, 3000);
        }
    }, [dispatch]);

    const validation = useFormik<User>({
        enableReinitialize: true,
        initialValues: {
            displayName: user.displayName,
            email: user.email,
            createdAt: user.createdAt,
            firebaseAuthUid: user.firebaseAuthUid,
        },
        validationSchema: Yup.object({
            displayName: Yup.string().required("Please Enter Your Display Name"),
        }),
        onSubmit: (values) => {
            firebaseBackend.updateUserProfile(values)
            updateUserInfo(values).then(resp => {
                if (resp) {
                    const reqBody = {
                        ...user,
                        ...values,
                    }
                    localStorage.setItem("graffity-console-user", JSON.stringify(reqBody));
                }
            })
            setUpdateSuccess(true)
        }
    });

    return (
        <div className="bg-light bg-opacity-50 rounded">
            <CardBody>
                <Container fluid>
                    <Row className="bg-light rounded border border-dark border-opacity-10 bg-opacity-75 py-2 mb-2">
                        <Col lg="12">
                            <div className="d-flex">
                                <div className="ms-3">
                                    <img
                                        src={userProfileImageURL}
                                        alt=""
                                        className="avatar-md rounded-circle img-thumbnail"
                                    />
                                </div>
                                <div className="flex-grow-1 align-self-center ms-4">
                                    <div className="text-muted">
                                        <h5>{user.displayName}</h5>
                                        <p className="mb-0">Account Role: Admin</p>
                                        <p className="mb-1">Email: {user.email}</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row >
                        <Col className="p-0" lg={12}>
                            <p>Personal Information</p>
                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    return false;
                                }}
                            >
                                <div className="mb-3">
                                    <Label className="form-label">Display Name</Label>
                                    <Input
                                        id="displayName"
                                        name="displayName"
                                        className="form-control"
                                        placeholder="Enter Display Name"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.displayName || ""}
                                        data-testid="input-display-name"
                                        invalid={
                                            validation.touched.displayName && validation.errors.displayName ? true : false
                                        }
                                    />
                                    {validation.touched.displayName && validation.errors.displayName ? (
                                        <FormFeedback data-testid="error-display-name" type="invalid">{validation.errors.displayName || ""}</FormFeedback>
                                    ) : null}
                                </div>
                                {updateSuccess && <p className="text-success" data-testid="profile-success-message">Profile Updated, the change will be applied after a refresh</p>}
                                <Box
                                    background="light"
                                    padding="M"
                                    borderColor="dark"
                                    borderRadius="rounded"
                                    display="flex"
                                    justifyContent="center"
                                    className="bg-opacity-75 border-opacity-10"
                                >
                                    <button
                                        className="mb-0 text-primary border-0 bg-light"
                                        type="submit"
                                        data-testid="save-button"
                                        onClick={() => { console.log(validation) }}
                                    >
                                        Save changes
                                    </button>
                                </Box>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </CardBody>
        </div>
    );
};

export { Profile };