export enum UserPermission {
    Owner = "owner",
	Viewer = "viewer",
	Editor = "editor",
    Pending = "pending",
}

export interface PermissionType {
    _id?: string;
    createdAt?: string; // ISO string
    userId?: string;
    organizationId: string;
    userPermission: UserPermission;
    userDisplayName?: string;
    orgDisplayName: string;
    email?: string;
}
