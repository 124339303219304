import { Floor } from "pages/Projects/data"
import { Input } from "reactstrap"

interface FloorSelectorProps {
    floors: Floor[]
    onFloorChange: (floorId: string) => void
    selectedFloorId: string | undefined
}

const FloorSelector = ({floors, selectedFloorId, onFloorChange}: FloorSelectorProps) => {
    return <Input
        type="select"
        onChange={(e) => {
            onFloorChange(e.target.value)
        }}
        value={selectedFloorId || ""}
    >
        {floors.map(floor =>
            <option key={floor._id} value={floor._id}>
                {floor.displayName}
            </option>
        )}
    </Input>
}

export { FloorSelector }