import React, { useState } from "react";
import {
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    Container,
    Button,
    Card,
    CardBody,
} from "reactstrap";

// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';

import { withRouter } from "../../../tsxParser/router-v6";
import { Project } from "../data";

import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import "../Projects.css";
import GeneralSetting from "./GeneralSetting";
import AccessTokenSetting from "./AccessTokenSetting";
import PreviewLocationSetting from "./PreviewLocationSetting";
import { Box } from "components/Common/Layout/Layout";
import "./setting.scss"
import { LiteButton } from "components/Common/LiteButton/LiteButton";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN!

export interface SettingProps {
    project: Project
}

interface ProjectSettingModalProps {
    project: Project
    isOpenModal: boolean
    toggleModal: () => void
}

const ProjectSetting = ({
    project,
}: SettingProps) => {

    const [activeTab, setActiveTab] = useState(0);

    const projectSettings = [
        {
            title: "General",
            component: <GeneralSetting project={project} />,
        },
        {
            title: "Access token",
            component: <AccessTokenSetting project={project} />,
        },
        {
            title: "Preview location",
            component: <PreviewLocationSetting project={project} />,
        },
    ]

    const navButton = (title: string, selected: boolean, index: number) => (
        <Box fullWidth marginBottom="S">
            <LiteButton
                block
                highlight={selected}
                border="none"
                onClick={() => { setActiveTab(index) }}
                key={title}
            >
                <span className="fs-6 fw-medium">{title}</span>
            </LiteButton>
        </Box>
    )

    return (
        <React.Fragment>
            <Container fluid className="settingContainer">
                <Row className="">
                    <Col lg={2}>
                        {projectSettings.map((setting, index) => navButton(
                            setting.title,
                            activeTab === index,
                            index
                        ))}
                    </Col>
                    <Col lg={10}>
                        <Card className="bg-opacity-50" color="light">
                            <CardBody>
                                {
                                    projectSettings[activeTab].component
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
};

const ProjectSettingModal = ({
    project,
    isOpenModal,
    toggleModal
}: ProjectSettingModalProps) => {
    return (
        <React.Fragment>
            <Modal
                isOpen={isOpenModal}
                toggle={toggleModal}
                size="xl"
                fullscreen={true}
            >
                <ModalHeader toggle={toggleModal}>
                    Project Settings
                </ModalHeader>
                <ModalBody>
                    <ProjectSetting
                        project={project}
                    />
                </ModalBody>

                <div className="modal-footer">
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={toggleModal}
                    >
                        Close
                    </button>
                </div>
            </Modal>
        </React.Fragment>
    );
};

ProjectSettingModal.displayName = "ProjectSettingModal"

export default withRouter(ProjectSettingModal);
export { ProjectSetting }
