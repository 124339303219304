import { useEffect, useRef, useState } from "react"
import { Map, MapRef, Source, Layer } from 'react-map-gl';
import React from "react";
import { RequestLog } from "../Dashboard/data";
import { clusterLayer, clusterCountLayer, unclusteredPointLayer } from '../Dashboard/components/MapboxLayers';
import mapboxgl from 'mapbox-gl';

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN!

interface ClusterMapProps {
  rawLogsData: RequestLog[];
}

const ClusterMap = ({ rawLogsData }: ClusterMapProps) => {
  const mapRef = useRef<MapRef>(null);
  const [geojsonData, setGeoJsonData] = useState<GeoJSON.FeatureCollection>();

  const prepGeoJsonData = () => {
    let geoJsonColl: GeoJSON.FeatureCollection = {
      type: "FeatureCollection",
      features: []
    }
    rawLogsData.forEach(e => {
      if (e.positionGps.coordinates[0]) {
        let data: GeoJSON.Feature = {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [e.positionGps.coordinates[0], e.positionGps.coordinates[1]],
          },
          properties: {
            id: e._id,
          },
        };
        geoJsonColl.features.push(data);
      }
    });
    // console.log("geojson", geoJsonColl.features.length)
    setGeoJsonData(geoJsonColl);
  }

  useEffect(() => {
    if (rawLogsData) {
      prepGeoJsonData();
    }
  }, [rawLogsData])

  return (
    <React.Fragment>
      <Map
        initialViewState={{
          longitude: 100.5259941,
          latitude: 13.7339379,
          zoom: 4
        }}
        style={{ height: 500 }}
        mapStyle="mapbox://styles/mapbox/dark-v11"
        mapboxAccessToken={MAPBOX_TOKEN}
        ref={mapRef}
      >
        <Source
          id="logs-data"
          type="geojson"
          data={geojsonData}
          cluster={true}
          clusterMaxZoom={14}
          clusterRadius={50}
        >
          <Layer {...clusterLayer} />
          <Layer {...clusterCountLayer} />
          <Layer {...unclusteredPointLayer} />
        </Source>
      </Map>
    </React.Fragment>
  )
}

export default ClusterMap
