import { AnyAction } from 'redux'

import { INIT_PERMISSIONS } from "./actions"

import { PermissionType } from "./types";

const INIT_STATE: PermissionType[] = []

const Permissions = (state = INIT_STATE, action: AnyAction) => {
    switch (action.type) {
        case INIT_PERMISSIONS:
            return [...action.payload]

        default:
            return state
    }
}

export { Permissions }
