import { useState, useEffect } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Button,
    Table,
    Badge,
} from "reactstrap";
import SimpleBar from "simplebar-react";

import { PermissionType, UserPermission } from "store/permissions/types";
import { getAllPermissionsForOrganization, createPermission } from "apis/permission";
import { createOrUpdateOrganization } from "apis/organization"

import dummyOrganization from "../../assets/images/dummy-organization.svg";
import { OrganizationType } from "store/organization/types";
import { getOrganizationByOrganizationId } from "apis/organization";
import { defaultOrganizationName } from "constants/const";
import { connect, useDispatch } from "react-redux";
import { ApplicationState } from "store/data";
import { initOrganization } from "store/actions";
import { editModal } from "components/Common/EditModal/EditModal";

interface StateProps {
    organization: OrganizationType;
    permissions: PermissionType[];
}

const dateOption: Intl.DateTimeFormatOptions = {
    formatMatcher: "basic",
    dateStyle: "medium",
    timeStyle: "short",
}

const OrganizationComponent = (props: StateProps) => {
    const [organizationPermissions, setOrganizationPermissions] = useState<PermissionType[]>([])
    const [inputEmail, setInputEmail] = useState("");
    const [inputOrganizationName, setInputOrganizationName] = useState("");
    const [addUserModal, setAddUserModal] = useState(false);
    const [renameModal, setRenameModal] = useState(false);
    const [disableModalAction, setDisableModalAction] = useState(false);
    const [addError, setAddError] = useState("");
    const [addSuccess, setAddSuccess] = useState("");
    const [renameError, setRenameError] = useState("");
    const permission = props.permissions.find(permission => permission.organizationId === props.organization._id)
    const isOwner = permission?.userPermission === UserPermission.Owner
    const dispatch = useDispatch()

    useEffect(() => {
        getAllPermissionsForOrganization(props.organization._id).then(data => {
            if (!data) {
                return
            }
            setOrganizationPermissions(data)
        })
        setInputOrganizationName(props.organization?.displayName || "")
    }, [props.organization]);

    const toggleAddUserModal = () => setAddUserModal(!addUserModal)
    const toggleRenameModal = () => setRenameModal(!renameModal)

    const projectsTableBody = () => {
        let table = organizationPermissions.map((permission, index) => {
            return (
                <tr key={"token-table-" + index}>
                    <td>{index + 1}</td>
                    <td>{permission.email} {permission.userPermission === UserPermission.Pending ? <Badge color="light" className="text-secondary">Pending</Badge> : <></>}</td>
                    <td>{permission.userPermission}</td>
                    <td>{permission.createdAt ? Intl.DateTimeFormat("en-US", dateOption).format(new Date(permission.createdAt)) : ""}</td>
                </tr>
            );
        });
        return table;
    }

    const handleAddUser = (userEmail: string) => {
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(userEmail)) {
            setAddError("invalid email")
            return
        }
        const permission: PermissionType = {
            email: userEmail,
            userPermission: UserPermission.Editor,
            organizationId: props.organization._id,
            orgDisplayName: props.organization.displayName || "",
        }
        setDisableModalAction(true)
        createPermission(permission).then(data => {
            setDisableModalAction(false)
            if (data === 200) {
                setAddError("")
                getAllPermissionsForOrganization(props.organization._id).then(data => {
                    setOrganizationPermissions(data)
                })
                setAddSuccess("Invitation sent to user's email")
                return
            }
            setAddError("Please make sure the email owner have a Graffity account.")
            return
        }).catch(() => {
            setDisableModalAction(false)
            setAddError("Please make sure the email owner have a Graffity account.")
            return
        })
    }

    const handleOrganizationRename = (newName: string) => {
        if (props.organization?._id === undefined) {
            setRenameError("Missing _id field of the organization")
            return
        }
        const newOrganization: OrganizationType = {
            ...props.organization,
            displayName: newName
        }
        setDisableModalAction(true)
        createOrUpdateOrganization(newOrganization).then(data => {
            setDisableModalAction(false)
            if (data === 200) {
                setRenameError("")
                setRenameModal(false)
                getOrganizationByOrganizationId(props.organization?._id).then(data => {
                    if (data === undefined) {
                        return
                    }
                    setInputOrganizationName(data?.displayName || "")
                    dispatch(initOrganization(data))
                })
                return
            }
            setAddError("Please make sure the email owner have a Graffity account.")
            return
        }).catch(() => {
            setDisableModalAction(false)
            setAddError("Please make sure the email owner have a Graffity account.")
            return
        })
    }
    
    return (
        <Card color="light">
            {
                editModal(
                    "Rename Your Team",
                    (e: React.ChangeEvent<HTMLInputElement>) => {
                        setInputOrganizationName(e.target.value)
                    },
                    inputOrganizationName,
                    "Submit",
                    () => handleOrganizationRename(inputOrganizationName),
                    renameError,
                    "",
                    "",
                    renameModal,
                    toggleRenameModal,
                    disableModalAction
                )
            }
            {
                editModal(
                    "Invite Team Members",
                    (e: React.ChangeEvent<HTMLInputElement>) => {
                        setInputEmail(e.target.value)
                    },
                    inputEmail,
                    "Send Invite",
                    () => handleAddUser(inputEmail),
                    addError,
                    addSuccess,
                    "Email of the member you want to add",
                    addUserModal,
                    toggleAddUserModal,
                    disableModalAction
                )
            }
            <CardBody>
                <Container fluid className="fullHeight">
                    <Row>
                        <Col lg="9">
                            <Row>
                                <div style={{ width: "180px" }}>
                                    <img
                                        src={dummyOrganization}
                                        alt=""
                                        className="organizationImage"
                                    />
                                </div>
                                <Col>
                                    <h4>{props.organization?.displayName || defaultOrganizationName}{isOwner && 
                                        <i 
                                            className={`font-size-16 align-middle me-2 mdi mdi-pencil clickable`} onClick={toggleRenameModal}
                                        />}
                                    </h4>
                                    <p>Updated at: {props.organization ? Intl.DateTimeFormat("en-US", dateOption).format(new Date(props.organization.updatedAt)) : ""}</p>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg="3" className="inviteButtonContainer">
                            <div>
                                {isOwner &&
                                    <Button color="primary" onClick={toggleAddUserModal}>
                                        <i className={`font-size-16 align-middle me-2 mdi mdi-plus`} /> Invite Member
                                    </Button>
                                }
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <Card className="tableContainer">
                                <SimpleBar style={{ maxHeight: "100%" }}>
                                    <CardBody>
                                        <Table hover>
                                            <thead>
                                                <tr>
                                                    <th style={{ width: "5%" }}>#</th>
                                                    <th style={{ width: "40%" }}>Member Email</th>
                                                    <th style={{ width: "25%" }}>Permission</th>
                                                    <th style={{ width: "30%" }}>Added at</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {projectsTableBody()}
                                            </tbody>
                                        </Table>
                                    </CardBody>
                                </SimpleBar>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </CardBody>
        </Card>
    );
};

OrganizationComponent.displayName = "Organization"

const mapStateToProps = (state: ApplicationState): StateProps => {
    return {
        organization: state.Organization,
        permissions: state.Permissions,
    }
}

const Organization = connect(mapStateToProps)(OrganizationComponent)

export { Organization };