import axios from "axios"
import { generateBaseHeader } from "./apiHelper"
import { GraffityConsoleBackendURL } from "./const"
import { Floor } from "pages/Projects/data"

export const getFloors = async (placeId: string): Promise<Floor[]> => {
    const header = await generateBaseHeader()
    const resp = await axios({
        method: "POST",
        url: GraffityConsoleBackendURL + "/private-api/v1/floor/get",
        headers: {
            ...header,
        },
        data: {
            placeId
        },
    })

    if (resp.status === 200) {
        // console.log("update user success");
        return resp.data;
    }
    console.error("getFloors fail");
    return [];
}
