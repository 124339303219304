import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Container,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
} from "reactstrap"

import ApiUsageChart from "./components/ApiUsageChart"

import { withRouter } from "../../tsxParser/router-v6";
import VerticalLayout from "../../components/VerticalLayout/";
import Breadcrumb from "../../components/Common/Breadcrumb";
import WelcomeCard from "./components/WelcomeCard";
import ActivityComp from "./components/ActivityComp";
import { useApiUsageData, useOrgSummaryData } from "./data";
import "./Dashboard.css";
import { PageMeta } from "components/Common/Header";
import { ApplicationState } from "store/data";
import { connect } from "react-redux";
import { OrganizationType } from "store/organization/types";
import hello from "assets/images/hello.png"

interface StateProps {
  organization: OrganizationType
}

const Dashboard: React.FC<StateProps> = ({organization}: StateProps) => {
  const [apiUsageData, isApiUsageFetching] = useApiUsageData(organization._id);
  const [organizationSummary, isOrgSummaryFetching] = useOrgSummaryData(organization._id)

  return (
    <VerticalLayout>
      <div className="page-content">
        <PageMeta title="Dashboard | Graffity Console"/>
        <Container fluid>
          <Breadcrumb title="Console" breadcrumbItem="Dashboard" />
          <Row>
            <Col lg={5}>
              <img src={hello} alt="hello" className="w-100 mb-2"/>
              <WelcomeCard apiUsageData={apiUsageData} organizationSummary={organizationSummary} />
            </Col>
            <Col lg={7}>
              <ActivityComp />
              <ApiUsageChart apiUsageData={apiUsageData} />
            </Col>
          </Row>
        </Container>
      </div>
    </VerticalLayout>
  )
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  return {
      organization: state.Organization,
  }
};

export default connect(mapStateToProps)(withRouter(Dashboard));
