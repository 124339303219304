import { getOrganizationByOrganizationId, getOrganizationByUserId } from 'apis/organization'
import { initOrganization, changeOrganization, initPermissions } from './actions'
import { Dispatch } from 'redux'
import { getAllPermissionsForUser } from 'apis/permission';
import { UserStorage } from 'apis/user';
import { OrganizationType } from './organization/types';

const initOrganizationData = async(dispatch: Dispatch) => {
    const obj: UserStorage = JSON.parse(localStorage.getItem("graffity-console-user") || "");
    let organization: OrganizationType | undefined
    if (obj.organizationId) {
        organization = await getOrganizationByOrganizationId(obj.organizationId)
    } else {
        organization = await getOrganizationByUserId(obj.firebaseAuthUid)
    }
    if (organization !== undefined) {
        dispatch(initOrganization(organization));
    }
}

const changeOrganizationData = async(organizationId: string, dispatch: Dispatch) => {
    const user: UserStorage = JSON.parse(localStorage.getItem("graffity-console-user") || "");
    const organization = await getOrganizationByOrganizationId(organizationId);
    if (organization !== undefined) {
        dispatch(changeOrganization(organization));
        user.organizationId = organizationId
        localStorage.setItem("graffity-console-user", JSON.stringify(user));
    }
}

const initPermissionsData = async(dispatch: Dispatch) => {
    const permissions = await getAllPermissionsForUser()
    if (permissions !== undefined) {
        dispatch(initPermissions(permissions));
    }
}

export {initOrganizationData, changeOrganizationData, initPermissionsData}
