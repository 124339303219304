import { Box } from "components/Common/Layout/Layout"
import { Input, Label } from "reactstrap"
import { InputType } from "reactstrap/types/lib/Input"

import "./labeledInput.scss"

const LabeledInput = (({ title, value, type, onChange, onStateChange, inputComponent }: {
    title: string,
    value: number,
    type: InputType,
    onChange: (value: any) => void,
    onStateChange: (value: any) => void,
    inputComponent: React.ReactNode,
}) => {
    const handleChange = (isBlur: boolean) => (value: any) => {
        if (!isNaN(parseFloat(value))) {
            onChange(value)
            return
        }
        if (isBlur) {
            onChange(0)
        }
        onStateChange(value)
    }

    return <>
        <div className="text-muted">
            <Box display="flex" justifyContent="space-between" alignItem="center" className="mb-2">
                <Label className="mb-0">{title}</Label>
                <Box display="flex">
                    <Input
                        className="labelInput"
                        bsSize="sm"
                        value={value}
                        type={type}
                        onChange={(e) => {
                            handleChange(false)(e.target.value)
                        }}
                        onBlur={(e) => {
                            handleChange(true)(e.target.value)
                        }}
                    />
                </Box>
            </Box>
            {inputComponent}
        </div>
    </>
})

export { LabeledInput }
