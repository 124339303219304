import JSZip from "jszip";
import mapboxgl from "mapbox-gl";
import { ARContent, PointCloudPlace } from "pages/Projects/data";
import * as THREE from "three";
import { PLYLoader } from "three/examples/jsm/loaders/PLYLoader";

export const pointcloudLoader = (scene: THREE.Scene, pointcloud: PointCloudPlace) => {
    const loader = new PLYLoader();
    loader.load(
        pointcloud.publicPointCloudFile,
        (geometry) => {

            const material = new THREE.PointsMaterial({ vertexColors: true, size: 0.75 }); // , vertexColors: true , alphaMap: texture
            const mesh = new THREE.Points(geometry, material);

            mesh.position.set(0, 0, 0)

            // don't change ortation below due to it ralative on scaling order
            // mesh.rotateY(pointcloud.rotationXYZ[1])
            // mesh.rotateZ(pointcloud.rotationXYZ[2])
            // mesh.rotateX(pointcloud.rotationXYZ[0])
            const quaternion = pointcloud.originCoordinate.displayRotation

            mesh.quaternion.set(quaternion.x, quaternion.y, quaternion.z, quaternion.w)

            mesh.scale.multiplyScalar(pointcloud.originCoordinate.multiplyScalar);

            mesh.castShadow = true;
            mesh.receiveShadow = true;

            scene.add(mesh);
        }
    )
}

export const getModelTransform = (arContent: ARContent, originMc: THREE.Vector3, meterScale: number) => {
    const modelOrigin: [number, number] = [arContent.location2D.coordinates[0], arContent.location2D.coordinates[1]];
    const modelAltitude = arContent.altitude;

    const modelMc = mapboxgl.MercatorCoordinate.fromLngLat(
        modelOrigin,
        modelAltitude
    );

    // transformation parameters to position, rotate and scale the 3D model onto the map
    const modelTransform = {
        translateX: (modelMc.x - originMc.x) / meterScale,
        translateY: (modelMc.y - originMc.y) / meterScale,
        translateZ: -modelAltitude,
        rotateX: arContent.rotation.x * (Math.PI / 180) - (Math.PI / 2),
        rotateY: arContent.rotation.y * (Math.PI / 180),
        rotateZ: arContent.rotation.z * (Math.PI / 180),
        scaleX: arContent.scale.x,
        scaleY: arContent.scale.y,
        scaleZ: arContent.scale.z,
    };

    return modelTransform;
}

export const getSceneTransform = (lng: number, lat: number) => {
    const modelOrigin: [number, number] = [lng, lat];
    const modelAltitude = 0;
    const modelRotate = [Math.PI, 0, 0];

    const modelAsMercatorCoordinate = mapboxgl.MercatorCoordinate.fromLngLat(
        modelOrigin,
        modelAltitude
    );

    // transformation parameters to position, rotate and scale the 3D model onto the map
    const modelTransform = {
        translateX: modelAsMercatorCoordinate.x,
        translateY: modelAsMercatorCoordinate.y,
        translateZ: modelAsMercatorCoordinate.z,
        rotateX: modelRotate[0],
        rotateY: modelRotate[1],
        rotateZ: modelRotate[2],
        /* Since the 3D model is in real world meters, a scale transform needs to be
        * applied since the CustomLayerInterface expects units in MercatorCoordinates.
        */
        scale: modelAsMercatorCoordinate.meterInMercatorCoordinateUnits()
    };
    return modelTransform;
}

export const getRaycasterParams = (point: mapboxgl.Point, canvas: HTMLCanvasElement, camera: THREE.Camera) => {
    var mouse = new THREE.Vector2();
    mouse.x = (point.x / canvas.clientWidth) * 2 - 1;
    mouse.y = 1 - (point.y / canvas.clientHeight) * 2;
    const camInverseProjection = camera.projectionMatrix.clone().invert()
    const cameraPosition = new THREE.Vector3().applyMatrix4(camInverseProjection);
    const mousePosition = new THREE.Vector3(mouse.x, mouse.y, 1).applyMatrix4(camInverseProjection);
    const viewDirection = mousePosition.clone().sub(cameraPosition).normalize();
    return [cameraPosition, viewDirection]
}

const blackListedObjectType = [
    'TransformControlsPlane',
    'TransformControlsGizmo',
]

const blackListedObjectName = [
    'X',
    'Y',
    'Z',
    'XY',
    'YZ',
    'XZ',
    'XYZ',
]

export const filterBlacklistedObject = (intersects: THREE.Intersection[]) => {
    const filteredIntersect = intersects.filter(intersect => (
        !blackListedObjectType.includes(intersect.object.type) &&
        !blackListedObjectName.includes(intersect.object.name)
    ))
    return filteredIntersect
}

export const checkStatus = (response: Response) => {
    // From: https://gist.github.com/irbull/42f3bd7a9db767ce72a770ded9a5bdd1
    if (!response.ok) {
        throw new Error(`HTTP ${response.status} - ${response.statusText}`);
    }
    return response;
}

export const getExtension = (filename: string) => {
    return filename.toLowerCase().split('.').pop();
}

export const getFileUrl = async (file: JSZip.JSZipObject) => {
    const blob = await file.async('blob');
    const url = URL.createObjectURL(blob);
    return url;
}
