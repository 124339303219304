export interface LayerConfig {
    path: string
    modelScale: number
    modelRotate: number[]
    hasAxis?: boolean
}

export enum EditorErrorType {
    ArContentError
}

export interface EditorError {
    type: EditorErrorType,
    objectid: string,
    message: string,
}
