import React, { useState, useEffect } from "react"

import { Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"

import avatar1 from "../../../assets/images/dummy-user.png"
import profileImg from "../../../assets/images/profile-img.png"
import { AggregatedData } from "../data";
import { OrganizationSummary } from "apis/organization"

interface WelcomeCardProps {
  apiUsageData: AggregatedData[];
  organizationSummary: OrganizationSummary | undefined;
}

const WelcomeCard = ({ apiUsageData, organizationSummary }: WelcomeCardProps) => {
  const apiCount = apiUsageData.reduce((acc, val) => {
    return acc + val.dataCount
  }, 0);
  const [username, setusername] = useState("")
  const [userEmail, setEmail] = useState("")
  const [userProfileImageURL, setUserProfileImageURL] = useState(avatar1)

  const profileImageImageOnError = (e: any) => {
    console.log(e)
    if (e !== null) setUserProfileImageURL(avatar1);
  }

  useEffect(() => {
    if (localStorage.getItem("graffity-console-user")) {
      const obj = JSON.parse(localStorage.getItem("graffity-console-user")!)
      setusername(obj.displayName)
      setEmail(obj.email)
      if (obj.photoURL) setUserProfileImageURL(obj.photoURL)
    }
  }, [])

  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="bg-primary bg-soft">
          <Row>
            <Col xs="7">
              <div className="text-primary p-3">
                <h5 className="text-primary">Welcome Back !</h5>
                <p>Graffity Dashboard</p>
              </div>
            </Col>
            <Col xs="5" className="align-self-end">
              <img src={profileImg} alt="" className="img-fluid" />
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row>
            <Col sm="4">
              <div className="avatar-md profile-user-wid mb-4">
                <img
                  src={userProfileImageURL}
                  // onLoad={profileImageOnLoad}
                  onError={profileImageImageOnError}
                  alt=""
                  className="img-thumbnail rounded-circle"
                />
              </div>
              <h5 className="font-size-15 text-truncate">{username}</h5>
              <p className="text-muted mb-0 text-truncate">{userEmail}</p>
            </Col>

            <Col sm="8">
              <div className="pt-4">
                <Row>
                  <Col xs="6">
                    <h5 className="font-size-15">{organizationSummary?.projects || 0}</h5>
                    <p className="text-muted mb-0">Projects</p>
                  </Col>
                  <Col xs="6">
                    <h5 className="font-size-15">{apiCount}</h5>
                    <p className="text-muted mb-0">Requests</p>
                  </Col>
                </Row>
                <div className="mt-4">
                  <Link
                    to=""
                    className="btn btn-primary  btn-sm"
                  >
                    View Account <i className="mdi mdi-arrow-right ms-1"></i>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default WelcomeCard
