import axios from "axios";

import { GraffityConsoleBackendURL } from './const';
import { Poi } from "../pages/Projects/data";
import { generateBaseHeader } from './apiHelper';

export const getPois = async (projectId: string, pointCloudId: string): Promise<Poi[]> => {
    const header = await generateBaseHeader()
    const resp = await axios({
        method: "POST",
        url: GraffityConsoleBackendURL + "/private-api/v1/poi/get",
        headers: {
            ...header,
        },
        data: {
            projectId: projectId,
            pointCloudRefId: pointCloudId,
        }
    })

    if (resp.status === 200) {
        return resp.data
    }

    return [];
}

export const createOrUpdatePoi = async (Poi: Poi): Promise<string> => {
    try {
        const header = await generateBaseHeader()
        const resp = await axios({
            method: "POST",
            url: GraffityConsoleBackendURL + "/private-api/v1/poi/create-or-update",
            headers: {
                ...header,
            },
            data: JSON.stringify(Poi)
        })

        if (resp.status === 200) {
            return resp.data
        }
        return ""
    } catch {
        console.error("Create AR Content failed");
        return ""
    }
}

export const deletePoi = async (Poi: Poi): Promise<boolean> => {
    const header = await generateBaseHeader()
    const resp = await axios({
        method: "POST",
        url: GraffityConsoleBackendURL + "/private-api/v1/poi/delete",
        headers: {
            ...header,
        },
        data: JSON.stringify(Poi)
    })

    if (resp.status === 200) {
        console.log("DELETE SUCCESS")
        return true
    }

    console.log("DELETE FAILED")
    return false
}
