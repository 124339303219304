import { ControlMode } from "../TransformControl/TransformControl"
import Icon from '@mdi/react';
import { mdiRotateOrbit, mdiAxisArrow, mdiResize } from '@mdi/js';
import './toolsBar.scss'

const controlItems = [
    {
        type: ControlMode.Translate,
        icon: mdiAxisArrow,
    },{
        type: ControlMode.Rotate,
        icon: mdiRotateOrbit,
    },{
        type: ControlMode.Scale,
        icon: mdiResize,
    },
]


interface ToolBarProps {
    onControlModeChange: (newControlMode: ControlMode) => void
    selectedControlMode: ControlMode
    onUndo: () => void
    onRedo: () => void
}

const ToolsBar = ({onControlModeChange, selectedControlMode, onUndo, onRedo}: ToolBarProps) => {
    return <div className="toolsBar">
        {controlItems.map(controlItem => <div
            key={controlItem.type} 
            className={`toolItem ${selectedControlMode === controlItem.type ? 'toolItem--selected' : ''}`}
            onClick={() => onControlModeChange(controlItem.type)}
            data-testid={`change-control-${controlItem.type}`}
        >
            <Icon path={controlItem.icon} size="16px" color="#404856"/>
        </div>)}
        <div style={{height: 20}} className="border-end border-secondary ms-2 me-2"></div>
        <div
            key="undo" 
            className={`toolItem`}
            onClick={onUndo}
            data-testid="undo-button"
        >
            <i className="bi bi-arrow-counterclockwise toolIcon" color="#404856"/>
        </div>
        <div
            key="redo" 
            className={`toolItem`}
            onClick={onRedo}
            data-testid="redo-button"
        >
            <i className="bi bi-arrow-clockwise toolIcon" color="#404856"/>
        </div>
    </div>
}

ToolsBar.displayName = "ToolsBar"

export {ToolsBar}
