import React, { useEffect, useState } from "react"
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { customAlphabet } from "nanoid";
import SimpleBar from "simplebar-react";
import {
    Button,
    Col,
    FormGroup,
    Input,
    Row,
} from "reactstrap";
import { ARAnchorType, ARContent, ARContentType, Location2D, Poi, PointCloudPlace, Project, Vector3, VersionType } from "../../data";
import { createOrUpdateARContent } from "../../../../apis/arContent";

import "toastr/build/toastr.min.css";
import { filterARContentAndARAnchorType, getContentFromARContentId } from "../utils";
import { UploadManager } from "../common/UploadManager/UploadManager";
import "./leftSideMenu.scss"
import { Box } from "components/Common/Layout/Layout";
import { feedbackURL } from "constants/const";
import { ApplicationState } from "store/data";
import { connect } from "react-redux";
import { EditorState, SelectedObjectType } from "store/editor/types";
import { Dispatch } from "redux";
import { changeSelectedArContent, toggleMapOverlay } from "store/actions";
import { ARContentRow } from "./ARContentMenu/ARContentRow";
import { ARContentMenu } from "./ARContentMenu/ARContentMenu";
import { PoiMenu } from "./PoiMenu/PoiMenu";
import { UploadMultiple } from "../common/UploadManager/UploadMultiple";
import { useObjectSelect } from "../groupHook";

const MAX_ANCHOR_IMAGE_SIZE = 10485760 // 10MB
export const iosFileTypes = ".usdz"
const nanoid = customAlphabet('abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890', 15);

enum PageEnum {
    asset,
    poi
}

const pageList = [
    {
        text: "Assets",
        icon: "bi bi-box",
    },
    {
        text: "POI",
        icon: "bi bi-pin-map",
    },
]

const pageButton = (
    text: string,
    icon: string,
    selected: boolean,
    onClick: () => void,
) => (
    <Col key={text} lg={6}>
        <Box fullWidth>
            <Button data-testid={`page-button-${text}`} color="white" block onClick={onClick} className="border border-0">
                <Box display="flex" justifyContent="center" alignItem="center">
                    {iconBox(<i className={icon} />)}<span className={`ms-1 ${selected ? "" : "opacity-50"}`}>{text}</span>
                </Box>
            </Button>
            <hr className={`mt-0 mb-2 ${selected ? "border-primary border-2 border-opacity-75 opacity-100" : ""} `} />
        </Box>
    </Col>
)

const iconBox = (icon: React.ReactNode) => (
    <Box className="py-1 px-2 rounded text-primary bg-primary bg-opacity-10 fs-6" style={{ width: "24px", height: "24px" }}>
        {icon}
    </Box>
)

export enum ToastType {
    info, warning, error, success
}

const formatBytes = (bytes: number, decimals = 2) => {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

interface OwnProps {
    arContents: ARContent[]
    onAddARContent: (object: ARContent) => void
    onUpdateARContent: (index: number, object: ARContent) => void
    onEditorRefresh: () => void
    pois: Poi[]
    onCreatePoi: (positon: Vector3) => void
    project: Project
    place: PointCloudPlace | null
}

interface StateProps {
    editorState: EditorState
}

interface DispatchProps {
    dispatch: Dispatch
}

type Props = OwnProps & StateProps & DispatchProps

const LeftSideMenuComponent = ({ project, arContents, onAddARContent, onUpdateARContent, onEditorRefresh, pois, onCreatePoi, editorState, place, dispatch }: Props) => {
    const navigate = useNavigate();
    const [urlParams] = useSearchParams();
    const [mapCenter] = useState(urlParams.get('center'));
    const [page, setPage] = useState<PageEnum>(PageEnum.asset)
    const [hideUploadButton, setHideUploadButton] = useState(true);
    const anchorType = urlParams.get('anchor_type');
    const detectionImageId = urlParams.get('detection_image_id');
    const faceAnchorId = urlParams.get('face_anchor_id');
    const floorId = editorState.selectedFloor?._id
    const [pickedDetectionImage, setPickedDetectionImage] = useState<ARContent | null>();
    const {
        selectObject,
        addObjectToSelect,
        clearSelection
    } = useObjectSelect(editorState, dispatch, arContents)
    const referenceImage = arContents.find(
        arContent => arContent &&
            arContent.arContentType === ARContentType.ReferenceImage &&
            arContent.indoorPointCloudRefId === place?._id &&
            (editorState.floors.length <= 1 || arContent.floorId === editorState.selectedFloor?._id)
    )
    const pickedFaceParent = faceAnchorId && getContentFromARContentId(arContents, faceAnchorId);
    const placeOrigin = [
        place?.originCoordinate?.displayOriginCoordinates[0] || place?.location.coordinates[0][0],
        place?.originCoordinate?.displayOriginCoordinates[1] || place?.location.coordinates[0][1],
    ]


    useEffect(() => {
        const detectionImages = filterARContentAndARAnchorType(arContents, ARAnchorType.ImageAnchor, ARContentType.DetectionImage);
        if (detectionImageId) {
            setPickedDetectionImage(getContentFromARContentId(detectionImages, detectionImageId));
        } else {
            setPickedDetectionImage(null);
        }
    }, [arContents, detectionImageId]);

    // Creating AR content without file
    // default to face parent
    const createARContentNoImport = async () => {
        let newObjects: ARContent[] = []
        const projectRefId = project._id!
        let loc: Location2D = {
            type: "Point",
            coordinates: [
                mapCenter ? parseFloat(mapCenter!.split(",")[0]) : project.previewLocations[0].location2D.coordinates[0],
                mapCenter ? parseFloat(mapCenter!.split(",")[1]) : project.previewLocations[0].location2D.coordinates[1]
            ]
        }

        const faceAnchorList = filterARContentAndARAnchorType(arContents, ARAnchorType.FaceAnchor, ARContentType.FaceParent)
        const fileName = `Face${faceAnchorList.length + 1}`

        let newARContent: ARContent = {
            arContentId: nanoid(),
            projectRefId: projectRefId,
            parentId: "",
            accessTokenRefId: project.accessTokenRefId,
            lastModified: Date.now(),
            createdAt: Date.now(),
            arContentType: ARContentType.FaceParent,
            arAnchorType: project.lastOpenedAnchorType,
            fileExtWithoutDot: "glb",
            userFilename: fileName,
            version: VersionType.Draft,

            location2D: loc,
            storeId: "",
            storeUrl: "",
            downloadUrl: "",
            isPublicStore: false,
            altitude: 10,
            scale: {
                x: 1.0,
                y: 1.0,
                z: 1.0,
            },
            rotation: {
                x: 0.0,
                y: 0.0,
                z: 0.0,
            },
            position: {
                x: 0.0,
                y: 0.0,
                z: 0.0,
            },
            userFilenameIos: "",
            storeUrlIos: "",
            iosDownloadUrl: "",
            isFrontFacing: true,
            renderRadiusInMeter: 100,
            isIndoorPointCloud: false,
        }
        // Update to Database
        await createOrUpdateARContent(newARContent);
        newObjects.push(newARContent);

        setTimeout(() => {
            let arContentId = newObjects.pop()?.arContentId;
            if (arContentId) {
                navigate(`?anchor_type=${project.lastOpenedAnchorType}&face_anchor_id=${arContentId}`);
            }
            window.location.reload();
        }, 1200);
    }

    const handleUploadSuccess = (addedArContents: ARContent[], shouldRefresh: boolean, redirectAnchorType?: ARAnchorType, redirectAnchorId?: string) => {
        addedArContents.forEach(addedArContent => {
            const arContentIndex = arContents.findIndex(e => e._id === addedArContent._id)
            if (arContentIndex !== -1) {
                onUpdateARContent(arContentIndex, addedArContent)
            } else {
                onAddARContent(addedArContent);
            }
        })
        if (shouldRefresh) {
            onEditorRefresh()
        }
    }

    const handleUploadSessionFinish = () => {
    }

    useEffect(() => {
        switch (anchorType) {
            case ARAnchorType.ImageAnchor:
                if (detectionImageId) setHideUploadButton(false);
                else setHideUploadButton(true)
                break;
            case ARAnchorType.WorldAnchor:
                setHideUploadButton(false);
                break;
            case ARAnchorType.FaceAnchor:
                if (faceAnchorId) setHideUploadButton(false);
                else setHideUploadButton(true)
                break;
            case ARAnchorType.VerticalPlaneAnchor:
                setHideUploadButton(false);
                break;
            default:
                break;
        }
    }, [anchorType, detectionImageId, faceAnchorId]);

    useEffect(() => {
        if (editorState.selectedObjectType === SelectedObjectType.ArContent) {
            setPage(PageEnum.asset)
        } else {
            setPage(PageEnum.poi)
        }
    }, [editorState.selectedObjectType])

    return (
        <Box background="white" className="h-100 ps-3 py-3" data-testid="ar-content-menu">
            <SimpleBar className={`simpleBarContainer ${page === PageEnum.poi ? "simpleBarContainer-mini" : ""} pe-3`}>
                {place?._id && <>
                    <a href={`${window.location.pathname}?anchor_type=world_anchor&center=${placeOrigin[0]},${placeOrigin[1]}`} data-testid="navigate-to-map-button">
                        <Button block color="light" className="mb-3">
                            <i className="bi bi-arrow-return-left me-3" />
                            Back to Outdoor
                        </Button>
                    </a>
                    <h6>Indoor World Anchor</h6>
                </>}
                {(anchorType === ARAnchorType.ImageAnchor) &&
                    <>
                        <div>
                            {pickedDetectionImage ?
                                <p>Image Anchor</p>
                                : <>
                                    <Box fullWidth>
                                        <Box fullWidth marginBottom="M">
                                            <UploadManager
                                                project={project}
                                                detectionImageId={detectionImageId}
                                                faceAnchorId={faceAnchorId}
                                                pointCloudId={place?._id}
                                                floorId={floorId}
                                                onUploadSuccess={handleUploadSuccess}
                                                variant="detectionImage"
                                            />
                                        </Box>
                                    </Box>
                                    <Box fullWidth>
                                        <p className="mb-0">Upload an anchor image <code>.jpg</code> (max {formatBytes(MAX_ANCHOR_IMAGE_SIZE)})</p>
                                        <p><code>1 millimeter will translate to 10 pixels</code> for detection image</p>
                                    </Box>
                                </>
                            }
                        </div>
                        {pickedDetectionImage &&
                            <div className="accordion" id="accordion">
                                <ARContentRow
                                    onAddArContentToSelect={addObjectToSelect}
                                    onArContentSelect={selectObject}
                                    onClearArContent={clearSelection}
                                    arContent={pickedDetectionImage}
                                    data-testid="ar-detection-image"
                                />
                            </div>
                        }
                        <hr className="mb-3" />
                    </>
                }
                {(anchorType === ARAnchorType.FaceAnchor) &&
                    <>
                        <p className="mb-1">The average person's face width is <code>16 cm.</code> Your model may be too large for a face anchor.</p>
                        <div
                            id="upload-anchor-image"
                            className="mb-2"
                        >
                            {!pickedFaceParent && <>
                                <p className="mb-0">Add new Face Anchor</p>
                                <i
                                    className="bi bi-plus-circle font-size-16 text-primary"
                                    onClick={() => {
                                        createARContentNoImport();
                                    }}
                                />
                            </>}

                        </div>
                        {pickedFaceParent &&
                            <div className="accordion" id="accordion">
                                <ARContentRow
                                    arContent={pickedFaceParent}
                                    onAddArContentToSelect={addObjectToSelect}
                                    onArContentSelect={selectObject}
                                    onClearArContent={clearSelection}
                                    data-testid="ar-face-parent-row"
                                />
                            </div>
                        }
                        <hr className="mb-3" />
                    </>}
                {(anchorType === ARAnchorType.WorldAnchor && place?._id) &&
                    <>
                        <div
                            id="upload-anchor-image"
                            className="mb-2"
                        >
                            {!referenceImage && <Box fullWidth>
                                <Box fullWidth>
                                    <Box fullWidth marginBottom="M">
                                        <UploadManager
                                            project={project}
                                            faceAnchorId={faceAnchorId}
                                            pointCloudId={place?._id}
                                            floorId={floorId}
                                            onUploadSuccess={handleUploadSuccess}
                                            defaultPosition={editorState.objectInitialPosition}
                                            variant="referenceImage"
                                        />
                                    </Box>
                                </Box>
                                <p className="mb-0">Upload a floor plan image <code>.jpg</code> (max {formatBytes(MAX_ANCHOR_IMAGE_SIZE)})</p>
                            </Box>
                            }
                        </div>
                        {referenceImage &&
                            <div className="accordion" id="accordion">
                                <ARContentRow
                                    arContent={referenceImage}
                                    onAddArContentToSelect={addObjectToSelect}
                                    onArContentSelect={selectObject}
                                    onClearArContent={clearSelection}
                                    data-testid="ar-reference-image"
                                />
                            </div>
                        }
                        <hr className="mb-3" />
                    </>
                }
                <div className="w-100">
                    <h6>AR Contents</h6>
                    <Box fullWidth marginBottom="M">
                        <UploadMultiple
                            project={project}
                            detectionImageId={detectionImageId}
                            faceAnchorId={faceAnchorId}
                            mapCenter={mapCenter}
                            pointCloudId={place?._id}
                            floorId={floorId}
                            onUploadSuccess={handleUploadSuccess}
                            onUploadSessionFinish={handleUploadSessionFinish}
                            defaultPosition={editorState.objectInitialPosition}
                            data-testid="starter-asset-button"
                            variant="starterAssets"
                        />
                    </Box>
                </div>
                <hr className="mb-3" />
                {
                    place?._id && <Row className="g-0">
                        {pageList.map((item, idx) => pageButton(
                            item.text,
                            item.icon,
                            idx === page,
                            () => { setPage(idx) }
                        ))}
                    </Row>
                }
                {
                    page === PageEnum.asset
                        ? <ARContentMenu
                            data-testid="ar-content-menu"
                            arContents={arContents}
                            editorState={editorState}
                            onAddArContentToSelect={addObjectToSelect}
                            onArContentSelect={selectObject}
                            onClearArContent={clearSelection}
                            onAddARContent={onAddARContent}
                        />
                        : <PoiMenu
                            pois={pois}
                            data-testid="poi-menu"
                            onAddPoi={() => {
                                const camPosition = editorState.objectInitialPosition
                                onCreatePoi(camPosition)
                            }}
                        />
                }

            </SimpleBar>
            <Box fullWidth background="white" className="uploadManager">
                {!hideUploadButton && page === PageEnum.asset &&
                    <>
                        <UploadMultiple
                            project={project}
                            detectionImageId={detectionImageId}
                            faceAnchorId={faceAnchorId}
                            mapCenter={mapCenter}
                            pointCloudId={place?._id}
                            floorId={floorId}
                            onUploadSuccess={handleUploadSuccess}
                            onUploadSessionFinish={handleUploadSessionFinish}
                            defaultPosition={editorState.objectInitialPosition}
                            data-testid="upload-button"
                        />
                        <hr />
                    </>
                }
                <Link to={feedbackURL} target="_blank" rel="noopener noreferrer">
                    <Button
                        outline
                        block
                    >
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItem="center"
                        >
                            <i className="font-size-16 bi bi-chat-right-dots me-2 mt-1" />
                            <div className="me-2">Feedback</div>
                            <div><i className="font-size-16 mdi mdi-open-in-new"></i></div>
                        </Box>
                    </Button>
                </Link>
            </Box>
        </Box>
    )
};

const mapStateToProps = (state: ApplicationState): StateProps => ({
    editorState: state.Editor,
})

const LeftSideMenu = connect(mapStateToProps)(LeftSideMenuComponent)

export { LeftSideMenuComponent };
export default LeftSideMenu;