import { getFirebaseBackend } from "helpers/firebase_helper"

const getCookieValue = (name: string) => (
    document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || ''
)

const getAuthToken = async () => {
    const rawToken = getCookieValue("firebase.token");
    if (rawToken.length !== 0) {
        return `Bearer ${rawToken}`
    }
    const firebase = getFirebaseBackend();
    const token = await firebase.getRefreshedToken();
    return `Bearer ${token}`
}

export const generateBaseHeader = async () => {
    return {
        "Content-Type": "application/json",
        "Authorization": await getAuthToken(),
    }
}
