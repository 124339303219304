import React, { Suspense, useRef, useState } from 'react'
import { ARContent, ARContentType, Project, ARAnchorType } from "pages/Projects/data";
import toastr from "toastr";
import { Button, Col, Container, Modal, ModalBody, Progress, Row } from "reactstrap";
import { Box } from 'components/Common/Layout/Layout';
import { useDropzone } from 'react-dropzone';
import { AssetPreview } from './AssetPreview';
import "./uploadManager.scss";
import { StarterAssets } from './StarterAsset';
import { getDefaultDropzoneOption, getDefaultIosDropzoneOption, getUploadMessage, MAX_ANCHOR_IMAGE_SIZE, MAX_FILE_SIZE, UploadStatusType, UploadType, useUpload } from './uploadHook';

const formatBytes = (bytes: number, decimals = 2) => {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

enum ToastType {
    info, warning, error, success
}

const showToastr = (type: ToastType, message: string) => {
    toastr.options = {
        positionClass: "toast-bottom-left",
        showEasing: "swing",
        hideEasing: "linear",
        showDuration: 300,
        hideDuration: 500,
        timeOut: 3000,
        closeButton: true,
        progressBar: true,
    }

    if (type === ToastType.info) toastr.info(message, "");
    else if (type === ToastType.warning) toastr.warning(message, "");
    else if (type === ToastType.error) toastr.error(message, "");
    else toastr.success(message, "");
}

const mapUploadTypeToToastType = (uploadType: UploadStatusType): ToastType => {
    switch (uploadType) {
        case UploadStatusType.info:
            return ToastType.info
        case UploadStatusType.success:
            return ToastType.success
        case UploadStatusType.warning:
            return ToastType.warning
        case UploadStatusType.error:
            return ToastType.error
        default:
            return ToastType.info
    }
}

interface OwnProps {
    project?: Project;
    detectionImageId?: string | null;
    faceAnchorId?: string | null;
    mapCenter?: string | null;
    pointCloudId?: string | null;
    floorId?: string | null;
    defaultPosition?: THREE.Vector3;
    onUploadSuccess: (addedObject: ARContent[], shouldRefresh: boolean, redirectAnchorType?: ARAnchorType, redirectAnchorId?: string) => void;
    onUploadSessionFinish?: () => void;
    variant?: "full" | "detectionImage" | "referenceImage" | "edit" | "mini" | "starterAssets";
    arContent?: ARContent;
}

type UploadManagerProps = OwnProps

/**
 * Use UploadMultiple for AR Content creation, this component is for editing
 * and special ar content creation (Anchor object and reference image)
 * 
 */
const UploadManager = ({
    project,
    detectionImageId = null,
    faceAnchorId = null,
    mapCenter = null,
    pointCloudId = null,
    floorId = null,
    onUploadSuccess,
    onUploadSessionFinish,
    defaultPosition,
    variant = "full",
    arContent,
}: UploadManagerProps) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const is3dModel = arContent && arContent.arContentType === ARContentType.Model3d
    const detectionImageFileField = useRef<HTMLInputElement>(null);
    const referenceImageFileField = useRef<HTMLInputElement>(null);

    const handleUploadStatusChange = (type: UploadStatusType, message: string) => {
        const toastType = mapUploadTypeToToastType(type)
        showToastr(toastType, message)
    }

    const {
        enableUpload,
        uploadProgress,
        createARContentFromImport,
        createARContentFromExistingAsset,
        storeIOSARContent,
        storeArContent,
        resetUpload,
    } = useUpload(
        onUploadSuccess,
        handleUploadStatusChange,
        project,
        detectionImageId,
        faceAnchorId,
        mapCenter,
        pointCloudId,
        floorId,
        arContent,
        defaultPosition,
        variant === "detectionImage" ? UploadType.anchor : UploadType.arContent,
    )

    const onImportFilesChange = (
        selectedFiles: File[] | FileList,
        arContentType?: ARContentType,
        maxFileSize = MAX_FILE_SIZE,
    ) => {
        if (selectedFiles) {
            const rightSize: File[] = []
            for (let i = 0; i < selectedFiles.length; i++) {
                let file = selectedFiles[i]
                if (file) {
                    if (file.size > maxFileSize) {
                        showToastr(ToastType.error, `File ${file.name} is too large.`)
                    } else {
                        rightSize.push(file)
                    }
                }
            }
            if (rightSize.length <= 0) return;
            if (variant === 'edit') {
                storeArContent(rightSize)
            } else {
                createARContentFromImport(rightSize, arContentType);
            }
        }
    };

    const onDrop = (acceptedFiles: File[] | FileList) => {
        onImportFilesChange(acceptedFiles)
    }
    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        disabled: !enableUpload || (!!arContent && variant !== 'edit'),
        multiple: false,
        ...getDefaultDropzoneOption()
    })

    const onIosDrop = (acceptedFiles: File[] | FileList) => {
        storeIOSARContent(acceptedFiles)
    }
    const { getRootProps: getIosRootProps, getInputProps: getIosInputProps } = useDropzone({
        onDrop: onIosDrop,
        disabled: !enableUpload || arContent === undefined,
        multiple: false,
        ...getDefaultIosDropzoneOption()
    })

    const toggleModal = () => {
        if (isModalOpen && arContent) {
            onUploadSessionFinish && onUploadSessionFinish()
            resetUpload()
        }
        if (pointCloudId && !floorId) {
            showToastr(ToastType.error, "Please select floor before uploading contents.")
            return
        }
        setIsModalOpen(!isModalOpen)
    }

    const getDefaultDisplay = () => {
        switch (variant) {
            case "full":
                return <Button
                    color="primary"
                    block
                    onClick={toggleModal}
                    data-testid="upload-button-full"
                >
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItem="center"
                    >
                        <i className="font-size-20 bi bi-cloud-arrow-up me-2" />
                        <div>Upload</div>
                    </Box>
                </Button>
            case "detectionImage":
                return <Button
                    color="primary"
                    outline
                    block
                    onClick={() => { detectionImageFileField.current?.click() }}
                    data-testid="upload-button-detectionImage"
                >
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItem="center"
                    >
                        <Col lg={2}><i className="font-size-16 mdi mdi-plus" /></Col>
                        <Col lg={8}>Upload Anchor</Col>
                    </Box>
                </Button>
            case "referenceImage":
                return <Button
                    color="primary"
                    outline
                    block
                    onClick={() => {
                        if (!floorId) {
                            showToastr(ToastType.error, "Please select floor before uploading floor plan.")
                            return
                        }
                        referenceImageFileField.current?.click()
                    }}
                    disabled={floorId === undefined}
                    data-testid="upload-button-referenceImage"
                >
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItem="center"
                    >
                        <div className='me-2'><i className="font-size-16 mdi mdi-plus" /></div>
                        <div>Upload Floor Plan</div>
                    </Box>
                </Button>
            case "edit":
                return <Button
                    color="primary"
                    block
                    onClick={toggleModal}
                    data-testid="upload-button-edit"
                    disabled={arContent?.isPublicStore}
                >
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItem="center"
                    >
                        <Col lg={2}><i className="font-size-16 mdi mdi-plus" /></Col>
                        <Col lg={6}>Edit Uploads</Col>
                    </Box>
                </Button>
            case "starterAssets":
                return <Button
                    color="secondary"
                    outline
                    block
                    onClick={toggleModal}
                    data-testid="upload-button-full"
                >
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItem="center"
                    >
                        <div className='me-2'><i className="font-size-16 bi bi-plus-lg" /></div>
                        <div>Starter assets</div>
                    </Box>
                </Button>
            default:
                break;
        }
    }

    // Modal for full upload, Button while collapsed with support for dragfirld in future expansion
    return <>
        {getDefaultDisplay()}
        <Modal
            isOpen={isModalOpen}
            toggle={toggleModal}
            size='xl'
        >
            <ModalBody>
                <Container fluid data-testid="upload-modal-container">
                    <h5 className="mb-3">
                        Upload asset file
                    </h5>
                    <Row>
                        <p>Please upload image file or <code>.glb</code> for Android. <br />You can link a <code>.usdz</code> file for iOS later</p>
                    </Row>
                    <Row className="mb-2 uploadFieldContainer">
                        <Col lg={is3dModel ? 6 : 12}>
                            <div {...getRootProps({ className: 'dropzone uploadField d-flex h-100' })}>
                                <input
                                    data-testid="input-upload-normal"
                                    {...getInputProps()}
                                />
                                <Box
                                    fullWidth
                                    display='flex'
                                    justifyContent='center'
                                    alignItem='center'
                                >
                                    <Container fluid>
                                        <Row>
                                            <Box display='flex' justifyContent='center' className={`icon ${arContent ? "text-success" : ""}`}>
                                                <i className={`mdi mdi-web icon`}></i>
                                                /
                                                <i className={`mdi mdi-android icon`}></i>
                                            </Box>
                                        </Row>
                                        {arContent ?
                                            <>
                                                <Row>
                                                    <Box display='flex' justifyContent='center'>
                                                        <h5>Content Uploaded Successfully</h5>
                                                    </Box>
                                                </Row>
                                                <Row>
                                                    <Box display='flex' justifyContent='center'>
                                                        <p>Click here to replace existing .glb file</p>
                                                    </Box>
                                                </Row>
                                            </>
                                            :
                                            <>
                                                <Row>
                                                    <Box display='flex' justifyContent='center'>
                                                        <h5>Drop file here</h5>
                                                    </Box>
                                                </Row>
                                                <Row>
                                                    <Box display='flex' justifyContent='center'>
                                                        <p>or <span className="text-primary">Browse file</span> from your computer to upload</p>
                                                    </Box>
                                                </Row>
                                                <Box fullWidth display='flex' justifyContent='center'>
                                                    <p className='mb-0'>You can upload AR contents here! Max size is <code>{formatBytes(MAX_FILE_SIZE)}</code> and <code>200MB</code> for video.</p>
                                                </Box>
                                                <Box fullWidth display='flex' justifyContent='center'>
                                                    <p className='mb-0'>Supported format <code>.glb, .gltf (zip), .jpg, .png</code></p>
                                                </Box>
                                            </>
                                        }
                                    </Container>
                                </Box>
                            </div>
                        </Col>
                        {
                            is3dModel &&
                            <Col lg={6}>
                                <div {...getIosRootProps({ className: `dropzone uploadField d-flex h-100 ${arContent ? "" : "disabled"}` })}>
                                    <input
                                        data-testid="input-upload-ios"
                                        {...getIosInputProps()}
                                    />
                                    <Box
                                        fullWidth
                                        display='flex'
                                        justifyContent='center'
                                        alignItem='center'
                                    >
                                        <Container fluid>
                                            {
                                                !arContent?.iosDownloadUrl && <Row>
                                                    <Box display='flex' justifyContent='center'>
                                                        <h6>Optional</h6>
                                                    </Box>
                                                </Row>
                                            }

                                            <Row>
                                                <Box display='flex' justifyContent='center'>
                                                    <i className={`mdi mdi-apple icon ${arContent?.iosDownloadUrl ? "text-success" : ""}`}></i>
                                                </Box>
                                            </Row>
                                            {
                                                arContent?.iosDownloadUrl ?
                                                    <>
                                                        <Row>
                                                            <Box display='flex' justifyContent='center'>
                                                                <h5><code>.usdz</code> Uploaded Successfully</h5>
                                                            </Box>
                                                        </Row>
                                                        <Row>
                                                            <Box display='flex' justifyContent='center'>
                                                                <p>Click here to replace existing .usdz file</p>
                                                            </Box>
                                                        </Row>
                                                    </>
                                                    : <>
                                                        <Row>
                                                            <Box display='flex' justifyContent='center'>
                                                                <h5>Drop <code>.usdz</code> file here</h5>
                                                            </Box>
                                                        </Row>
                                                        <Row>
                                                            <Box display='flex' justifyContent='center'>
                                                                <p>or <span className="text-primary">Browse file</span> from your computer after you upload <code>.glb</code></p>
                                                            </Box>
                                                        </Row>
                                                        <Row>
                                                            <Box display='flex' justifyContent='center'>
                                                                <p></p>
                                                            </Box>
                                                        </Row>
                                                    </>
                                            }
                                        </Container>
                                    </Box>
                                </div>
                            </Col>
                        }
                    </Row>
                    {uploadProgress.map(progress => <Row>
                        <p>{getUploadMessage(progress)}</p>
                        <div>
                            <Progress className='p-0' animated={true} color={progress?.isComplete ? "success" : progress?.error ? "danger" : ""} value={progress?.progress} />
                        </div>
                    </Row>)}

                    {
                        (!arContent && enableUpload) && <>
                            <Row>
                                <h6>Starter Asset</h6>
                            </Row>
                            <Row>
                                <StarterAssets onAssetClick={createARContentFromExistingAsset} />
                            </Row>
                        </>
                    }
                    <Box display="flex" justifyContent="flex-end" className="mt-2">
                        <Button color="primary" onClick={toggleModal}>Close</Button>
                    </Box>
                </Container>
            </ModalBody>
        </Modal>
        <input
            type='file'
            accept=".jpg, .png, .jpeg"
            multiple={false}
            id='file'
            ref={detectionImageFileField}
            data-testid="input-upload-detectionImage"
            onChange={(e) => onImportFilesChange(
                e.target.files || [],
                ARContentType.DetectionImage,
                MAX_ANCHOR_IMAGE_SIZE
            )}
            style={{ display: 'none' }}
        />
        <input
            type='file'
            accept=".jpg, .png, .jpeg"
            multiple={false}
            id='file'
            ref={referenceImageFileField}
            data-testid="input-upload-refImage"
            onChange={(e) => onImportFilesChange(
                e.target.files || [],
                ARContentType.ReferenceImage,
                MAX_ANCHOR_IMAGE_SIZE
            )}
            style={{ display: 'none' }}
        />
    </>
}

UploadManager.displayName = "Upload Manager"

export { UploadManager }
