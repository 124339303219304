import "./liteButton.scss"

interface liteButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    block: boolean
    highlight: boolean
    border: "none" | "solid"
    variant?: "normal" | "error"
}

const LiteButton = (props: liteButtonProps) => {
    const buttonProps = {
        ...props,
        block: undefined,
        highlight: undefined,
        border: undefined,
        variant: undefined,
    } as React.ButtonHTMLAttributes<HTMLButtonElement>
    const constructClassName = () => {
        let classString = "liteButton"
        if (props.block) classString += " w-100"
        if (props.highlight) classString += " selected"
        if (props.variant === "error") {
            classString += " error"
        }
        if (props.border === "none") {
            classString += " border-0"
        } else {
            classString += " border"
        }
        return classString
    }
    return <button className={constructClassName()} {...buttonProps} />
}

export { LiteButton }