import axios from "axios";

import { GraffityConsoleBackendURL } from './const';
import { generateBaseHeader } from './apiHelper';
import { OrganizationType } from 'store/organization/types'

export interface OrganizationSummary {
    projects: number;
}

export const createOrUpdateOrganization = async (organization: OrganizationType): Promise<number> => {
    const header = await generateBaseHeader()
    const resp = await axios({
        method: "POST",
        url: GraffityConsoleBackendURL + "/private-api/v1/organization/create-or-update",
        headers: {
            ...header,
        },
        data: JSON.stringify(organization),
    })
    return resp.status
}

export const getOrganizationByUserId = async (firebaseAuthUid: string): Promise<OrganizationType | undefined> => {
    const header = await generateBaseHeader()
    const resp = await axios({
        method: "POST",
        url: GraffityConsoleBackendURL + "/private-api/v1/organization/get-by-user-id",
        headers: {
            ...header,
        },
        data: JSON.stringify({
            firebaseAuthUid: firebaseAuthUid
        }),
    })

    if (resp.status === 200) {
        const returnBody: OrganizationType = {
            ...resp.data,
            updatedAt: new Date(resp.data.updatedAt || 0),
            subscriptionExpireAt: new Date(resp.data.subscriptionExpireAt || 0),
        }
        return returnBody
    }
}

export const getOrganizationByOrganizationId = async (organizationId: string): Promise<OrganizationType | undefined> => {
    const header = await generateBaseHeader()
    const resp = await axios({
        method: "POST",
        url: GraffityConsoleBackendURL + "/private-api/v1/organization/get-by-id",
        headers: {
            ...header,
        },
        data: JSON.stringify({
            _id: organizationId
        }),
    })

    if (resp.status === 200) {
        return resp.data
    }
}

export const getOrgSummary = async (organizationId: string): Promise<OrganizationSummary | undefined> => {
    const header = await generateBaseHeader()

    const resp = await axios({
        method: "POST",
        url: GraffityConsoleBackendURL + "/private-api/v1/organization/summary",
        headers: {
            ...header,
        },
        data: {
            _id: organizationId,
        }
    })

    if (resp.status === 200) {
        return resp.data
    }

    return undefined;
}
