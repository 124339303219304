import mbxClient from "@mapbox/mapbox-sdk"
import mbxStatic, { StaticMapRequest } from "@mapbox/mapbox-sdk/services/static"

const mapboxToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || ""
const client = mbxClient({accessToken: mapboxToken})
const staticClient = mbxStatic(client)

const getStaticMapImage = (bbox: number[], size=1280): string => {
    const request: StaticMapRequest = {
        ownerId: "mapbox", 
        styleId: "streets-v12",
        position: {
            // @ts-ignore
            bbox,
        },
        width: size,
        height: size,
    }
    return staticClient.getStaticImage(request).url()
}

export { getStaticMapImage }