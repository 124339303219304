import { useEffect } from "react"

interface PageMetaProps {
    title: string
}

const PageMeta = ({title}: PageMetaProps) => {
    const defaultTitle = "Graffity Console"

    useEffect(() => {
        document.title = title || defaultTitle
    }, [title])

    return <></>
}

export { PageMeta }
