import React, { useState } from "react";
import { Col, Label, Modal, ModalHeader, ModalBody, ModalFooter, Card, CardBody, Button } from "reactstrap";
import { SettingProps } from "./ProjectSetting";
import { createOrUpdateProject, deleteProjectByProjectId } from "apis/project";
import { Box } from "components/Common/Layout/Layout";
import { editModal } from "components/Common/EditModal/EditModal";
import { Project } from "../data";

const GeneralSetting = ({
    project,
}: SettingProps) => {
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [showDeleteFailMessage, setShowDeleteFailMessage] = useState(false);
    const closeDeleteModal = () => { setDeleteModalOpen(false) }

    const onDeleteProject = async () => {
        const isDeleteSuccess = await deleteProjectByProjectId(project._id || "");
        if (isDeleteSuccess) {
            window.location.replace('/projects')
            return
        }
        setShowDeleteFailMessage(true);
    }

    const dateOption: Intl.DateTimeFormatOptions = {
        formatMatcher: "basic",
        dateStyle: "full",
        timeStyle: "short",
    }

    return (
        <React.Fragment>
            <Modal
                isOpen={deleteModalOpen}
                toggle={closeDeleteModal}
                data-testid="delete-modal"
            >
                <ModalHeader toggle={closeDeleteModal} data-testid="delete-modal-header">Are you sure?</ModalHeader>
                <ModalBody>
                    This action will permanently delete your project and it will be lost forever.
                </ModalBody>
                <ModalFooter>
                    <button
                        type="button"
                        onClick={onDeleteProject}
                        className="btn btn-danger"
                        data-testid="modal-delete-button"
                    >
                        Delete Project
                    </button>
                    <button
                        type="button"
                        onClick={closeDeleteModal}
                        className="btn"
                        data-testid="modal-cancel-button"
                    >
                        Cancel
                    </button>
                </ModalFooter>
            </Modal>
            <Box
                background="light"
                padding="XL"
                borderColor="dark"
                borderRadius="rounded"
                className="mb-4 bg-opacity-75 border-opacity-10"
            >
                <Box display="flex" justifyContent="space-between">
                    <Label>
                        Project Name
                    </Label>
                    <p>{project.name}</p>
                </Box>
                <hr className="border-dark border-opacity-50" />
                <Box display="flex" justifyContent="space-between">
                    <Label>
                        Project ID
                    </Label>
                    <p>{project._id}</p>
                </Box>
                <hr className="border-dark border-opacity-50" />
                <Box display="flex" justifyContent="space-between">
                    <Label
                    >
                        Date Created
                    </Label>
                    <p>{Intl.DateTimeFormat("en-US", dateOption).format(new Date(project.createdAt))}</p>
                </Box>
            </Box>
            <Box
                background="light"
                padding="M"
                borderColor="dark"
                borderRadius="rounded"
                display="flex"
                justifyContent="center"
                className="bg-opacity-75 border-opacity-10"
            >
                <button
                    className="mb-0 text-danger border-0 bg-light"
                    onClick={() => {
                        setDeleteModalOpen(true)
                    }}
                    data-testid="delete-button"
                >
                    Delete Project
                </button>
            </Box>
            {showDeleteFailMessage && <p className="text-danger">Delete Failed, please try again later.</p>}


        </React.Fragment>
    )
}

export default GeneralSetting;