import sha512 from 'crypto-js/sha512';
import Base64 from 'crypto-js/enc-base64';
import { nanoid } from 'nanoid';
import { Buffer } from 'buffer';

export interface AccessToken {
    _id?: string // Got it when fetch from API
    accessTokenId: string
    prefix: string
    createdAt: number
    lastModified: number
    scopes: string[]
    hash?: string
    raw?: string
    firebaseAuthUid?: string
}

export const generateAccessToken = (): string => {
    let token = nanoid(96);
    token = "sk." + Buffer.from(token).toString('base64');;
    return token;
}

export const getTokenPrefix = (token: string): string => {
    return token.slice(0, 8);
}

export const hashMessage = (message: string): string => {
    // const nonce = "v1.0" + nanoid(16);
    let hashDigest = sha512(message);
    console.log("hashDigest.toString()", hashDigest.toString())
    let base64Hash = Base64.stringify(hashDigest);
    console.log("base64Hash", base64Hash)
    return base64Hash;
}