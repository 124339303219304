import React, { Suspense, useRef, useState } from 'react'
import { ARContent, ARContentType, Project, ARAnchorType } from "pages/Projects/data";
import toastr from "toastr";
import { Button, Col, Container, Modal, ModalBody, Progress, Row } from "reactstrap";
import { Box } from 'components/Common/Layout/Layout';
import { useDropzone } from 'react-dropzone';
import "./uploadManager.scss";
import { StarterAssets } from './StarterAsset';
import { getDefaultDropzoneOption, getUploadMessage, MAX_FILE_SIZE, UploadProgress, UploadStatusType, UploadType, useUpload } from './uploadHook';

const formatBytes = (bytes: number, decimals = 2) => {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

enum ToastType {
    info, warning, error, success
}

const showToastr = (type: ToastType, message: string) => {
    toastr.options = {
        positionClass: "toast-bottom-left",
        showEasing: "swing",
        hideEasing: "linear",
        showDuration: 300,
        hideDuration: 500,
        timeOut: 3000,
        closeButton: true,
        progressBar: true,
    }

    if (type === ToastType.info) toastr.info(message, "");
    else if (type === ToastType.warning) toastr.warning(message, "");
    else if (type === ToastType.error) toastr.error(message, "");
    else toastr.success(message, "");
}

const mapUploadTypeToToastType = (uploadType: UploadStatusType): ToastType => {
    switch (uploadType) {
        case UploadStatusType.info:
            return ToastType.info
        case UploadStatusType.success:
            return ToastType.success
        case UploadStatusType.warning:
            return ToastType.warning
        case UploadStatusType.error:
            return ToastType.error
        default:
            return ToastType.info
    }
}

const UploadProgressList = ({ progressList }: {
    progressList: UploadProgress[]
}) => {
    return <>{progressList.map(progress => <Row className='mb-2' key={progress.id}>
        <div className='d-flex justify-content-between'>
            <p className='mb-1'>{progress.name}</p>
            <p>{getUploadMessage(progress)}</p>
        </div>
        <div>
            <Progress className='p-0' animated={true} color={progress.isComplete ? "success" : progress.error ? "danger" : ""} value={progress.progress} />
        </div>
    </Row>)}</>
}

interface OwnProps {
    project?: Project;
    detectionImageId?: string | null;
    faceAnchorId?: string | null;
    mapCenter?: string | null;
    pointCloudId?: string | null;
    floorId?: string | null;
    defaultPosition?: THREE.Vector3;
    onUploadSuccess: (addedObject: ARContent[], shouldRefresh: boolean, redirectAnchorType?: ARAnchorType, redirectAnchorId?: string) => void;
    onUploadSessionFinish: () => void;
    variant?: "full" | "starterAssets";
}

type UploadMultipleProps = OwnProps

/**
 * Only use UploadMultiple for regular AR Content creation for everything else
 * use UploadManager
 * 
 */
const UploadMultiple = ({
    project,
    detectionImageId = null,
    faceAnchorId = null,
    mapCenter = null,
    pointCloudId = null,
    floorId = null,
    onUploadSuccess,
    onUploadSessionFinish,
    defaultPosition,
    variant = "full",
}: UploadMultipleProps) => {
    const [isModalOpen, setIsModalOpen] = useState(false)

    const handleUploadStatusChange = (type: UploadStatusType, message: string) => {
        const toastType = mapUploadTypeToToastType(type)
        showToastr(toastType, message)
    }

    const {
        enableUpload,
        isUploading,
        uploadProgress,
        createARContentFromImport,
        createARContentFromExistingAsset,
        resetUpload,
    } = useUpload(
        onUploadSuccess,
        handleUploadStatusChange,
        project,
        detectionImageId,
        faceAnchorId,
        mapCenter,
        pointCloudId,
        floorId,
        undefined,
        defaultPosition,
        UploadType.arContent,
    )

    const onImportFilesChange = (
        selectedFiles: File[] | FileList,
        arContentType?: ARContentType,
        maxFileSize = MAX_FILE_SIZE,
    ) => {
        if (selectedFiles) {
            const rightSize: File[] = []
            for (let i = 0; i < selectedFiles.length; i++) {
                let file = selectedFiles[i]
                if (file) {
                    if (file.size > maxFileSize) {
                        showToastr(ToastType.error, `File ${file.name} is too large.`)
                    } else {
                        rightSize.push(file)
                    }
                }
            }
            if (rightSize.length <= 0) return;
            createARContentFromImport(rightSize, arContentType);
        }
    };

    const onDrop = (acceptedFiles: File[] | FileList) => {
        onImportFilesChange(acceptedFiles)
    }
    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        disabled: !enableUpload,
        multiple: true,
        ...getDefaultDropzoneOption()
    })

    const toggleModal = () => {
        if (isModalOpen) {
            onUploadSessionFinish()
            resetUpload()
        }
        if (pointCloudId && !floorId) {
            showToastr(ToastType.error, "Please select floor before uploading contents.")
            return
        }
        setIsModalOpen(!isModalOpen)
    }

    const getDefaultDisplay = () => {
        switch (variant) {
            case "full":
                return <Button
                    color="primary"
                    block
                    onClick={toggleModal}
                    data-testid="upload-button-full"
                >
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItem="center"
                    >
                        <i className="font-size-20 bi bi-cloud-arrow-up me-2" />
                        <div>Upload</div>
                    </Box>
                </Button>
            case "starterAssets":
                return <Button
                    color="secondary"
                    outline
                    block
                    onClick={toggleModal}
                    data-testid="upload-button-full"
                >
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItem="center"
                    >
                        <div className='me-2'><i className="font-size-16 bi bi-plus-lg" /></div>
                        <div>Starter assets</div>
                    </Box>
                </Button>
            default:
                break;
        }
    }
    return <>
        {getDefaultDisplay()}
        <Modal
            isOpen={isModalOpen}
            toggle={toggleModal}
            size='xl'
        >
            <ModalBody>
                <Container fluid data-testid="upload-modal-container">
                    <h5 className="mb-3">
                        Upload asset file
                    </h5>
                    <Row>
                        <p>Please upload image file or <code>.glb</code> for Android. <br />You can link a <code>.usdz</code> file for iOS later</p>
                    </Row>
                    <Row className="mb-2 uploadFieldContainer">
                        <Col lg={12}>
                            <div {...getRootProps({ className: 'dropzone uploadField d-flex h-100' })}>
                                <input
                                    data-testid="input-upload-normal"
                                    {...getInputProps()}
                                />
                                <Box
                                    fullWidth
                                    display='flex'
                                    justifyContent='center'
                                    alignItem='center'
                                >
                                    <Container fluid>
                                        <Row>
                                            <Box display='flex' justifyContent='center' className="icon">
                                                <i className={`mdi mdi-web icon`}></i>
                                                /
                                                <i className={`mdi mdi-android icon`}></i>
                                            </Box>
                                        </Row>
                                        <>
                                            <Row>
                                                <Box display='flex' justifyContent='center'>
                                                    <h5>Drop file here</h5>
                                                </Box>
                                            </Row>
                                            <Row>
                                                <Box display='flex' justifyContent='center'>
                                                    <p>or <span className="text-primary">Browse file</span> from your computer to upload</p>
                                                </Box>
                                            </Row>
                                            <Box fullWidth display='flex' justifyContent='center'>
                                                <p className='mb-0'>You can upload AR contents here! Max size is <code>{formatBytes(MAX_FILE_SIZE)}</code> and <code>200MB</code> for video.</p>
                                            </Box>
                                            <Box fullWidth display='flex' justifyContent='center'>
                                                <p className='mb-0'>Supported format <code>.glb, .gltf (zip), .jpg, .png, .mp4, .avi, .webm</code></p>
                                            </Box>
                                        </>
                                    </Container>
                                </Box>
                            </div>
                        </Col>
                    </Row>
                    {
                        (enableUpload) && <>
                            <Row>
                                <h6>Starter Asset</h6>
                            </Row>
                            <Row>
                                <StarterAssets onAssetClick={createARContentFromExistingAsset} />
                            </Row>
                        </>
                    }
                    <UploadProgressList progressList={uploadProgress} />
                    <Box display="flex" justifyContent="flex-end" className="mt-3">
                        <Button color="primary" onClick={toggleModal}>Close</Button>
                    </Box>
                </Container>
            </ModalBody>
        </Modal>
    </>
}

UploadMultiple.displayName = "UploadMultiple"

export { UploadMultiple }
