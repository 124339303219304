import JSZip from "jszip";

export class GLTFHelper {

    convertedGLBFile: File | undefined;

    inputFile: File;
    glbfilename: string;

    fileblobs: { [key: string]: any; } = {};
    gltf: any;
    outputBuffers: any[] = [];
    bufferMap = new Map();
    bufferOffset = 0;
    remainingfilestoprocess = 0;

    gltfMimeTypes: any = {
        'image/png': ['png'],
        'image/jpeg': ['jpg', 'jpeg'],
        'text/plain': ['glsl', 'vert', 'vs', 'frag', 'fs', 'txt'],
        'image/vnd-ms.dds': ['dds']
    };

    constructor(filename: string, file: File) {
        this.glbfilename = filename
        this.inputFile = file
    }

    async getGLBFileFromGltfZip() {
        this.glbfilename = this.inputFile.name.split('.')[0];

        const result = await JSZip.loadAsync(this.inputFile);
        const files = Object.values(result.files).filter(item => !item.dir);
        this.remainingfilestoprocess = files.length

        // Read glTF Start
        const entryFile = files.find(f => this.getExtension(f.name) === 'gltf');
        let entryFileBlob
        try {
            entryFileBlob = await entryFile!.async('blob');
        } catch (error) {
            console.error(error);
            return;
        }

        let gltfReader = new FileReader();
        gltfReader.onload = (event: any) => {
            this.gltf = JSON.parse(event.target.result);
        };
        gltfReader.readAsText(entryFileBlob);
        // Read glTF End

        // Create blobs for every file resource
        for (const fileJSZip of files) {
            if (fileJSZip.name.split('.').pop() !== "gltf") {
                console.log(`Loading ${fileJSZip.name}...`);
                let fileBlob;
                try {
                    fileBlob = await fileJSZip!.async('blob');
                } catch (error) {
                    console.error(error);
                    return;
                }

                const file = new File([fileBlob], `${fileJSZip.name}`);

                try {
                    let arrayBuffer = await this.readFileAsync(file);
                    this.fileblobs[file.name] = arrayBuffer
                } catch (error) {
                    console.error(error);
                    return;
                }
            }
            await this.checkRemaining();
        }
        return this.convertedGLBFile;
    }

    readFileAsync(file: File) {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();

            reader.onload = () => {
                resolve(reader.result);
            };

            reader.onerror = reject;

            reader.readAsArrayBuffer(file);
        })
    }

    getExtension = (filename: string) => {
        return filename.split('.').pop();
    }

    getFileUrl = async (file: JSZip.JSZipObject) => {
        const blob = await file.async('blob');
        const url = URL.createObjectURL(blob);
        return url;
    }

    async checkRemaining() {
        this.remainingfilestoprocess--;
        if (this.remainingfilestoprocess === 0) {
            await this.processBuffers();
            this.fileSave()
        }
    }

    async processBuffers() {
        let pendingBuffers = this.gltf.buffers.map(async (buffer: any, bufferIndex: number) => {
            const data = await this.dataFromUri(buffer);
            if (data !== undefined) {
                this.outputBuffers.push(data);
            }
            delete buffer.uri;
            buffer.byteLength = data.byteLength;
            this.bufferMap.set(bufferIndex, this.bufferOffset);
            this.bufferOffset += this.alignedLength(data.byteLength);
        });

        await Promise.all(pendingBuffers);
        let bufferIndex_1 = this.gltf.buffers.length;
        let images = this.gltf.images || [];

        let pendingImages = images.map(async (image: any) => {
            const data_1 = await this.dataFromUri(image);

            if (data_1 === undefined) {
                delete image['uri'];
                return;
            }
            let bufferView = {
                buffer: 0,
                byteOffset: this.bufferOffset,
                byteLength: data_1.byteLength,
            };
            this.bufferMap.set(bufferIndex_1, this.bufferOffset);
            bufferIndex_1++;
            this.bufferOffset += this.alignedLength(data_1.byteLength);
            let bufferViewIndex = this.gltf.bufferViews.length;
            this.gltf.bufferViews.push(bufferView);
            this.outputBuffers.push(data_1);
            image['bufferView'] = bufferViewIndex;
            image['mimeType'] = this.getMimeType(image.uri);
            delete image['uri'];
        });
        return await Promise.all(pendingImages);
    }

    fileSave() {
        let Binary = {
            Magic: 0x46546C67
        };

        for (let _i = 0, _a = this.gltf.bufferViews; _i < _a.length; _i++) {
            let bufferView = _a[_i];
            if (bufferView.byteOffset === undefined) {
                bufferView.byteOffset = 0;
            }
            else {
                bufferView.byteOffset = bufferView.byteOffset + this.bufferMap.get(bufferView.buffer);
            }
            bufferView.buffer = 0;
        }
        let binBufferSize = this.bufferOffset;
        this.gltf.buffers = [{
            byteLength: binBufferSize
        }];

        let enc = new TextEncoder();
        let jsonBuffer = enc.encode(JSON.stringify(this.gltf));
        let jsonAlignedLength = this.alignedLength(jsonBuffer.length);
        let padding;
        if (jsonAlignedLength !== jsonBuffer.length) {

            padding = jsonAlignedLength - jsonBuffer.length;
        }
        let totalSize = 12 + // file header: magic + version + length
            8 + // json chunk header: json length + type
            jsonAlignedLength +
            8 + // bin chunk header: chunk length + type
            binBufferSize;
        let finalBuffer = new ArrayBuffer(totalSize);
        let dataView = new DataView(finalBuffer);
        let bufIndex = 0;
        dataView.setUint32(bufIndex, Binary.Magic, true);
        bufIndex += 4;
        dataView.setUint32(bufIndex, 2, true);
        bufIndex += 4;
        dataView.setUint32(bufIndex, totalSize, true);
        bufIndex += 4;
        // JSON
        dataView.setUint32(bufIndex, jsonAlignedLength, true);
        bufIndex += 4;
        dataView.setUint32(bufIndex, 0x4E4F534A, true);
        bufIndex += 4;

        for (let j = 0; j < jsonBuffer.length; j++) {
            dataView.setUint8(bufIndex, jsonBuffer[j]);
            bufIndex++;
        }
        if (padding !== undefined) {
            for (let j = 0; j < padding; j++) {
                dataView.setUint8(bufIndex, 0x20);
                bufIndex++;
            }
        }

        // BIN
        dataView.setUint32(bufIndex, binBufferSize, true);
        bufIndex += 4;
        dataView.setUint32(bufIndex, 0x004E4942, true);
        bufIndex += 4;
        for (let i = 0; i < this.outputBuffers.length; i++) {
            let bufoffset = bufIndex + this.bufferMap.get(i);
            let buf = new Uint8Array(this.outputBuffers[i]);
            let thisbufindex = bufoffset;
            for (let j = 0; j < buf.byteLength; j++) {
                dataView.setUint8(thisbufindex, buf[j]);
                thisbufindex++;
            }
        }

        let blobGlb = new Blob([finalBuffer], { type: 'model/json-binary' });
        this.convertedGLBFile = new File([blobGlb], `${this.glbfilename}.glb`);
    }

    isBase64(uri: string) {
        return uri.length < 5 ? false : uri.slice(0, 5) === "data:";
    }

    async decodeBase64(uri: string) {
        const response = await fetch(uri);
        return await response.arrayBuffer();
    }

    async dataFromUri(buffer: any) {
        if (buffer.uri === undefined) {
            console.log("dataFromUri - buffer.uri === undefined")
            return await Promise.resolve(undefined);
        } else if (this.isBase64(buffer.uri)) {
            console.log("dataFromUri - this.isBase64(buffer.uri)")
            return await this.decodeBase64(buffer.uri);
        } else {
            return await Promise.resolve(this.fileblobs[buffer.uri]);
        }
    }

    alignedLength(value: any) {
        let alignValue = 4;
        if (value === 0) {
            return value;
        }
        let multiple = value % alignValue;
        if (multiple === 0) {
            return value;
        }
        return value + (alignValue - multiple);
    }

    getMimeType(filename: string) {
        for (let mimeType in this.gltfMimeTypes) {
            for (let extensionIndex in this.gltfMimeTypes[mimeType]) {
                let extension = this.gltfMimeTypes[mimeType][extensionIndex];
                if (filename.toLowerCase().endsWith('.' + extension)) {
                    return mimeType;
                }
            }
        }
        return 'application/octet-stream';
    }
}