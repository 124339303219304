import axios from "axios";

import { GraffityConsoleBackendURL } from './const';
import { ARContent, ARContentType } from "../pages/Projects/data";
import { generateBaseHeader } from './apiHelper';

interface GetUrlPutARContentResponse {
    putUrl: string
    storeUrl: string
    systemFilename: string
}

interface ConvertArContentFileResponse {
    store_url_ios: string
    user_filename_ios: string
    ios_download_url: string
}

export const getARContentsByProjectId = async (projectId: string): Promise<ARContent[]> => {
    const header = await generateBaseHeader()
    const resp = await axios({
        method: "POST",
        url: GraffityConsoleBackendURL + "/private-api/v1/ar-content/get",
        headers: {
            ...header,
        },
        data: {
            projectId: projectId
        }
    })

    if (resp.status === 200) {
        return resp.data
    }

    return [];
}

export const createOrUpdateARContent = async (arContent: ARContent): Promise<string> => {
    try {
        const header = await generateBaseHeader()
        const resp = await axios({
            method: "POST",
            url: GraffityConsoleBackendURL + "/private-api/v1/ar-content/create-or-update",
            headers: {
                ...header,
            },
            data: JSON.stringify(arContent)
        })

        if (resp.status === 200) {
            return resp.data
        }
        return ""
    } catch {
        console.error("Create AR Content failed");
        return ""
    }
}

export const convertARContentFile = async (arContent: ARContent): Promise<ConvertArContentFileResponse | undefined> => {
    try {
        const header = await generateBaseHeader()
        const resp = await axios({
            method: "POST",
            url: GraffityConsoleBackendURL + "/private-api/v1/ar-content/convert-file",
            headers: {
                ...header,
            },
            data: JSON.stringify(arContent)
        })

        if (resp.status === 200) {
            return resp.data
        }
        return undefined
    } catch {
        console.error("Convert AR Content failed");
        return undefined
    }
}

export const getUrlPutARContent = async (projectRefId: string, fileExtWithoutDot: string): Promise<GetUrlPutARContentResponse> => {
    const header = await generateBaseHeader()
    const resp = await axios({
        method: "POST",
        url: GraffityConsoleBackendURL + "/private-api/v1/ar-content/get-presigned-url-put-content",
        headers: {
            ...header,
        },
        data: JSON.stringify({
            path: "ar_contents/" + process.env.REACT_APP_STAGE + "/" + projectRefId,
            fileExtWithoutDot: fileExtWithoutDot
        })
    })

    if (resp.status === 200) {
        // console.log("getAllAccessTokens success");
        return resp.data
    }

    return {} as GetUrlPutARContentResponse;
}

export const putARContent = async (url: string, object: File, onUploadProgress: (progressEvent: any) => void): Promise<string> => {
    try {
        const resp = await axios({
            method: "PUT",
            url: url,
            data: object,
            onUploadProgress,
        })

        if (resp.status === 200) {
            return "success"
        } else {
            console.error("Upload object failed");
        }
        return "failed";
    } catch {
        console.error("Upload object failed");
        return "failed"
    }
}

export const getUrlGetARContent = async (storeUrl: string): Promise<string> => {
    const header = await generateBaseHeader()
    const resp = await axios({
        method: "POST",
        url: GraffityConsoleBackendURL + "/private-api/v1/ar-content/get-presigned-url-get-content",
        headers: {
            ...header,
        },
        data: JSON.stringify({
            storeUrl: storeUrl,
        })
    })

    if (resp.status === 200) {
        // console.log("getAllAccessTokens success");
        return resp.data
    }

    return "";
}

interface GetVideoUploadUrlResponse {
    uploadURL: string
    uid: string
}

export const getVideoUploadUrl = async (projectId: string): Promise<GetVideoUploadUrlResponse> => {
    const header = await generateBaseHeader()
    const resp = await axios({
        method: "POST",
        url: GraffityConsoleBackendURL + "/private-api/v1/ar-content/get-video-upload-url",
        headers: {
            ...header,
        },
        data: JSON.stringify({
            projectId,
        })
    })

    if (resp.status === 200) {
        return resp.data
    }

    return {} as GetVideoUploadUrlResponse;
}

export const uploadVideo = async (url: string, object: File, onUploadProgress: (progressEvent: any) => void): Promise<string> => {
    try {
        const formData = new FormData();
        formData.append("file", object, object.name)
        const resp = await axios({
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data",
            },
            url,
            data: formData,
            onUploadProgress,
        })

        if (resp.status === 200) {
            return "success"
        } else {
            console.error("Upload object failed");
        }
        return "failed";
    } catch {
        console.error("Upload object failed");
        return "failed"
    }
}

export const deleteARContent = async (arContent: ARContent): Promise<boolean> => {
    const header = await generateBaseHeader()
    const resp = await axios({
        method: "POST",
        url: GraffityConsoleBackendURL + "/private-api/v1/ar-content/delete",
        headers: {
            ...header,
        },
        data: JSON.stringify(arContent)
    })

    if (resp.status === 200) {
        console.log("DELETE SUCCESS")
        return true
    }

    console.log("DELETE FAILED")
    return false
}

export const deleteOrphan = async (arContentType: ARContentType, storeUrl="", storeId=""): Promise<string> => {
    const reqBody = {
        storeUrl,
        storeId,
        arContentType,
    }
    const header = await generateBaseHeader()
    const resp = await axios({
        method: "POST",
        url: GraffityConsoleBackendURL + "/private-api/v1/ar-content/delete-orphan",
        headers: {
            ...header,
        },
        data: JSON.stringify(reqBody)
    })

    if (resp.status === 200) {
        return "success"
    }

    return "failed";
}
