import { Dispatch } from "redux"
import { EditorState, SelectedObject, SelectedObjectType } from "store/editor/types"
import { ARContent, GroupObject } from "../data"
import { useEffect, useState } from "react"
import { addSelectedArContent, changeGroupTransform, changeSelectedArContent, clearSelectedObjects } from "store/actions"
import { createOrUpdateARContent } from "apis/arContent"
import { EditorStep, EditorStepType } from "./editorHook"

const getInitGroupObject = (): GroupObject => ({
    position: {
        x: 0,
        y: 0,
        z: 0
    },
    rotation: {
        x: 0,
        y: 0,
        z: 0
    },
    scale: {
        x: 0,
        y: 0,
        z: 0
    },
    isCommited: false,
})

const roundGroupTransform = (group: GroupObject) => {
    group.position = {
        x: Math.round(100 * group.position.x) / 100,
        y: Math.round(100 * group.position.y) / 100,
        z: Math.round(100 * group.position.z) / 100,
    }
    group.rotation = {
        x: Math.round(100 * group.rotation.x) / 100,
        y: Math.round(100 * group.rotation.y) / 100,
        z: Math.round(100 * group.rotation.z) / 100,
    }
    group.scale = {
        x: Math.round(100 * group.scale.x) / 100,
        y: Math.round(100 * group.scale.y) / 100,
        z: Math.round(100 * group.scale.z) / 100,
    }
}

const getGroupTransformDiff = (initGroup: GroupObject, subtractorGroup: GroupObject): GroupObject => {
    const newGroup = getInitGroupObject()
    newGroup.position = {
        x: initGroup.position.x - subtractorGroup.position.x,
        y: initGroup.position.y - subtractorGroup.position.y,
        z: initGroup.position.z - subtractorGroup.position.z,
    }
    newGroup.rotation = {
        x: (initGroup.rotation.x - subtractorGroup.rotation.x) % 360,
        y: (initGroup.rotation.y - subtractorGroup.rotation.y) % 360,
        z: (initGroup.rotation.z - subtractorGroup.rotation.z) % 360,
    }
    newGroup.scale = {
        x: initGroup.scale.x - subtractorGroup.scale.x,
        y: initGroup.scale.y - subtractorGroup.scale.y,
        z: initGroup.scale.z - subtractorGroup.scale.z,
    }
    return newGroup
}

const createStepFromGroup = (
    lastTransform: GroupObject,
    newTransform: GroupObject,
    selectedContents: SelectedObject[]
): {
    pastStep: EditorStep
    futureStep: EditorStep
} => {
    const targetContents = selectedContents.map(content => content.id)
    const pastStep: EditorStep = {
        type: EditorStepType.GroupTransformChange,
        groupTransform: {
            ...getGroupTransformDiff(lastTransform, newTransform),
            isCommited: true,
        },
        targetContents,
    }
    return {
        pastStep,
        futureStep: {
            ...pastStep,
            groupTransform: {
                ...getGroupTransformDiff(newTransform, lastTransform),
                isCommited: true,
            }
        }
    }
}

const updateContentFromGroup = (
    contents: ARContent[],
    originalContents: ARContent[],
    objectId: string,
    transform: GroupObject,
) => {
    const contentIdx = contents.findIndex(content => content._id === objectId)
    const ogContentIdx = originalContents.findIndex(content => content._id === objectId)
    if (contentIdx === -1 || ogContentIdx === -1) return
    const content = { ...contents[contentIdx] }
    const ogContent = originalContents[ogContentIdx]
    content.position = {
        x: ogContent.position.x + transform.position.x,
        y: ogContent.position.y + transform.position.y,
        z: ogContent.position.z + transform.position.z,
    }
    content.rotation = {
        x: (ogContent.rotation.x + transform.rotation.x) % 360,
        y: (ogContent.rotation.y + transform.rotation.y) % 360,
        z: (ogContent.rotation.z + transform.rotation.z) % 360,
    }

    const newScale = {
        x: ogContent.scale.x + transform.scale.x,
        y: ogContent.scale.y + transform.scale.y,
        z: ogContent.scale.z + transform.scale.z,
    }

    content.scale = {
        x: newScale.x < 0 ? 0 : newScale.x,
        y: newScale.y < 0 ? 0 : newScale.y,
        z: newScale.z < 0 ? 0 : newScale.z,
    }
    contents[contentIdx] = content
    if (transform.isCommited) {
        createOrUpdateARContent(content)
    }
}

const useObjectSelectProvider = (
    editorState: EditorState,
    dispatch: Dispatch,
    arContents: ARContent[],
    onGroupTerminate: (newArContents: ARContent[]) => void,
    onGroupStepUpdate: (pastStep: EditorStep, futureStep: EditorStep) => void,
) => {
    const [groupContents, setGroupContents] = useState<ARContent[] | null>(null)
    const [lastTransform, setLastTransform] = useState<GroupObject | null>(getInitGroupObject())

    const initGroup = () => {
        setGroupContents([...arContents])
        dispatch(changeGroupTransform(getInitGroupObject()))
        setLastTransform(getInitGroupObject())
    }

    const updateArContentsFromGroup = () => {
        if (groupContents === null || editorState.groupTransform === null) return
        const newGroupContents = [...groupContents]
        editorState.selectedObjects.forEach(object => {
            updateContentFromGroup(newGroupContents, arContents, object.id, editorState.groupTransform!)
        })
        setGroupContents(newGroupContents)
        if (editorState.groupTransform.isCommited && lastTransform !== null) {
            const { pastStep, futureStep } = createStepFromGroup(lastTransform, editorState.groupTransform, editorState.selectedObjects)
            onGroupStepUpdate(pastStep, futureStep)
            setLastTransform({
                ...editorState.groupTransform
            })
        }
    }

    // Terminate all group session and apply step
    const updateGroupContentFromStep = (stepToRealize: EditorStep) => {
        if (stepToRealize.type !== EditorStepType.GroupTransformChange) return
        dispatch(clearSelectedObjects())
        dispatch(changeGroupTransform(null))
        const originalContents = groupContents !== null ? groupContents : arContents
        const newGroupContents = groupContents !== null ? [...groupContents] : [...arContents]
        stepToRealize.targetContents.forEach(id => {
            updateContentFromGroup(newGroupContents, originalContents, id, stepToRealize.groupTransform!)
        })
        setGroupContents(null)
        setLastTransform(null)
        onGroupTerminate(newGroupContents)
    }

    const terminateGroup = () => {
        if (groupContents === null) return
        onGroupTerminate(groupContents)
        setGroupContents(null)
        setLastTransform(null)
        dispatch(changeGroupTransform(null))
    }

    useEffect(() => {
        if (editorState.selectedObjects.length > 1 && groupContents === null) {
            initGroup()
        } else if (editorState.selectedObjects.length <= 1 && groupContents !== null) {
            terminateGroup()
        }
    }, [editorState.selectedObjects])

    useEffect(() => {
        if (editorState.groupTransform !== null) {
            updateArContentsFromGroup()
        }
    }, [editorState.groupTransform])

    return {
        groupContents,
        updateGroupContentFromStep,
    }
}

const getUpdatedGroupFromArContent = (initTransform: GroupObject, arContent: ARContent): GroupObject => {
    const newTransform = { ...initTransform }
    newTransform.position = {
        x: arContent.position.x - initTransform.position.x,
        y: arContent.position.y - initTransform.position.y,
        z: arContent.position.z - initTransform.position.z,
    }
    newTransform.rotation = {
        x: (arContent.rotation.x - initTransform.rotation.x) % 360,
        y: (arContent.rotation.y - initTransform.rotation.y) % 360,
        z: (arContent.rotation.z - initTransform.rotation.z) % 360,
    }
    newTransform.scale = {
        x: arContent.scale.x - initTransform.scale.x,
        y: arContent.scale.y - initTransform.scale.y,
        z: arContent.scale.z - initTransform.scale.z,
    }
    return newTransform
}

// TODO: use this hook to do all ar content logic and remove props drilling
const useObjectSelect = (
    editorState: EditorState,
    dispatch: Dispatch,
    arContents: ARContent[],
) => {
    const [initTransform, setInitTransform] = useState<GroupObject | null>(null)
    const [parentId, setParentId] = useState<string | null>(null)

    const isGroupSelection = editorState.selectedObjects.length > 1
    const isAnythingSelected = editorState.selectedObjects.length > 0
    const groupTransform = editorState.groupTransform
    const selectedObjects = editorState.selectedObjects

    const initStateFromArContent = () => {
        if (!isGroupSelection) return
        const selectedParent = arContents.find(content => content._id === selectedObjects[0].id)
        if (!selectedParent) return
        setParentId(selectedParent._id!)
        setInitTransform({
            position: selectedParent.position,
            rotation: selectedParent.rotation,
            scale: selectedParent.scale,
        })

    }

    const terminateState = () => {
        setInitTransform(null)
        setParentId(null)
    }

    const updateGroupFromArContent = (content: ARContent, isCommited = false) => {
        if (!isGroupSelection || content._id !== parentId || initTransform === null) return
        const newGroup = getUpdatedGroupFromArContent(initTransform, content)
        newGroup.isCommited = isCommited
        roundGroupTransform(newGroup)
        dispatch(changeGroupTransform(newGroup))
    }

    const updateGroup = (newGroup: GroupObject, isCommited = false) => {
        if (!isGroupSelection) return
        const groupToUpdate = {
            ...newGroup,
            isCommited,
        }
        roundGroupTransform(groupToUpdate)
        dispatch(changeGroupTransform(groupToUpdate))
    }

    useEffect(() => {
        if (isGroupSelection && parentId === null) {
            initStateFromArContent()
        } else if (!isGroupSelection) {
            terminateState()
        }
    }, [isGroupSelection])

    const selectObject = (objectId: string, objectType = SelectedObjectType.ArContent) => {
        if (objectType === SelectedObjectType.ArContent) {
            dispatch(changeSelectedArContent(objectId))
        }
    }

    const addObjectToSelect = (objectId: string, objectType = SelectedObjectType.ArContent) => {
        if (isAnythingSelected && objectType !== editorState.selectedObjectType) return
        if (objectType === SelectedObjectType.ArContent) {
            dispatch(addSelectedArContent(objectId))
        }
    }

    const clearSelection = () => {
        dispatch(clearSelectedObjects())
    }

    return {
        group: groupTransform,
        updateGroup,
        selectObject,
        addObjectToSelect,
        clearSelection,
        updateGroupFromArContent,
    }
}

export { useObjectSelectProvider, useObjectSelect, getInitGroupObject }
