import { useState } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    Alert,
    CardBody,
    Button,
} from "reactstrap";

//redux
import { useSelector } from "react-redux";
import { withRouter } from "../../tsxParser/router-v6";

import VerticalLayout from "../../components/VerticalLayout";
import Breadcrumb from "../../components/Common/Breadcrumb";

import { RootState } from "../../store/reducers";

import "./account.scss"
import { Profile } from "./Profile";
import { Organization } from "./Organization";
import { PageMeta } from "components/Common/Header";


const Account = () => {
    const { error, success } = useSelector((state: RootState) => ({
        error: state.Profile.error,
        success: state.Profile.success,
    }));
    const [isProfile, setIsProfile] = useState(false);

    const navButton = (title: string, selected: boolean, onClick: () => void) => (
        <Button
            block
            outline={!selected}
            color="primary"
            className="navButton"
            onClick={onClick}
            key={title}
        >
            {title}
        </Button>
    )

    return (
        <VerticalLayout>
            <div className="page-content account">
                <PageMeta title="Profile | Graffity Console"/>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title="Console" breadcrumbItem="Account" />

                    <Row className="fullHeight">
                        <Col lg="12">
                            {error && error ? <Alert color="danger">{error}</Alert> : null}
                            {success ? <Alert color="success">{success}</Alert> : null}

                            <Card className="card">
                                <CardBody>
                                    <Container className="cardContainer" fluid>
                                        <Row>
                                            <Col lg={2}>
                                                {navButton("My profile", isProfile, () => { setIsProfile(true) })}
                                                <hr />
                                                {navButton(
                                                    "My Team",
                                                    !isProfile,
                                                    () => { setIsProfile(false) },
                                                )}
                                            </Col>
                                            <Col lg={10}>
                                                {
                                                    isProfile ? <Profile /> : <Organization />
                                                }
                                            </Col>
                                        </Row>
                                    </Container>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </VerticalLayout>
    );
};

Account.displayName = "Account"

export default withRouter(Account);