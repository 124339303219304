import { isEqual } from "lodash";
import { ARAnchorType, ARContent, ARContentType, Poi, Vector3 } from "../data"
import { EditorStep, EditorStepType } from "./editorHook";
import { createOrUpdateARContent } from "apis/arContent";
import { EditorState } from "store/editor/types";

export const filterARContentAndARAnchorType = (arContents: ARContent[], anchorType: ARAnchorType, contentType: ARContentType) => {
    return arContents.filter(e => (e.arAnchorType === anchorType) && (e.arContentType === contentType));
}

export const getContentFromARContentId = (arContents: ARContent[], id: string) => {
    let result = arContents.filter(e => (e.arContentId === id));
    if (result[0]) {
        return result[0];
    }
    return null;
}

export const getARContentChildsFromParentId = (arContents: ARContent[], parentId: string) => {
    return arContents.filter(content => (content.parentId) && (content.parentId === parentId))
}

export const getStepType = (previousArContent: ARContent, arContent: ARContent): EditorStepType | null => {
    if (!isEqual(previousArContent.position, arContent.position)) {
        return EditorStepType.ArContentPositionChange
    }
    if (!isEqual(previousArContent.rotation, arContent.rotation)) {
        return EditorStepType.ArContentRotationChange
    }
    if (!isEqual(previousArContent.scale, arContent.scale)) {
        return EditorStepType.ArContentScaleChange
    }
    if (!isEqual(previousArContent.location2D, arContent.location2D) || previousArContent.altitude !== arContent.altitude) {
        return EditorStepType.ArContentCoordinateChange
    }
    return null
}

export const getTransformStepBody = (stepType: EditorStepType, content: ARContent): Vector3 | null => {
    if (stepType === EditorStepType.ArContentPositionChange) return {
        x: content.position.x,
        y: content.position.y,
        z: content.position.z,
    }
    if (stepType === EditorStepType.ArContentRotationChange) return {
        x: content.rotation.x,
        y: content.rotation.y,
        z: content.rotation.z,
    }
    if (stepType === EditorStepType.ArContentScaleChange) return {
        x: content.scale.x,
        y: content.scale.y,
        z: content.scale.z,
    }
    return null
}

export const getCoordinateStepBody = (content: ARContent): number[] => {
    return [content.location2D.coordinates[0], content.location2D.coordinates[1], content.altitude]
}

const getArContentCoordinateFromStep = (step: EditorStep, arContent: ARContent) => {
    arContent.location2D = {
        ...arContent.location2D,
        coordinates: [step.coordinate![0], step.coordinate![1], step.coordinate![2]]
    }
    arContent.altitude = step.coordinate![2]
}

export const updateArContentsFromStep = (step: EditorStep, arContents: ARContent[]): ARContent[] => {
    const newContents = [...arContents]
    step.targetContents.forEach(content => {
        const index = arContents.findIndex(e => e._id === content)
        if (index === -1) return
        switch (step.type) {
            case EditorStepType.ArContentPositionChange:
                newContents[index].position = {
                    x: step.transform!.x,
                    y: step.transform!.y,
                    z: step.transform!.z,
                }
                break
            case EditorStepType.ArContentRotationChange:
                newContents[index].rotation = {
                    x: step.transform!.x,
                    y: step.transform!.y,
                    z: step.transform!.z,
                }
                break
            case EditorStepType.ArContentScaleChange:
                newContents[index].scale = {
                    x: step.transform!.x,
                    y: step.transform!.y,
                    z: step.transform!.z,
                }
                break
            case EditorStepType.ArContentCoordinateChange:
                getArContentCoordinateFromStep(step, newContents[index])
        }
        createOrUpdateARContent(newContents[index]);
    })
    return newContents
}

export const isArContentSelected = (arContent: ARContent, editorState: EditorState): boolean => {
    return editorState.selectedObjects.reduce((prev, object) => (prev || object.id === arContent._id), false)
}

export const isPoiSelected = (poi: Poi, editorState: EditorState): boolean => {
    return editorState.selectedObjects.reduce((prev, object) => (prev || object.id === poi._id), false)
}
