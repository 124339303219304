import { useThree } from "@react-three/fiber"
import { useEffect } from "react"
import { PerspectiveCamera } from "three"

interface TrackingProps {
    shouldGetPosition: boolean
    onPositionChange: (camera: PerspectiveCamera) => void
}

const Tracking = ({
    shouldGetPosition,
    onPositionChange
}: TrackingProps) => {
    const { camera } = useThree()
    useEffect(() => {
        if (!shouldGetPosition) return
        onPositionChange(camera as PerspectiveCamera)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldGetPosition])
    return <></>
}
export { Tracking }
