import React, { useEffect, useRef, useState } from "react"
import { connect } from 'react-redux'
import PropTypes from "prop-types"
import { Modal, ModalHeader, ModalBody } from "reactstrap";

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { Link } from "react-router-dom"

import { withRouter } from "../../tsxParser/router-v6";
import { OrganizationType } from "store/organization/types";
import { customerPortalUrl, isConsoleAccessAllowed } from 'constants/const';
import { ApplicationState } from "store/data";
import { PermissionType, UserPermission } from "store/permissions/types";


interface StateProps {
  organization: OrganizationType
  permission?: PermissionType
}

interface RouterProps {
  location: any
}

type Props = StateProps & RouterProps

const SidebarContent = (props: Props) => {
  const ref = useRef<SimpleBar>();
  const isSubscriptionExpired = !isConsoleAccessAllowed(props.organization)
  const urlParams = new URLSearchParams(window.location.search);
  const checkoutParams = urlParams.get('checkout');
  const [checkoutModalOpen, setCheckoutModalOpen] = useState(false);
  const isOwnerPermission = props.permission?.userPermission === UserPermission.Owner;
  const user = JSON.parse(localStorage.getItem("graffity-console-user")!);
  const managePaymentUrl = `${customerPortalUrl}?prefilled_email=${encodeURIComponent(user?.email)}`

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul!.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current!.recalculate()
  })

  useEffect(() => {
    if (checkoutParams === 'success') {
      setCheckoutModalOpen(true);
    }
  }, [])

  const closeCheckoutModal = () => {
    setCheckoutModalOpen(false);
  }

  function scrollElement(item: any) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current!.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item: any) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false
    }
    scrollElement(item);
    return false
  }

  return (
    <React.Fragment>
      {/* @ts-ignore */}
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{"Menu"}</li>
            <li>
              <Link to="/dashboard" className=" ">
                <i className="bi bi-house"></i>
                <span>{"Dashboard"}</span>
              </Link>
            </li>

            <li>
              <Link to="/projects" className=" ">
                <i className="bi bi-geo-alt"></i>
                <span>{"Projects"}</span>
              </Link>
            </li>

            <li>
              <Link to="/analytics" className=" ">
                <i className="bi bi-graph-up"></i>
                <span>{"Analytics"}</span>
              </Link>
            </li>

            <li className="menu-title">Settings</li>
            <li>
              <Link to="/account" className="">
                <i className="bi bi-person-circle"></i>
                <span>{"Account & Team"}</span>
              </Link>
            </li>
            <li>
              <Modal
                isOpen={checkoutModalOpen}
                toggle={closeCheckoutModal}
                data-testid="checkout-modal"
              >
                <ModalHeader toggle={closeCheckoutModal} data-testid="checkout-modal-header">Payment Successful</ModalHeader>
                <ModalBody>
                  Your subscription is activated.
                </ModalBody>
              </Modal>
              {
                isOwnerPermission &&
                (isSubscriptionExpired ?
                  <Link to="/payment" className="">
                    <i className="bi bi-credit-card"></i>
                    <span>{"Billing & Payment"}</span>
                  </Link>
                  :
                  <a href={managePaymentUrl} target="_blank" rel="noreferrer noopener" className="">
                    <i className="bi bi-credit-card"></i>
                    <span>{"Billing & Payment"}</span>
                    <span className="float-end">
                      <i className="bi bi-box-arrow-up-right fs-4"></i>
                    </span>
                  </a>)
              }
            </li>

            <li className="menu-title">{"Developer"}</li>

            <li>
              <a href="https://developers.graffity.tech" target="_blank" rel="noreferrer noopener" className="">
                <i className="bi bi-file-earmark-text"></i>
                <span>{"Documents"}</span>
                <span className="float-end">
                  <i className="bi bi-box-arrow-up-right fs-4"></i>
                </span>
              </a>
            </li>

            {isOwnerPermission &&
              <li>
                <Link to="/developer" className="">
                  <i className="bi bi-person-circle"></i>
                  <span>{"Developer Options"}</span>
                </Link>
              </li>
            }

          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  return {
    organization: state.Organization,
    permission: state.Permissions.find(permission => permission.organizationId === state.Organization._id)
  }
}

export default connect(mapStateToProps)(withRouter((SidebarContent)))
export { SidebarContent }
