import React, { useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import { Container, Row, Col } from "reactstrap"
import { getFirebaseBackend } from 'helpers/firebase_helper'

//Import Images
import logo from "../assets/images/logo-dark.png"
import maintanence from "../assets/images/coming-soon.svg"
import { PageMeta } from "components/Common/Header"

const VerifyEmailPage = () => {

  const firebaseBackend = getFirebaseBackend()
  const user = firebaseBackend.getCurrentUser()
  const navigate = useNavigate();

  useEffect(() => {
    if (!user || user.emailVerified || user.providerData[0]?.providerId !== "password") {
      navigate("/")
    }
    const dashboardUrl = window.location.origin
    user && firebaseBackend.verifyUserEmail(dashboardUrl)
  }, [firebaseBackend, navigate, user])

  return (
    <React.Fragment>
      <PageMeta title="Verify Email | Graffity Console"/>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-white">
          <i className="fas fa-home h2" />
        </Link>
      </div>

      <div className="my-5 pt-sm-5">
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center">

                <img src={logo} alt="logo" height="60" />

                <Row className="justify-content-center mt-5">
                  <Col sm="4">
                    <div className="maintenance-img">
                      <img
                        src={maintanence}
                        alt=""
                        className="img-fluid mx-auto d-block"
                      />
                    </div>
                  </Col>
                </Row>
                <h4 className="mt-5">Please Verify Your Email...</h4>
                <p className="text-muted">
                  Check you email inbox to verify your identity.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default VerifyEmailPage
