import { OrganizationType } from './types'

export const INIT_ORGANIZATION = "INIT_ORGANIZATION"
export const CHANGE_ORGANIZATION = "CHANGE_ORGANIZATION"

export const initOrganization = (organization: OrganizationType) => ({
    type: INIT_ORGANIZATION,
    payload: organization,
})

export const changeOrganization = (organization: OrganizationType) => ({
    type: CHANGE_ORGANIZATION,
    payload: organization,
})
