import axios from "axios"
import { generateBaseHeader } from "./apiHelper"
import { GraffityConsoleBackendURL } from "./const"
import { PointCloudPlace } from "pages/Projects/data"

export const getPointClouds = async (organizationId: string): Promise<PointCloudPlace[]> => {
    const header = await generateBaseHeader()
    const resp = await axios({
        method: "POST",
        url: GraffityConsoleBackendURL + "/private-api/v1/point-cloud/get",
        headers: {
            ...header,
        },
        data: {
            organizationId,
        },
    })

    if (resp.status === 200) {
        // console.log("update user success");
        return resp.data;
    }
    console.error("getPointClouds fail");
    return [];
}

export const getPlaceById = async (placeId: string): Promise<PointCloudPlace | null> => {
    const header = await generateBaseHeader()
    const resp = await axios({
        method: "GET",
        url: GraffityConsoleBackendURL + "/private-api/v1/point-cloud/" + placeId,
        headers: {
            ...header,
        },
    })

    if (resp.status === 200) {
        return resp.data;
    }
    console.error("getPointCloud fail");
    return null;
}
