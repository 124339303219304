import {
    Container,
    Card,
    CardBody,
} from "reactstrap";

import { withRouter } from "../../tsxParser/router-v6";

import VerticalLayout from "../../components/VerticalLayout";
import Breadcrumb from "../../components/Common/Breadcrumb";
import { useEffect } from "react";
import { PageMeta } from "components/Common/Header";

const Documents = () => {

    const openDocs = () => {
        window.open("https://developers.graffity.tech");
    };

    useEffect(() => {
        openDocs();
    }, [])

    return (
        <VerticalLayout>
            <div className="page-content">
                <PageMeta title="Documents | Graffity Console"/>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title="Console" breadcrumbItem="Documents" />

                    <Card>
                        <CardBody>

                        </CardBody>
                    </Card>
                </Container>
            </div>
        </VerticalLayout>
    );
};

export default withRouter(Documents);