import { PointCloudPlace } from '../../../../data'
import { Box } from 'components/Common/Layout/Layout'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Container, Row } from 'reactstrap'

interface MarkerProps {
    pointCloudPlace: PointCloudPlace
    link: string
}

const Marker: React.FC<MarkerProps> = (props: MarkerProps) => {

    return <Container fluid>
        <Row style={{ padding: "0.75rem" }} className='bg-white rounded g-0'>
            <Box display='flex' justifyContent='space-between'>
                <div className='me-2'>
                    <h5 style={{
                        fontSize: "0.9rem",
                        maxWidth: 95,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                    }} className='mb-1'
                    >
                        {props.pointCloudPlace.name}
                    </h5>
                    <p className='mb-0'>View Point Cloud</p>
                </div>
                <a href={props.link}>
                    <Button color='primary' className='rounded-circle p-0' style={{ width: 40, height: 40 }}><i className='bi bi-chevron-right fs-3' /></Button>
                </a>
            </Box>
        </Row>
        <Box fullWidth display='flex' justifyContent='center'>
            <div style={{ width: 2, height: 30 }} className='bg-white' />
        </Box>
        <Box fullWidth display='flex' justifyContent='center'>
            <div style={{ width: 20, height: 20 }} className='bg-white rounded-circle' />
        </Box>
    </Container>
}

export { Marker }
