import { AnyAction } from 'redux'

import { INIT_EDITOR, CHANGE_SELECTED_AR_CONTENT, TOGGLE_MAP_OVERLAY, CHANGE_FLOOR, INIT_FLOORS, UPDATE_ERROR, UPDATE_INITIAL_POSITION, CHANGE_SELECTED_POI, TOGGLE_MAP_OVERLAY_AVAILABLE, ADD_SELECTED_AR_CONTENT, CHANGE_GROUP_TRANSFORM, CLEAR_SELECTED_OBJECTS } from "./actions"

import { EditorState, SelectedObjectType } from "./types";
import { Vector3 } from 'three';

const INIT_STATE: EditorState = {
    selectedObjects: [],
    selectedObjectType: SelectedObjectType.ArContent,
    groupTransform: null,
    selectedFloor: undefined,
    floors: [],
    errors: [],
    objectInitialPosition: new Vector3(0, 0, 0),
    showMapOverlay: false,
    isMapOverlayAvailable: false,
}

const Editor = (state = INIT_STATE, action: AnyAction): EditorState => {
    switch (action.type) {
        case INIT_EDITOR:
            return {
                ...action.payload,
            }
        case CHANGE_SELECTED_AR_CONTENT:
            return {
                ...state,
                selectedObjects: [
                    {
                        id: action.payload,
                    }
                ],
                selectedObjectType: SelectedObjectType.ArContent,
            }
        case CHANGE_SELECTED_POI:
            return {
                ...state,
                selectedObjects: [
                    {
                        id: action.payload,
                    }
                ],
                selectedObjectType: SelectedObjectType.Poi,
            }
        case ADD_SELECTED_AR_CONTENT:
            if (state.selectedObjectType === SelectedObjectType.ArContent || state.selectedObjects.length < 1) {
                return {
                    ...state,
                    selectedObjects: [
                        ...state.selectedObjects,
                        {
                            id: action.payload,
                        }
                    ],
                    selectedObjectType: SelectedObjectType.ArContent,
                }
            }
            return {
                ...state
            }
        case CLEAR_SELECTED_OBJECTS:
            return {
                ...state,
                selectedObjects: [],
            }
        case CHANGE_GROUP_TRANSFORM:
            return {
                ...state,
                groupTransform: action.payload
            }
        case CHANGE_FLOOR:
            return {
                ...state,
                selectedFloor: action.payload
            }
        case INIT_FLOORS:
            return {
                ...state,
                floors: action.payload
            }
        case UPDATE_ERROR:
            return {
                ...state,
                errors: action.payload
            }
        case UPDATE_INITIAL_POSITION:
            return {
                ...state,
                objectInitialPosition: action.payload
            }
        case TOGGLE_MAP_OVERLAY:
            return {
                ...state,
                showMapOverlay: action.payload
            }
        case TOGGLE_MAP_OVERLAY_AVAILABLE:
            return {
                ...state,
                isMapOverlayAvailable: action.payload
            }

        default:
            return state
    }
}

export { Editor }
