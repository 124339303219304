import { useEffect, useState } from 'react';
import { getAccessTokenByDatabaseId } from '../../../apis/accessToken';
import { AccessToken } from '../../AccessTokens/helpers';

interface AccessTokensFetch {
    data?: AccessToken
    isFetching: boolean
    isFinished: boolean
}

export const useAccessTokenData = (accessTokenDatabaseId: string | undefined) => {

    const [dataState, setDataState] = useState<AccessTokensFetch>({ data: undefined, isFetching: false, isFinished: false });

    useEffect(() => {
        const fetchDataFromApi = async () => {

            if (accessTokenDatabaseId) {
                try {
                    setDataState({ ...dataState, isFetching: true });
                    let token = await getAccessTokenByDatabaseId(accessTokenDatabaseId);
                    if (!token) token = undefined;
                    setDataState({
                        ...dataState,
                        data: token,
                        isFetching: false,
                        isFinished: true,
                    });
                } catch (e) {
                    console.log(e);
                    setDataState({ ...dataState, isFetching: false });
                }
            } else {
                setDataState({ ...dataState, isFetching: false });
            }


        };

        if (localStorage.getItem("graffity-console-user")) {
            fetchDataFromApi();
        }

    }, []); // Runs once

    return dataState;
};