import { customAlphabet } from "nanoid";
import React, { useEffect, useState } from "react";
import {
    Container,
    Row,
    Col,
    Button,
    Label,
    Input,
    Spinner,
    FormGroup,
} from "reactstrap";
import { createAccessToken, deleteAccessToken, generateV2AccessToken } from "../../../apis/accessToken";
import { createOrUpdateProject } from "../../../apis/project";
import { AccessToken, generateAccessToken, getTokenPrefix } from "../../AccessTokens/helpers";
import { updateARContentAccessTokenByProjectId } from "../helpers";
import { useAccessTokenData } from "./data";
import { SettingProps } from "./ProjectSetting";
import { isV2Token } from "constants/const"
import { Box } from "components/Common/Layout/Layout";

const nanoid = customAlphabet('1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ', 12);

const AccessTokenSetting = ({
    project,
}: SettingProps) => {

    const accessToken = useAccessTokenData(project.accessTokenRefId);
    const [isAllowGenerateNewToken, setIsAllowGenerateNewToken] = useState(false);
    const [generatedToken, setGeneratedToken] = useState<string | undefined>();
    const [showToken, setShowToken] = useState<string | undefined>();

    const onGenerateNewToken = async () => {
        if (isV2Token) {
            generateV2AccessToken(project._id || "", true).then(generatedToken => {
                const tokenToDisplay = `Bearer ${generatedToken}`
                setShowToken(tokenToDisplay)
                setGeneratedToken(tokenToDisplay)
            })
            return
        }
        // if exist delete the old one
        if (accessToken.data?.accessTokenId) {
            // console.log("delete access token", accessToken.data?.accessTokenId)
            deleteAccessToken(accessToken.data?.accessTokenId);
        }

        const newToken = generateAccessToken();
        // console.log("newToken", newToken)
        setGeneratedToken(newToken);
        setShowToken(newToken);
        const newTokenPrefix = getTokenPrefix(newToken);

        // API Store Hash Token
        const obj = JSON.parse(localStorage.getItem("graffity-console-user")!);
        const newTokenObject: AccessToken = {
            accessTokenId: nanoid(),
            raw: newToken,
            prefix: newTokenPrefix,
            lastModified: Date.now(),
            createdAt: Date.now(),
            scopes: ["default"],
            firebaseAuthUid: obj.firebaseAuthUid,
        }
        let _id = await createAccessToken(newTokenObject);
        // console.log("_id", _id)

        // Update project & arContent with new token

        let updateProject = project
        updateProject.firebaseAuthUid = obj.firebaseAuthUid; // for API req.
        // In case access token was changed
        updateProject.accessTokenRefId = _id;
        // console.log("updateProject.accessTokenRefId", updateProject.accessTokenRefId)

        // In case access token was changed - update ARContent too!
        updateARContentAccessTokenByProjectId(project._id || '', _id);

        const isCreateOrUpdateSuccess = await createOrUpdateProject(updateProject);
        // console.log("isCreateOrUpdateSuccess", isCreateOrUpdateSuccess);
    }

    useEffect(() => {
        // console.log("accessToken.data", accessToken.data)
        if (isV2Token) {
            setShowToken("Bearer ey...");
            setIsAllowGenerateNewToken(false);
            return
        }
        if (accessToken.isFinished) {
            setShowToken(accessToken.data?.prefix + "...");
            setIsAllowGenerateNewToken(false);
        }
    }, [accessToken]);

    return (
        <React.Fragment>
            <Box
                background="light"
                padding="XL"
                borderColor="dark"
                borderRadius="rounded"
                className="mb-4 border-opacity-10"
            >
                <Box fullWidth display="flex" justifyContent="space-between">
                    <Box display="flex" alignItem="center">
                        <Label>Access Token</Label>
                    </Box>
                    <Box display="flex" justifyContent="space-between">
                        <Box display="flex" alignItem="center" className="text-nowrap me-5">
                            Allow Gen New Token
                        </Box>
                        <Box display="flex" justifyContent="flex-end">
                            <FormGroup switch>
                                <Input
                                    className="fs-3"
                                    type="switch"
                                    role="switch"
                                    defaultChecked={isAllowGenerateNewToken}
                                    onChange={(e) => setIsAllowGenerateNewToken(e.target.checked)}
                                />
                            </FormGroup>
                        </Box>
                    </Box>
                </Box>
                <Row>
                    <p className="text-muted m-b-15">
                        <code>The token will only be shown once its created for security reason.</code> Please save your key at a secured place.<br />
                        You need an API access token to configure Graffity SDK. Read more about API access tokens in <a href="https://developers.graffity.tech/" rel="noreferrer" target="_blank">our documentation</a>.<br />
                        Note that exisiting token will be disabled and removed when you create the new token.
                    </p>
                </Row>
                <Label>Generated Token</Label>
                <Input
                    id="token-name"
                    type="text"
                    className="form-control mb-3"
                    readOnly
                    placeholder={showToken}
                />
                <Box display="flex" fullWidth justifyContent="flex-end">
                    <Box>
                        <Button
                            color="primary"
                            className="me-2"
                            onClick={onGenerateNewToken}
                            disabled={!isAllowGenerateNewToken || !!generatedToken}
                        >
                            <i className="bi bi-file-earmark-plus fs-5 me-1" />Generate Token
                        </Button>
                        <Button
                            color="secondary"
                            outline
                            onClick={() => {
                                navigator.clipboard.writeText(generatedToken!);
                            }}
                            disabled={!generatedToken}
                        >
                            <i className="bi bi-copy fs-5 me-1" />Copy Token
                        </Button>
                    </Box>
                </Box>


            </Box>
            <Box
                background="light"
                padding="XL"
                borderColor="dark"
                borderRadius="rounded"
                className="mb-4 border-opacity-10"
            >
                <Label>API Scopes</Label>
                <p><code>Not required for BETA version.</code> All tokens, regardless of the scopes included, are able to request api service.</p>
                <Col sm="3">
                    <div className="form-check mb-3">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="defaultCheck1"
                            checked
                            disabled
                        />
                        <label
                            className="form-check-label"
                            htmlFor="defaultCheck1"
                        >
                            USER:WRITE
                        </label>
                    </div>
                </Col>
                <Col sm="3">
                    <div className="form-check mb-3">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="defaultCheck1"
                            checked
                            disabled
                        />
                        <label
                            className="form-check-label"
                            htmlFor="defaultCheck1"
                        >
                            VISION:READ
                        </label>
                    </div>
                </Col>
                <Col sm="3">
                    <div className="form-check mb-3">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="defaultCheck1"
                            disabled
                            checked
                        />
                        <label
                            className="form-check-label"
                            htmlFor="defaultCheck1"
                        >
                            MAP:WRITE
                        </label>
                    </div>
                </Col>
            </Box>
            <Box
                background="light"
                padding="XL"
                borderColor="dark"
                borderRadius="rounded"
                className="border-opacity-10"
            >
                <Label>Token restrictions</Label>
                <p><code>Not required for BETA version.</code> Restrict this token to specific app bundle id for iOS and SHA key for android.</p>

                <Input
                    id="ios-app-bundle-id"
                    type="text"
                    className="form-control mb-3"
                    placeholder="iOS App Bundle ID"
                    disabled
                />
                <Input
                    id="android-sha"
                    type="text"
                    className="form-control"
                    placeholder="Android App SHA certificate fingerprints"
                    disabled
                />
            </Box>
        </React.Fragment>
    )
}

export default AccessTokenSetting;