import React, { useEffect, ChangeEvent, useState } from "react"
import {
    Input,
} from "reactstrap";
import { ARContent, Effect, EffectActionType, EffectTriggerType } from "pages/Projects/data";
import { connect } from "react-redux";
import { EditorState } from "store/editor/types";
import { ApplicationState } from "store/data";
import { Box } from "components/Common/Layout/Layout";

interface OwnProps {
    arContents: ARContent[]
    onUpdateARContentState: (index: number, obj: ARContent) => void
    onUpdateARContent: (index: number, obj: ARContent) => void
    onRemoveARContent: (obj: ARContent) => void
}

interface StateProps {
    editorState: EditorState
}

type ARContentEffectProps = StateProps & OwnProps

const ARContentEffectComponent = ({
    arContents,
    onUpdateARContentState,
    onUpdateARContent,
    onRemoveARContent,
    editorState,
}: ARContentEffectProps) => {
    const [isValidUrl, setIsValidUrl] = useState(true)
    const index = arContents.findIndex(arContent => arContent._id === editorState.selectedObjects[0]?.id);
    const arContent = arContents[index];
    const effects = arContent?.effects
    const effect = effects?.[0]

    const validateUrl = (url: string) => {
        try {
            new URL(url)
            if (url.includes("https")) {
                return true
            }
        } catch (_) {
            return false
        }
    }

    useEffect(() => {
        if (effects === undefined || effects.length === 0) {
            const newEffect: Effect = {
                trigger: EffectTriggerType.Tap,
                actionType: EffectActionType.OpenUrl,
            }
            const newArContent = {
                ...arContent,
                effects: [newEffect],
            }
            onUpdateARContent(index, newArContent)
            return
        }
    }, [arContent])

    const handleSelectChanges = (field: keyof Effect) => (e: ChangeEvent<HTMLInputElement>) => {
        // only support <= 1 Effect in effects field
        if (!effect) return
        const newEffect: Effect = {
            ...effect,
            [field]: e.target.value,
        }
        const newArContent = {
            ...arContent,
            effects: [newEffect],
        }
        onUpdateARContent(index, newArContent)
        return
    }

    return (
        <Box fullWidth>
            <h6>Trigger</h6>
            <Input type="select" className="mb-2" onChange={handleSelectChanges("trigger")} value={effect?.trigger}>
                <option value={EffectTriggerType.Tap}>
                    Tap
                </option>
            </Input>
            <h6>Action</h6>
            <Input type="select" className="mb-2" onChange={handleSelectChanges("actionType")} value={effect?.actionType}>
                <option value={EffectActionType.OpenUrl}>
                    Open URL
                </option>
                <option value={EffectActionType.Collect}>
                    Collect
                </option>
            </Input>
            {
                effect !== undefined &&
                <>
                    {effect.actionType === EffectActionType.OpenUrl ?
                        <Box className="rounded" background="light" fullWidth padding="L">
                            <h6>Open URL</h6>
                            <Input
                                onChange={(e) => {
                                    const newEffect: Effect = {
                                        ...effect,
                                        openUrl: e.target.value
                                    }
                                    const newArContent: ARContent = {
                                        ...arContent,
                                        effects: [newEffect]
                                    }
                                    if (!validateUrl(e.target.value)) {
                                        setIsValidUrl(false)
                                        onUpdateARContent(index, newArContent)
                                        return
                                    }
                                    setIsValidUrl(true)
                                    onUpdateARContent(index, newArContent)
                                }}
                                value={effect?.openUrl || ""}
                            />
                            {!isValidUrl && <code>Invalid Url</code>}
                        </Box>
                        :
                        <Box display="flex" className="rounded" background="light" alignItem="center" fullWidth padding="L">
                            <h6 className="mb-0">Enable Collect</h6>
                            <input
                                className="form-check-input ms-2 mt-0"
                                id="is-front-facing-checkbox"
                                type="checkbox"
                                checked={effect.enabled}
                                onChange={e => {
                                    const newEffect: Effect = {
                                        ...effect,
                                        enabled: e.target.checked
                                    }
                                    const newArContent: ARContent = {
                                        ...arContent,
                                        effects: [newEffect]
                                    }
                                    onUpdateARContent(index, newArContent)
                                }} />
                        </Box>
                    }

                </>
            }
        </Box>
    )
}

ARContentEffectComponent.displayName = "ARContentEffectComponent";

const mapStateToProps = (state: ApplicationState): StateProps => ({
    editorState: state.Editor,
})

const ARContentEffect = connect(mapStateToProps)(ARContentEffectComponent);

export { ARContentEffect, ARContentEffectComponent }
