import React, { useState } from "react"
import {
    Label,
    InputGroup,
    Input,
    Row,
    Col,
} from "reactstrap";
import { GroupObject } from "pages/Projects/data";
import "./groupOption.scss"
import { LabeledInput } from "../common/LabeledInput/LabeledInput";

interface OwnProps {
    group: GroupObject
    onUpdateGroupState: (obj: GroupObject) => void
    onUpdateGroup: (obj: GroupObject) => void
}

type GroupOptionProps = OwnProps

const GroupOption = ({
    group,
    onUpdateGroup,
    onUpdateGroupState,
}: GroupOptionProps) => {
    const [pendingChange, setPendingChange] = useState({
        position: {
            x: false,
            y: false,
            z: false,
        },
        rotation: {
            x: false,
            y: false,
            z: false,
        },
        scale: {
            x: false,
            y: false,
            z: false,
        },
    })
    const [lockedScale, setLockedScale] = useState(false);

    const relativePositionLimit = 10
    const minRelativePosition = -relativePositionLimit.toString()
    const maxRelativePosition = relativePositionLimit.toString()

    const handleScaleChange = (axis: 'x' | 'y' | 'z') => (shouldUpdateServer: boolean) => (value: string) => {
        const updateObj = { ...group };
        const newScale = { ...group.scale }
        if (lockedScale) {
            newScale.x = parseFloat(parseFloat(value).toFixed(2));
            newScale.y = parseFloat(parseFloat(value).toFixed(2));
            newScale.z = parseFloat(parseFloat(value).toFixed(2));
        } else newScale[axis] = parseFloat(parseFloat(value).toFixed(2));

        if (
            newScale['x'] === updateObj.scale['x']
            && newScale['y'] === updateObj.scale['y']
            && newScale['z'] === updateObj.scale['z']
        ) {
            return
        }

        updateObj.scale = newScale

        if (shouldUpdateServer) {
            onUpdateGroup(updateObj)
        } else {
            setPendingChange({
                ...pendingChange,
                scale: {
                    ...pendingChange.scale,
                    [axis]: true,
                }
            })
            onUpdateGroupState(updateObj);
        }
    }

    const handleRotationChange = (axis: 'x' | 'y' | 'z') => (shouldUpdateServer: boolean) => (value: string) => {
        const updateObj = { ...group };
        const newRotation = { ...group.rotation }
        newRotation[axis] = parseFloat(parseFloat(value).toFixed(2)) % 360;
        if (newRotation[axis] === updateObj.rotation[axis]) {
            return
        }

        updateObj.rotation = newRotation

        if (shouldUpdateServer) {
            onUpdateGroup(updateObj)
        } else {
            setPendingChange({
                ...pendingChange,
                rotation: {
                    ...pendingChange.rotation,
                    [axis]: true,
                }
            })
            onUpdateGroupState(updateObj);
        }
    }

    const handlePositionChange = (axis: 'x' | 'y' | 'z') => (shouldUpdateServer: boolean) => (value: string) => {
        const updateObj = { ...group };
        const newPosition = { ...group.position }
        newPosition[axis] = parseFloat(parseFloat(value).toFixed(2));
        if (newPosition[axis] === updateObj.position[axis]) {
            return
        }

        updateObj.position = newPosition

        if (shouldUpdateServer) {
            onUpdateGroup(updateObj)
        } else {
            setPendingChange({
                ...pendingChange,
                position: {
                    ...pendingChange.position,
                    [axis]: true,
                }
            })
            onUpdateGroupState(updateObj);
        }
    }

    const toggleLock = () => {
        if (!lockedScale) {
            let updateObj = group;
            updateObj.scale.y = updateObj.scale.x;
            updateObj.scale.z = updateObj.scale.x;
            onUpdateGroupState(updateObj);
        }
        setLockedScale(!lockedScale)
    }

    const handleSliderRelease = (key: "position" | "rotation" | "scale", axis: "x" | "y" | "z") => () => {
        if (pendingChange[key][axis]) {
            onUpdateGroup(group)
            setPendingChange({
                ...pendingChange,
                [key]: {
                    ...pendingChange[key],
                    [axis]: false,
                }
            })
        }
    }

    return (
        <div className="GroupOption">
            {/* Relative Position Options */}
            <React.Fragment>
                <h6>Position</h6>
                <div className="row mb-3" data-testid="relative-position-option-group">
                    <LabeledInput
                        title="X-axis"
                        type="number"
                        value={group.position.x}
                        onStateChange={handlePositionChange('x')(false)}
                        onChange={handlePositionChange('x')(true)}
                        inputComponent={
                            <input
                                type="range"
                                className="form-range"
                                min={minRelativePosition}
                                max={maxRelativePosition}
                                step={0.5}
                                id="positionX"
                                value={group.position.x}
                                onChange={e => handlePositionChange('x')(false)(e.target.value)}
                                onMouseUp={handleSliderRelease('position', 'x')} />
                        }
                    />
                    <LabeledInput
                        title="Y-axis"
                        type="number"
                        value={group.position.y}
                        onStateChange={handlePositionChange('y')(false)}
                        onChange={handlePositionChange('y')(true)}
                        inputComponent={
                            <input
                                type="range"
                                className="form-range"
                                min={minRelativePosition}
                                max={maxRelativePosition}
                                step={0.5}
                                id="positionY"
                                value={group.position.y}
                                onChange={e => handlePositionChange('y')(false)(e.target.value)}
                                onMouseUp={handleSliderRelease('position', 'y')} />
                        }
                    />
                    <LabeledInput
                        title="Z-axis"
                        type="number"
                        value={group.position.z}
                        onStateChange={handlePositionChange('z')(false)}
                        onChange={handlePositionChange('z')(true)}
                        inputComponent={
                            <input
                                type="range"
                                className="form-range"
                                min={minRelativePosition}
                                max={maxRelativePosition}
                                step={0.5}
                                id="positionZ"
                                value={group.position.z}
                                onChange={e => handlePositionChange('z')(false)(e.target.value)}
                                onMouseUp={handleSliderRelease('position', 'z')} />
                        }
                    />
                </div>
                <h6>Rotation</h6>
                <div className="row mb-3" data-testid="rotation-option-group">
                    <LabeledInput
                        title="X-axis"
                        type="number"
                        value={group.rotation.x}
                        onStateChange={handleRotationChange('x')(false)}
                        onChange={handleRotationChange('x')(true)}
                        inputComponent={
                            <input
                                type="range"
                                className="form-range"
                                min="-360" max="360"
                                step={5}
                                id="rotationX"
                                value={group.rotation.x}
                                onChange={e => handleRotationChange('x')(false)(e.target.value)}
                                onMouseUp={handleSliderRelease('rotation', 'x')} />
                        }
                    />
                    <LabeledInput
                        title="Y-axis"
                        type="number"
                        value={group.rotation.y}
                        onStateChange={handleRotationChange('y')(false)}
                        onChange={handleRotationChange('y')(true)}
                        inputComponent={
                            <input
                                type="range"
                                className="form-range"
                                min="-360" max="360"
                                step={5}
                                id="rotationY"
                                value={group.rotation.y}
                                onChange={e => handleRotationChange('y')(false)(e.target.value)}
                                onMouseUp={handleSliderRelease('rotation', 'y')} />
                        }
                    />
                    <LabeledInput
                        title="Z-axis"
                        type="number"
                        value={group.rotation.z}
                        onStateChange={handleRotationChange('z')(false)}
                        onChange={handleRotationChange('z')(true)}
                        inputComponent={
                            <input
                                type="range"
                                className="form-range"
                                min="-360" max="360"
                                step={5}
                                id="rotationZ"
                                value={group.rotation.z}
                                onChange={e => handleRotationChange('z')(false)(e.target.value)}
                                onMouseUp={handleSliderRelease('rotation', 'z')} />
                        }
                    />
                </div>
                <Row >
                    <Col className="lockableTitle">
                        <h6 className="lockTitle">Scale</h6>
                    </Col>
                    <Col className="lockableIcon">
                        <i
                            className={`font-size-16 align-middle lockIcon mdi ${lockedScale ? 'mdi-link-lock' : 'mdi-link'}`}
                            onClick={toggleLock}
                        />
                    </Col>
                </Row>
                <div className="row mb-3" data-testid="scale-option-group">
                    <div className="text-muted">
                        <LabeledInput
                            title={lockedScale ? "X, Y, Z" : "X-axis"}
                            type="number"
                            value={group.scale.x}
                            onStateChange={handleScaleChange('x')(false)}
                            onChange={handleScaleChange('x')(true)}
                            inputComponent={
                                <input
                                    type="range"
                                    className="form-range"
                                    min={0.01} max={10}
                                    step={0.01}
                                    id="scaleARContent"
                                    value={group.scale.x}
                                    onChange={e => handleScaleChange('x')(false)(e.target.value)}
                                    onMouseUp={handleSliderRelease('scale', 'x')} />
                            }
                        />
                    </div>
                    {
                        lockedScale ? <>
                        </> : <>
                            <LabeledInput
                                title="Y-axis"
                                type="number"
                                value={group.scale.y}
                                onStateChange={handleScaleChange('y')(false)}
                                onChange={handleScaleChange('y')(true)}
                                inputComponent={
                                    <input
                                        type="range"
                                        className="form-range"
                                        min={0.01} max={10}
                                        step={0.01}
                                        id="scaleARContent"
                                        value={group.scale.y}
                                        onChange={e => handleScaleChange('y')(false)(e.target.value)}
                                        onMouseUp={handleSliderRelease('scale', 'y')} />
                                }
                            />
                            <LabeledInput
                                title="Z-axis"
                                type="number"
                                value={group.scale.z}
                                onStateChange={handleScaleChange('z')(false)}
                                onChange={handleScaleChange('z')(true)}
                                inputComponent={
                                    <input
                                        type="range"
                                        className="form-range"
                                        min={0.01} max={10}
                                        step={0.01}
                                        id="scaleARContent"
                                        value={group.scale.z}
                                        onChange={e => handleScaleChange('z')(false)(e.target.value)}
                                        onMouseUp={handleSliderRelease('scale', 'z')} />
                                }
                            />
                        </>
                    }
                </div>
            </React.Fragment>
        </div >
    )
}

GroupOption.displayName = "PoiOptionComponent";

export { GroupOption }
