import React from "react"
import { Link } from "react-router-dom"
import { Container, Row, Col, Spinner } from "reactstrap"

//Import Images
import error from "../assets/images/error-img.png"
import { PageMeta } from "components/Common/Header";

// import React, { useState, useCallback, useEffect } from 'react';
// import {
//   GoogleReCaptchaProvider,
//   useGoogleReCaptcha
// } from 'react-google-recaptcha-v3';
// import { verifyRecaptcha } from "../apis/recapcha";

// const YourReCaptchaComponent = () => {
//   const { executeRecaptcha } = useGoogleReCaptcha();

//   const handleReCaptchaVerify = useCallback(async () => {
//     if (!executeRecaptcha) {
//       console.log('Execute recaptcha not yet available');
//       return;
//     }

//     const token = await executeRecaptcha('LoginPage');
//     console.log("token", token)

//     const verify = await verifyRecaptcha(token);

//     if (verify.success) {
//       console.log("verify.score", verify.score);
//     }

//   }, []);

//   useEffect(() => {
//     handleReCaptchaVerify();
//   }, [handleReCaptchaVerify]);

//   return <Button onClick={handleReCaptchaVerify}>Verify recaptcha</Button>;
// };


const Pages404 = () => {
  return (
    <React.Fragment>
      {/* <GoogleReCaptchaProvider reCaptchaKey="" useEnterprise={true}>
        <YourReCaptchaComponent />
      </GoogleReCaptchaProvider> */}

      <div className="account-pages my-5 pt-5">
        <PageMeta title="404 Error Page | Graffity Console"/>
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center mb-5">
                <h1 className="display-2 font-weight-medium">
                  404
                </h1>
                <h4 className="text-uppercase">Sorry, page not found</h4>
                <div className="mt-5 text-center">
                  <Link
                    className="btn btn-primary "
                    to="/dashboard"
                  >
                    Back to Dashboard
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md="8" xl="6">
              <div>
                <img src={error} alt="" className="img-fluid" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Pages404
