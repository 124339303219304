import React, { Suspense, useRef, useState } from 'react'
import { ARContent, ARContentType, Project, ARAnchorType, Location2D, VersionType, EffectTriggerType, EffectActionType } from "pages/Projects/data";
import { getUrlPutARContent, getUrlGetARContent, putARContent, createOrUpdateARContent, deleteOrphan, convertARContentFile, getVideoUploadUrl, uploadVideo } from "apis/arContent";
import { GLTFHelper } from "../../glTFHelpers";
import { customAlphabet } from 'nanoid';
import { DropzoneOptions } from 'react-dropzone';
import { getImageMeta, getVideoMeta } from './utils';


export const MAX_FILE_SIZE = 524288000 // 500MB
export const MAX_ANCHOR_IMAGE_SIZE = 10485760 // 10MB
export const MAX_VIDEO_SIZE = 419430400 // 400 MB
export const uploadFileTypes = {
    "image/*": [".png", ".jpg", ".jpeg"],
    "model/gltf-binary": [],
    "model/gltf+json": [],
    "application/zip": [],
    ".glb": [],
}
export const iosFileTypes = ".usdz"
const supportedExt = ['png', 'jpg', 'jpeg', 'glb', 'gltf', 'zip', 'mp4', 'mov', 'avi', 'webm']
const nanoid = customAlphabet('abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890', 15);

const uploadProgressSync: UploadProgress[] = []

export enum UploadStatusType {
    info,
    success,
    error,
    warning,
}

export enum UploadType {
    arContent,
    anchor,
}

export interface UploadProgress {
    id: string
    name: string
    progress: number
    error?: string
    isComplete?: boolean
    type?: ARContentType
}

const getUploadMessage = (upload: UploadProgress) => {
    if (upload.isComplete) {
        return "Upload Completed!"
    }
    if (upload.type === ARContentType.Model3d) {
        return "...Uploading & auto converting .usdz file"
    }
    return "...Uploading"
}

const getDefaultDropzoneOption = (): DropzoneOptions => ({
    accept: {
        'image/jpeg': ['.jpg', '.jpeg'],
        'image/png': ['.png'],
        'model/gltf-binary': ['.glb', '.gltf'],
        'application/x-zip': ['.zip'],
        'model/usd': ['.usdz'],
        'video/mp4': ['.mp4'],
        'video/x-msvideo': ['.avi'],
        'video/webm': ['.webm'],
        'video/quicktime': ['.mov'],
    },
})

const getDefaultIosDropzoneOption = (): DropzoneOptions => ({
    accept: {
        'model/usd': ['.usdz'],
    },
})

const useUpload = (
    onUploadSuccess: (addedObject: ARContent[], shouldRefresh: boolean, redirectAnchorType?: ARAnchorType, redirectAnchorId?: string) => void,
    onUploadStatusChange: (type: UploadStatusType, message: string) => void,
    project?: Project,
    detectionImageId?: string | null,
    faceAnchorId?: string | null,
    mapCenter?: string | null,
    pointCloudId?: string | null,
    floorId?: string | null,
    arContent?: ARContent,
    defaultPosition?: THREE.Vector3,
    uploadType = UploadType.arContent
) => {
    const [enableUpload, setEnableUpload] = useState(true)
    const [isUploading, setIsUploading] = useState(false)
    const [uploadProgress, setUploadProgress] = useState<UploadProgress[]>([])
    const shouldBlockUpload = !enableUpload || !project || (!faceAnchorId && !detectionImageId && !(uploadType === UploadType.anchor) && !(project.lastOpenedAnchorType === ARAnchorType.WorldAnchor))

    const handleUploadProgress = (id: string) => (progressEvent: any) => {
        if (progressEvent?.loaded !== undefined && progressEvent.total !== undefined) {
            const progressNumber = (progressEvent.loaded / progressEvent.total) * 100
            const progressIndex = uploadProgressSync.findIndex(e => e.id === id)
            const prevProgress = uploadProgressSync[progressIndex]
            uploadProgressSync[progressIndex] = {
                ...prevProgress,
                progress: progressNumber
            }
            setUploadProgress([...uploadProgressSync])
        }
    }

    const handleUploadError = (id: string, error: string) => {
        const progressIndex = uploadProgressSync.findIndex(e => e.id === id)
        const prevProgress = uploadProgressSync[progressIndex]
        uploadProgressSync[progressIndex] = {
            ...prevProgress,
            error,
        }
        setUploadProgress([...uploadProgressSync])
    }

    const handleUploadComplete = (id: string) => {
        const progressIndex = uploadProgressSync.findIndex(e => e.id === id)
        const prevProgress = uploadProgressSync[progressIndex]
        uploadProgressSync[progressIndex] = {
            ...prevProgress,
            isComplete: true,
            progress: 100,
        }
        setUploadProgress([...uploadProgressSync])
    }

    const addUploadItem = (name: string, type?: ARContentType): string => {
        const id = nanoid()
        uploadProgressSync.push({
            id,
            name,
            progress: 0,
            type,
        })
        setUploadProgress([...uploadProgressSync])
        return id
    }

    const resetUpload = () => {
        uploadProgressSync.splice(0)
        setUploadProgress([])
        setEnableUpload(true)
        setIsUploading(false)
    }

    const getDefaultArContent = () => {
        const defaultArContent: ARContent = {
            arContentId: nanoid(),
            projectRefId: project?._id || "",
            parentId: "",
            accessTokenRefId: project?.accessTokenRefId || "",
            lastModified: Date.now(),
            createdAt: Date.now(),
            arContentType: ARContentType.Model3d,
            arAnchorType: project?.lastOpenedAnchorType || ARAnchorType.WorldAnchor,
            fileExtWithoutDot: "",
            userFilename: "",
            version: VersionType.Draft,

            location2D: {
                type: "Point",
                coordinates: [
                    mapCenter ? parseFloat(mapCenter!.split(",")[0]) : project?.previewLocations[0].location2D.coordinates[0] || 0,
                    mapCenter ? parseFloat(mapCenter!.split(",")[1]) : project?.previewLocations[0].location2D.coordinates[1] || 0
                ]
            } as Location2D,
            storeId: "",
            storeUrl: "",
            downloadUrl: "",
            altitude: 10,
            scale: {
                x: 1.0,
                y: 1.0,
                z: 1.0,
            },
            rotation: {
                x: 0.0,
                y: 0.0,
                z: 0.0,
            },
            position: defaultPosition ? {
                x: defaultPosition.x,
                y: defaultPosition.y,
                z: defaultPosition.z,
            } : {
                x: 0.0,
                y: 0.0,
                z: 0.0,
            },
            userFilenameIos: "",
            storeUrlIos: "",
            iosDownloadUrl: "",
            isPublicStore: false,
            isFrontFacing: true,
            renderRadiusInMeter: 0,
            isIndoorPointCloud: pointCloudId ? pointCloudId.length !== 0 : false,
            indoorPointCloudRefId: pointCloudId || undefined,
            floorId: floorId || undefined,
            effects: [
                {
                    trigger: EffectTriggerType.Tap,
                    actionType: EffectActionType.OpenUrl,
                }
            ]
        }
        return defaultArContent
    }

    const createARContentFromImport = async (files: File[], arContentTypeOverride?: ARContentType) => {
        const isDetectionImage = arContentTypeOverride === ARContentType.DetectionImage
        // block floorless reference image
        if (shouldBlockUpload) return
        let newObjects: ARContent[] = []
        let shouldRefreshAfterUpload = false

        for (let i = 0; i < files.length; i++) {
            let isWrongFileFormat = false;
            let originalFile = files[i]!

            let fileExtWithoutDot = originalFile.name.split('.').pop();
            if (fileExtWithoutDot === undefined) continue
            let filename = originalFile.name.split(fileExtWithoutDot)[0];
            let userFilename = filename.slice(0, filename.length - 1);

            // Convert to .glb...fileExtWithoutDot is blocked with uploadFileTypes
            if (fileExtWithoutDot === "gltf" || fileExtWithoutDot === "zip") {
                fileExtWithoutDot = "glb";
                let glbHelper = new GLTFHelper(userFilename, originalFile);
                let convertedGlb = await glbHelper.getGLBFileFromGltfZip();

                if (convertedGlb !== undefined) {
                    onUploadStatusChange(UploadStatusType.info, `Formating ${originalFile.name}`)
                    originalFile = convertedGlb;
                } else {
                    // TODO: show error wrong format
                    isWrongFileFormat = true;
                    onUploadStatusChange(UploadStatusType.warning, `Sorry! ${userFilename} is wrong format`)
                }
            } else if (supportedExt.includes(fileExtWithoutDot)) {
                onUploadStatusChange(UploadStatusType.info, `Uploading ${originalFile.name}`)
            } else {
                isWrongFileFormat = true;
                onUploadStatusChange(UploadStatusType.warning, `Sorry! ${userFilename} file type not supported`)
            }

            if (isWrongFileFormat) {
                onUploadStatusChange(UploadStatusType.warning, `Sorry! ${originalFile.name} is wrong format`)
                handleUploadError(originalFile.name, "wrong file format")
                continue
            }
            setEnableUpload(false)
            const newARContent = getDefaultArContent()

            if (fileExtWithoutDot === "jpg" || fileExtWithoutDot === "png" || fileExtWithoutDot === "jpeg") {
                newARContent.arContentType = ARContentType.Image;
                const imageMeta = await getImageMeta(originalFile)
                newARContent.width = imageMeta.width
                newARContent.height = imageMeta.height
            }
            if (fileExtWithoutDot === "mp4" || fileExtWithoutDot === "avi" || fileExtWithoutDot === "webm" || fileExtWithoutDot === "mov") {
                newARContent.arContentType = ARContentType.Video;
                const videoMeta = await getVideoMeta(originalFile)
                newARContent.width = videoMeta.width
                newARContent.height = videoMeta.height
            }
            if (isDetectionImage) {
                newARContent.arContentType = ARContentType.DetectionImage;
                newARContent.renderRadiusInMeter = 100;
            }
            if (arContentTypeOverride) {
                newARContent.arContentType = arContentTypeOverride;
            }
            if (project.lastOpenedAnchorType === ARAnchorType.ImageAnchor && !isDetectionImage) {
                newARContent.parentId = detectionImageId ? detectionImageId : "";
            }
            if (project.lastOpenedAnchorType === ARAnchorType.FaceAnchor) {
                newARContent.parentId = faceAnchorId || "";
            }

            newARContent.sizeInBytes = originalFile.size
            const fileId = addUploadItem(originalFile.name, newARContent.arContentType)

            newARContent.fileExtWithoutDot = fileExtWithoutDot
            newARContent.userFilename = userFilename

            if (project.lastOpenedAnchorType === ARAnchorType.FaceAnchor) {
                newARContent.scale = {
                    x: 0.5,
                    y: 0.5,
                    z: 0.5,
                }
            }

            if (newARContent.arContentType === ARContentType.Video) {
                if (originalFile.size > MAX_VIDEO_SIZE) {
                    onUploadStatusChange(UploadStatusType.warning, `File ${originalFile.name} is too large.`)
                    return
                }
            } else if (originalFile.size > MAX_FILE_SIZE) {
                onUploadStatusChange(UploadStatusType.warning, `File ${originalFile.name} is too large.`)
                return
            }
            let uploadSuccess = false
            if (newARContent.arContentType === ARContentType.Video) {
                uploadSuccess = await uploadVideoFile(
                    newARContent,
                    project,
                    originalFile,
                    fileId
                )
                shouldRefreshAfterUpload = true
            } else {
                uploadSuccess = await uploadToS3(
                    newARContent,
                    project,
                    fileExtWithoutDot,
                    originalFile,
                    fileId,
                )
            }

            if (!uploadSuccess) {
                onUploadStatusChange(UploadStatusType.error, "Upload failed. Please try again")
                setIsUploading(false)
                handleUploadError(fileId, "Upload failed, please try again later")
                setEnableUpload(true)
                continue
            }

            // Update to Database
            const createdId = await createOrUpdateARContent(newARContent);

            if (createdId.length === 0) {
                deleteOrphan(newARContent.arContentType, newARContent.storeUrl, newARContent.storeId)
                onUploadStatusChange(UploadStatusType.error, "Upload failed. Please try again")
                setIsUploading(false)
                handleUploadError(fileId, "Upload failed, please try again later")
                setEnableUpload(true)
                continue
            }
            newARContent._id = createdId
            if (newARContent.arContentType === ARContentType.Model3d) {
                const convertRes = await convertARContentFile(newARContent)
                newARContent.storeUrlIos = convertRes?.store_url_ios || ""
                newARContent.userFilenameIos = convertRes?.user_filename_ios || ""
                newARContent.iosDownloadUrl = convertRes?.ios_download_url || ""
            }

            handleUploadComplete(fileId)
            newObjects.push(newARContent);
            if (isDetectionImage) {
                onUploadSuccess(newObjects, false, project.lastOpenedAnchorType, newARContent.arContentId)
                resetUpload()
                return
            }
        }
        setIsUploading(false)
        setEnableUpload(true)
        onUploadSuccess(newObjects, shouldRefreshAfterUpload)
        if (arContentTypeOverride === ARContentType.ReferenceImage) resetUpload()
    };

    const uploadToS3 = async (
        newARContent: ARContent,
        project: Project,
        fileExtWithoutDot: string,
        originalFile: File,
        fileId: string,
    ): Promise<boolean> => {

        const getSignedURLPut = await getUrlPutARContent(project._id!, fileExtWithoutDot);
        const getSignedURLGet = await getUrlGetARContent(getSignedURLPut.storeUrl);

        if (
            getSignedURLPut.putUrl.length === 0 ||
            getSignedURLPut.storeUrl.length === 0 ||
            getSignedURLGet.length === 0
        ) {
            onUploadStatusChange(UploadStatusType.error, "Upload error occured, please try again later")
            handleUploadError(fileId, "Upload error occured, please try again later")
            return false
        }

        newARContent.storeUrl = getSignedURLPut.storeUrl
        newARContent.downloadUrl = getSignedURLGet

        // PUT to Object Storage
        setIsUploading(true)
        const uploadResponse = await putARContent(getSignedURLPut.putUrl, originalFile, handleUploadProgress(fileId));
        if (uploadResponse !== "success") {
            return false
        }
        return true
    }

    const uploadVideoFile = async (
        newARContent: ARContent,
        project: Project,
        originalFile: File,
        fileId: string,
    ): Promise<boolean> => {
        const uploadUrlRes = await getVideoUploadUrl(project._id!)
        if (
            uploadUrlRes.uid.length === 0 ||
            uploadUrlRes.uploadURL.length === 0
        ) {
            onUploadStatusChange(UploadStatusType.error, "Upload error occured, please try again later")
            handleUploadError(fileId, "Upload error occured, please try again later")
            return false
        }

        newARContent.storeId = uploadUrlRes.uid

        // PUT to Object Storage
        setIsUploading(true)
        const uploadResponse = await uploadVideo(uploadUrlRes.uploadURL, originalFile, handleUploadProgress(fileId));
        if (uploadResponse !== "success") {
            return false
        }
        return true
    }

    const createARContentFromExistingAsset = async (fileName: string, downloadUrl: string, downloadUrlIos: string) => {
        if (shouldBlockUpload) return
        let newObjects: ARContent[] = []

        const newARContent = getDefaultArContent()

        newARContent.isPublicStore = true
        newARContent.storeUrl = downloadUrl
        newARContent.downloadUrl = downloadUrl
        newARContent.storeUrlIos = downloadUrlIos
        newARContent.iosDownloadUrl = downloadUrlIos
        newARContent.arContentType = ARContentType.Model3d
        const fileId = addUploadItem(fileName, newARContent.arContentType)

        if (project.lastOpenedAnchorType === ARAnchorType.ImageAnchor) {
            newARContent.parentId = detectionImageId ? detectionImageId : "";
        }
        if (project.lastOpenedAnchorType === ARAnchorType.FaceAnchor) {
            newARContent.parentId = faceAnchorId || "";
        }

        newARContent.fileExtWithoutDot = "glb"
        newARContent.userFilename = fileName

        if (project.lastOpenedAnchorType === ARAnchorType.FaceAnchor) {
            newARContent.scale = {
                x: 0.5,
                y: 0.5,
                z: 0.5,
            }
        }

        setEnableUpload(false)
        newARContent._id = await createOrUpdateARContent(newARContent);
        setEnableUpload(true)

        newObjects.push(newARContent);
        handleUploadComplete(fileId)
        onUploadSuccess(newObjects, false)
    };

    const storeArContent = async (files: File[]) => {
        if (!arContent) {
            onUploadStatusChange(UploadStatusType.error, `Please select AR Content before editing upload`)
            return
        }
        let file = files[0]
        let fileExtWithoutDot = file.name.split('.').pop();
        if (fileExtWithoutDot === undefined) return
        let filename = file.name.split(fileExtWithoutDot)[0];
        let userFilename = filename.slice(0, filename.length - 1);
        if (arContent?.arContentType === ARContentType.Model3d) {
            if (fileExtWithoutDot === "gltf" || fileExtWithoutDot === "zip") {
                fileExtWithoutDot = "glb";
                let glbHelper = new GLTFHelper(userFilename, file);
                let convertedGlb = await glbHelper.getGLBFileFromGltfZip();

                if (convertedGlb !== undefined) {
                    onUploadStatusChange(UploadStatusType.info, `Formating ${file.name}`)
                    file = convertedGlb;
                } else {
                    onUploadStatusChange(UploadStatusType.warning, `Sorry! ${userFilename} is wrong format`)
                    return
                }
            } else if (fileExtWithoutDot === "glb") {
                onUploadStatusChange(UploadStatusType.info, `Uploading ${file.name}`)
            } else {
                onUploadStatusChange(UploadStatusType.warning, `Sorry! ${userFilename} file type not supported`)
                return
            }
        } else if (arContent?.arContentType == ARContentType.Image && (fileExtWithoutDot === "jpg" || fileExtWithoutDot === "png" || fileExtWithoutDot === "jpeg")) {
            onUploadStatusChange(UploadStatusType.info, `Uploading ${file.name}`)
        } else {
            onUploadStatusChange(UploadStatusType.warning, `Sorry! ${userFilename} file type not supported`)
            return
        }
        const fileId = addUploadItem(file.name, arContent.arContentType)
        setEnableUpload(false)
        const newARContent = { ...arContent }

        newARContent.sizeInBytes = file.size
        const getSignedURLPut = await getUrlPutARContent(arContent?.projectRefId!, fileExtWithoutDot);
        const getSignedURLGet = await getUrlGetARContent(getSignedURLPut.storeUrl);

        if (
            getSignedURLPut.putUrl.length === 0 ||
            getSignedURLPut.storeUrl.length === 0 ||
            getSignedURLGet.length === 0
        ) {
            onUploadStatusChange(UploadStatusType.error, "Upload error occured, please try again later")
            handleUploadError(fileId, "Upload error occured, please try again later")
            return
        }

        newARContent.storeUrl = getSignedURLPut.storeUrl
        newARContent.downloadUrl = getSignedURLGet

        newARContent.fileExtWithoutDot = fileExtWithoutDot
        newARContent.userFilename = userFilename

        // PUT to Object Storage
        setIsUploading(true)
        const uploadResponse = await putARContent(getSignedURLPut.putUrl, file, handleUploadProgress(fileId));

        if (uploadResponse !== "success") {
            onUploadStatusChange(UploadStatusType.error, "Upload failed. Please try again")
            setIsUploading(false)
            handleUploadError(fileId, "Upload failed, please try again later")
            setEnableUpload(true)
            return
        }

        // Update to Database
        const createdId = await createOrUpdateARContent(newARContent);

        if (createdId.length === 0) {
            deleteOrphan(newARContent.arContentType, newARContent.storeUrl, newARContent.storeId)
            onUploadStatusChange(UploadStatusType.error, "Upload failed. Please try again")
            setIsUploading(false)
            handleUploadError(fileId, "Upload failed, please try again later")
            setEnableUpload(true)
            return
        }
        newARContent._id = createdId
        if (newARContent.arContentType === ARContentType.Model3d) {
            const convertRes = await convertARContentFile(newARContent)
            newARContent.storeUrlIos = convertRes?.store_url_ios || ""
            newARContent.userFilenameIos = convertRes?.user_filename_ios || ""
            newARContent.iosDownloadUrl = convertRes?.ios_download_url || ""
        }

        handleUploadComplete(fileId)
        setIsUploading(false)
        setEnableUpload(true)
        onUploadSuccess([newARContent], false)
    }


    const storeIOSARContent = async (
        selectedFiles: File[] | FileList,
        maxFileSize = MAX_FILE_SIZE,
    ) => {
        const file = selectedFiles[0]
        const fileExtWithoutDot = file.name.split('.').pop();
        if (file.size > maxFileSize) {
            onUploadStatusChange(UploadStatusType.warning, `File ${file.name} is too large.`)
            return
        }
        if (fileExtWithoutDot !== "usdz") {
            onUploadStatusChange(UploadStatusType.warning, `Sorry! ${file.name} is not .usdz format`)
            return
        }
        if (!arContent) {
            onUploadStatusChange(UploadStatusType.warning, `Please upload image or .glb file first`)
            return
        }
        let getSignedURLPut = await getUrlPutARContent(arContent.projectRefId, fileExtWithoutDot);
        let getSignedURLGet = await getUrlGetARContent(getSignedURLPut.storeUrl);
        let filename = file.name.split(fileExtWithoutDot)[0];
        let fileNameWithoutDot = filename.slice(0, filename.length - 1);
        const fileId = addUploadItem(filename)

        const addIOSObject = { ...arContent };
        addIOSObject.userFilenameIos = fileNameWithoutDot;
        addIOSObject.storeUrlIos = getSignedURLPut.storeUrl;
        addIOSObject.iosDownloadUrl = getSignedURLGet

        // PUT to Object Storage
        setIsUploading(true)
        setEnableUpload(false)
        const uploadResponse = await putARContent(getSignedURLPut.putUrl, file, handleUploadProgress(fileId));
        if (uploadResponse === "failed") {
            alert("Upload Failed. Please try again.")
            setIsUploading(false)
            handleUploadError(fileId, "Upload error occured, please try again later")
            setEnableUpload(true)
            return
        }
        // Update to Database
        addIOSObject._id = await createOrUpdateARContent(addIOSObject);
        setEnableUpload(true)
        setIsUploading(false)
        handleUploadComplete(fileId)
        onUploadSuccess([addIOSObject], false)
        onUploadStatusChange(UploadStatusType.success, `Upload ${file.name} for iOS successfully!`)
    }

    return {
        enableUpload,
        isUploading,
        uploadProgress,
        createARContentFromImport,
        createARContentFromExistingAsset,
        storeArContent,
        storeIOSARContent,
        resetUpload,
    }
}

export { useUpload, getUploadMessage, getDefaultDropzoneOption, getDefaultIosDropzoneOption }
