import { useEffect, useState } from "react";
import {
    Row,
    Col,
    Label,
    Input,
    Button,
    Container,
    Card,
    CardBody,
} from "reactstrap";
import VerticalLayout from "../../components/VerticalLayout";
import Breadcrumb from "../../components/Common/Breadcrumb";
import { getApiToken } from "apis/user";
import "./developer.scss"
import { OrganizationType } from "store/organization/types";
import { PermissionType, UserPermission } from "store/permissions/types";
import { connect } from "react-redux";
import { ApplicationState } from "store/data";

interface StateProps {
    organization: OrganizationType;
    permissions: PermissionType[];
}

const DeveloperComponent = (props: StateProps) => {
    const [isAllowGenerateNewToken, setIsAllowGenerateNewToken] = useState(false);
    const [generatedToken, setGeneratedToken] = useState("");
    const permission = props.permissions.find(permission => permission.organizationId === props.organization._id)
    const isOwner = permission?.userPermission === UserPermission.Owner

    useEffect(() => {
        if (!isOwner) {
            window.location.replace('/');
            return
        }
    }, [])

    const onGenerateNewToken = async () => {
        setIsAllowGenerateNewToken(false);
        const apiToken = await getApiToken();
        setGeneratedToken(`Bearer ${apiToken}`);
    }

    return (
        <VerticalLayout>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumb title="Console" breadcrumbItem="Developer Options" />
                    <Card>
                        <CardBody>
                            <Row>
                                <Col xl={10} lg={9}>
                                    <Label>External API Access Token</Label>
                                    <p className="text-muted m-b-15">
                                        <code>The token will be shown only once after it is created for security reason.</code> Please save your token in a secured place.<br />
                                        You need an API access token to use Graffity Console API <a href="https://developers.graffity.tech/" rel="noreferrer" target="_blank">our documentation</a>.<br />
                                        Note that existing token will be invalidated as soon as you create a new token.
                                    </p>
                                </Col>
                                <Col xl={2} lg={3}>
                                    <Row>
                                        <div className="form-switch form-switch-lg mt-4 float-end tokenEnableContainer">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="isAllowGenerateNewToken"
                                                defaultChecked={isAllowGenerateNewToken}
                                                onChange={(e) => setIsAllowGenerateNewToken(e.target.checked)}
                                            />
                                        </div>
                                        <div className="ps-0 mt-2 tokenEnableContainer">
                                            Allow Gen New Token
                                        </div>

                                    </Row>

                                </Col>
                            </Row>
                            <Input
                                id="token-name"
                                type="text"
                                className="form-control"
                                readOnly
                                value={generatedToken}
                            />
                            {generatedToken ?
                                <Button
                                    color="success"
                                    outline
                                    className="mt-2"
                                    style={{ width: "100%" }}
                                    onClick={() => {
                                        navigator.clipboard.writeText(generatedToken!);
                                    }}
                                >
                                    Copy token
                                </Button>
                                :
                                <Button
                                    color="success"
                                    // outline
                                    className="btn btn-success mt-2"
                                    style={{ width: "100%" }}
                                    onClick={onGenerateNewToken}
                                    disabled={!isAllowGenerateNewToken}
                                >
                                    Generate new token
                                </Button>
                            }
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </VerticalLayout>
    );
};

const mapStateToProps = (state: ApplicationState): StateProps => {
    return {
        organization: state.Organization,
        permissions: state.Permissions,
    }
}

const Developer = connect(mapStateToProps)(DeveloperComponent)

export { Developer }
