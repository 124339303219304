import { useEffect, useRef, useState } from "react"
import mapboxgl from "!mapbox-gl";
import React from "react";
import { RequestLog } from "../Dashboard/data";
import { Map, MapRef, Source, Layer, HeatmapLayer, RasterLayer, ImageSource } from "react-map-gl";

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN!

interface HeatmapProps {
  rawLogsData: RequestLog[];
}

const MAX_ZOOM_LEVEL = 24;
const floorPlanCoordinates: number[][] = [
  [100.60551189131576, 13.669546556517911],
  [100.60602151101818, 13.670883551295525],
  [100.60720168296065, 13.670675053584013],
  [100.60676716510913, 13.669257264251668],
]

const Heatmap = ({ rawLogsData }: HeatmapProps) => {
  const mapRef = useRef<MapRef>(null);
  const [geojsonData, setGeoJsonData] = useState<GeoJSON.FeatureCollection>();

  const prepGeoJsonData = () => {
    let geoJsonColl: GeoJSON.FeatureCollection = {
      type: "FeatureCollection",
      features: []
    }
    rawLogsData.forEach(e => {
      if (e.positionGps.coordinates[0]) {
        let data: GeoJSON.Feature = {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [e.positionGps.coordinates[0], e.positionGps.coordinates[1]],
          },
          properties: {
            id: e._id,
          },
        };
        geoJsonColl.features.push(data);
      }
    });
    // console.log("geojson", geojson)
    setGeoJsonData(geoJsonColl);
  }

  const heatmapLayer: HeatmapLayer = {
    id: 'heatmap',
    maxzoom: MAX_ZOOM_LEVEL,
    type: 'heatmap',
    paint: {
      // Increase the heatmap weight based on frequency and property magnitude
      // 'heatmap-weight': ['interpolate', ['linear'], ['get', 'mag'], 0, 0, 6, 1],
      // Increase the heatmap color weight by zoom level
      // heatmap-intensity is a multiplier on top of heatmap-weight
      // 'heatmap-intensity': ['interpolate', ['linear'], ['zoom'], 0, 1, MAX_ZOOM_LEVEL, 3],
      // 'heatmap-weight': {
      //   'property': 'id',
      //   'type': 'exponential',
      //   'stops': [
      //     [1, 0],
      //     [62, 1]
      //   ]
      // },
      'heatmap-weight': ['interpolate', ['linear'], ['get', 'mag'], 0, 0, 6, 1],
      // increase intensity as zoom level increases
      // 'heatmap-intensity': {
      //   'stops': [
      //     [11, 0.5],
      //     [15, 2]
      //   ]
      // },
      'heatmap-intensity': ['interpolate', ['linear'], ['zoom'], 0, 1, MAX_ZOOM_LEVEL, 3],
      // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
      // Begin color ramp at 0-stop with a 0-transparancy color
      // to create a blur-like effect.
      'heatmap-color': [
        'interpolate',
        ['linear'],
        ['heatmap-density'],
        0,
        'rgba(33,102,172,0)',
        0.2,
        'rgb(103,169,207)',
        0.4,
        'rgb(209,229,240)',
        0.6,
        'rgb(253,219,199)',
        0.8,
        'rgb(239,138,98)',
        0.9,
        'rgb(255,201,101)'
      ],
      // Adjust the heatmap radius by zoom level
      'heatmap-radius': ['interpolate', ['linear'], ['zoom'], 0, 2, MAX_ZOOM_LEVEL, 20],
      // Transition from heatmap to circle layer by zoom level
      // 'heatmap-opacity': [
      //   'interpolate', ['linear'], ['zoom'],
      //   // https://stackoverflow.com/questions/73150892/what-do-the-4-numbers-mean-in-mapboxs-heatmap-specification
      //   7, 1,
      //   15, 0.75
      // ]
      'heatmap-opacity': [
        'interpolate',
        ['linear'],
        ['zoom'],
        7, 1, 9, 0
      ]
    }
  };

  const floorPlanLayer: RasterLayer = {
    id: 'floor-plan-layer',
    type: 'raster',
    source: 'radar',
    paint: {
      'raster-fade-duration': 0
    }
  }

  useEffect(() => {
    if (rawLogsData) {
      prepGeoJsonData();
    }
  }, [rawLogsData])

  return (
    <React.Fragment>
      <Map
        initialViewState={{
          longitude: 100.5259941, // Bangkok
          latitude: 13.7339379,
          // longitude: 100.53584571685725, // Silom Edge
          // latitude: 13.72972891063123,
          // longitude: 100.60605461674673, // Bhiraj
          // latitude: 13.669952107078823,
          zoom: 5
        }}
        style={{ height: 500 }}
        mapStyle="mapbox://styles/mapbox/light-v11"
        mapboxAccessToken={MAPBOX_TOKEN}
        ref={mapRef}
      // onZoomEnd={(e) => { console.log(e.viewState.zoom) }}
      >
        {/* <Source
          type="image"
          url="https://graffity-public-assets.s3.ap-southeast-1.amazonaws.com/bhiraj-fl-plan-min.png"
          coordinates={floorPlanCoordinates}
        >
          <Layer {...floorPlanLayer} />
        </Source> */}

        <Source
          id="logs-data"
          type="geojson"
          data={geojsonData}
        >
          <Layer {...heatmapLayer} />
        </Source>
      </Map>
    </React.Fragment >
  )
}

export default Heatmap
