import React, { useState } from "react"
import * as THREE from 'three'
import { Suspense } from 'react'
import { Canvas } from '@react-three/fiber'
import { ARAnchorType, ARContent, ARContentType } from "../../data"
import { OrbitControls } from '@react-three/drei'
import { connect } from "react-redux"
import { Dispatch } from 'redux'
import { changeSelectedArContent, updateError } from 'store/editor/actions'
import { useSearchParams } from "react-router-dom"
import { ControlMode, Controls } from "../common/TransformControl/TransformControl"
import { ArContentModel } from "../common/ArContentModel/ArContentModel"
import { ApplicationState } from "store/data"
import { EditorState } from "store/editor/types"
import { UsageTip } from "../common/UsageTip/UsageTip"
import { EditorError, EditorErrorType } from "pages/Projects/types"

const normalAnchorScale = 1;


interface OwnProps {
    arContents: ARContent[]
    anchorType: ARAnchorType;
    onArContentUpdate: (index: number, object: ARContent) => void
    onArContentStateUpdate: (index: number, object: ARContent) => void
    controlMode: ControlMode
}

interface DispatchProps {
    dispatch: Dispatch
}

interface StateProps {
    editorState: EditorState
}


type ObjectAnchorEditorProps = OwnProps & StateProps & DispatchProps

const HorizontalGrid = () => {
    const grid = new THREE.Group();
    const grid1: any = new THREE.GridHelper(1, 10, 0x888888);
    grid1.material.color.setHex(0x888888);
    grid1.material.vertexColors = false;
    grid.add(grid1);
    const grid2: any = new THREE.GridHelper(1, 2, 0x222222);
    grid2.material.color.setHex(0x222222);
    grid2.material.vertexColors = false;
    grid.add(grid2);
    return (<primitive object={grid} />);
}

const VerticalGrid = () => {
    const grid = new THREE.Group();
    const grid1: any = new THREE.GridHelper(1, 10, 0x888888);
    grid1.material.color.setHex(0x888888);
    grid1.material.vertexColors = false;
    grid.add(grid1);
    const grid2: any = new THREE.GridHelper(1, 2, 0x222222);
    grid2.material.color.setHex(0x222222);
    grid2.material.vertexColors = false;
    grid.add(grid2);
    grid.rotateX(Math.PI / 2)
    return (<primitive object={grid} />);
}

const filterARContent = (arContents: ARContent[], anchorType: ARAnchorType, parentItemId: string) => {
    return arContents.filter((arContent) => {
        if (arContent.arAnchorType !== anchorType) {
            return false
        } else if (anchorType === ARAnchorType.FaceAnchor) {
            return (arContent.arContentType === ARContentType.FaceParent && arContent.arContentId === parentItemId)
                || (arContent.parentId === parentItemId)
        } else if (anchorType === ARAnchorType.ImageAnchor) {
            return (arContent.arContentType === ARContentType.DetectionImage && arContent.arContentId === parentItemId)
                || (arContent.parentId === parentItemId)
        }
        return false
    })
}

const ObjectAnchorEditorComponent = ({ arContents, dispatch, anchorType, onArContentUpdate, onArContentStateUpdate, editorState, controlMode }: ObjectAnchorEditorProps) => {
    const [urlParams] = useSearchParams();
    const [enableClick, setEnableClick] = useState(true);
    const [errContentList, setErrContentList] = useState<Set<String>>(new Set<string>())
    const faceParentId = urlParams.get('face_anchor_id')
    const detectionImageId = urlParams.get('detection_image_id');
    const parentItemId = anchorType === ARAnchorType.FaceAnchor ? faceParentId : detectionImageId
    const scale = normalAnchorScale;

    const onObjectClick = (arContentId: string) => {
        if (!enableClick) return
        dispatch(changeSelectedArContent(arContentId))
    }

    const onObjectError = (arContentId: string, message: string) => {
        if (errContentList.has(arContentId) || editorState.errors.find(error => error.objectid === arContentId)) return
        const newError: EditorError = {
            type: EditorErrorType.ArContentError,
            objectid: arContentId,
            message,
        }
        const newErrorList = new Set(errContentList).add(arContentId)
        setErrContentList(newErrorList)
        const newErrorsState = [
            ...editorState.errors,
            newError,
        ]
        dispatch(updateError(newErrorsState))
    }

    const handleArContentUpdate = (object: THREE.Object3D, shouldUpdateServer: boolean) => {
        const arContentIndex = arContents.findIndex((arContent) => arContent._id === object.name)
        if (arContentIndex === -1) return
        const updatedArContent = { ...arContents[arContentIndex] }
        updatedArContent.position = {
            x: Math.round(100 * object.position.x) / 100,
            y: Math.round(100 * object.position.y) / 100,
            z: Math.round(100 * object.position.z) / 100,
        }
        updatedArContent.rotation = {
            x: Math.round(100 * object.rotation.x * 180 / Math.PI) / 100,
            y: Math.round(100 * object.rotation.y * 180 / Math.PI) / 100,
            z: Math.round(100 * object.rotation.z * 180 / Math.PI) / 100,
        }
        updatedArContent.scale = {
            x: Math.round(100 * object.scale.x / scale) / 100,
            y: Math.round(100 * object.scale.y / scale) / 100,
            z: Math.round(100 * object.scale.z / scale) / 100,
        }
        if (shouldUpdateServer) {
            onArContentUpdate(arContentIndex, updatedArContent)
            return
        }
        onArContentStateUpdate(arContentIndex, updatedArContent)
    }

    const disableOnClick = () => setEnableClick(false);
    const enableOnClick = () => setEnableClick(true);

    const arContentsToDisplay = filterARContent(arContents, anchorType, parentItemId || '')

    return (
        <>
            <UsageTip />
            <Canvas
                camera={{ fov: 75, near: 0.01, far: 1000, position: [0.5, 0.5, 0.5] }}
                style={{ borderRadius: "4px" }}
                onPointerMissed={() => {
                    onObjectClick('')
                }}
                gl={{ antialias: true, toneMapping: THREE.NoToneMapping }}
            >
                <color attach="background" args={['#ffffff']} />
                <ambientLight intensity={1.5} />
                <directionalLight intensity={2} color="#ffffff" position={new THREE.Vector3(0, 1, 0.5)} />
                <OrbitControls
                    makeDefault
                    enableDamping={false}
                    onEnd={() => enableOnClick()}
                    onChange={() => disableOnClick()}
                />
                <Suspense fallback={null}>
                    {
                        anchorType === ARAnchorType.FaceAnchor
                            ? <VerticalGrid />
                            : <HorizontalGrid />
                    }
                    {
                        arContentsToDisplay.map((arContent) => <ArContentModel
                            arContent={arContent}
                            onObjectClick={onObjectClick}
                            onError={onObjectError}
                            scale={arContent.arContentType === ARContentType.DetectionImage ? 0.1 : scale}
                        />)
                    }
                </Suspense>
                <Controls
                    selectedObjectId={editorState.selectedObjects[0]?.id || ""}
                    controlMode={controlMode}
                    onArContentChange={handleArContentUpdate}
                    onMouseDown={() => disableOnClick()}
                    onMouseUp={() => enableOnClick()}
                />
            </Canvas>
        </>
    )
}

ObjectAnchorEditorComponent.displayName = "ObjectAnchorEditor"

const mapStateToProps = (state: ApplicationState): StateProps => ({
    editorState: state.Editor,
})

const ObjectAnchorEditor = connect(mapStateToProps)(ObjectAnchorEditorComponent);
export { ObjectAnchorEditor, filterARContent };