import React, { useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { acceptPermission } from "apis/permission";
import { PageMeta } from "components/Common/Header";

const InvitePage = () => {
  const [urlParams] = useSearchParams();
  const inviteToken = urlParams.get('token');
  const navigate = useNavigate();

  useEffect(() => {
    if (inviteToken !== null) {
      acceptPermission(inviteToken).then((status) => {
        if (status === 200) {
          navigate("/")
        }
      })
    }
  }, [inviteToken, navigate])

  return (
    <React.Fragment>
      <PageMeta title="Invite | Graffity Console"/>
    </React.Fragment>
  )
}

export default InvitePage
