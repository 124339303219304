import React, { useCallback, useEffect, useState } from "react";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Modal, Button, Container, Row, Input, CardBody, Card, ModalHeader, ModalBody, ModalFooter, Badge } from "reactstrap";
import { QRCodeSVG } from "qrcode.react"

import { createOrUpdateProject, publishProject } from "../../../apis/project";
import { generateV2AccessToken } from "apis/accessToken"
import { isV2Token } from "constants/const"
import logoDark from "../../../assets/images/logo-dark.png";
import ProjectSettingModal from "../Settings/ProjectSetting";
import { ARAnchorType, ARContent, ARContentType, Location2D, Project, SdkMode, VersionType } from "../data";
import { anchorTypes } from "../CreateProjectModal";
import { filterARContentAndARAnchorType, getContentFromARContentId } from "./utils";

const basePreviewLink = "https://viewer.graffity.app/"
interface ProjectEditorHeaderProps {
  project: Project
  arContents: ARContent[]
  onPublishProject: () => void
}

export const PublishModal = ({ project, onPublishProject }: {
  project: Project
  onPublishProject: () => void
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const isPublishedProject = project.version === VersionType.Published

  const handlePublishProject = () => {
    setIsOpen(false)
    onPublishProject()
  }

  const toggleModal = () => {
    setIsOpen(!isOpen)
  }

  return <>
    <Modal
      isOpen={isOpen}
      toggle={toggleModal}
      data-testid="publish-modal"
    >
      <ModalHeader toggle={toggleModal} data-testid="publish-modal-header">Are you sure?</ModalHeader>
      <ModalBody>
        This action will {isPublishedProject ? "republish" : "publish"} your project and it can be viewed by all user.
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={handlePublishProject}
          data-testid="modal-publish-button"
        >
          Publish Project
        </Button>
        <Button
          onClick={toggleModal}
          data-testid="modal-cancel-button"
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
    <Button className="me-3" color="light" onClick={toggleModal} data-testid="publish-button">
      <i className="bi bi-rocket-takeoff-fill fs-6 me-2" />
      {isPublishedProject ? "Published" : "Publish"}
    </Button>
  </>
}

const AnchorSelector = (props: {
  disabled: boolean,
  isOpen: boolean,
  onDropdownToggle: () => void,
  title: string,
  arContents: ARContent[],
  onContentClick: (arContentId: string) => void,
  onAddContent: () => void,
  anchorType: ARAnchorType,
}
) => {
  return (
    <Dropdown
      isOpen={props.isOpen}
      toggle={props.onDropdownToggle}
      className="d-inline-block"
      id="ar-anchor-selector"
    >
      <DropdownToggle
        className="btn header-item"
        tag="button"
        disabled={props.disabled}
        style={{ color: "#fff" }}
      >
        <i className='bi bi-window-plus font-size-24 align-middle me-1 text-primary'></i>
        <span className="d-none d-xl-inline-block ms-2 me-1" >
          {props.title}
        </span>
        <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-end">
        {props.arContents.map((arContent, i) => {
          return (
            <div key={`detection-image-${i}`}>
              <Button
                className="dropdown-item"
                onClick={() => props.onContentClick(arContent.arContentId)}
              >
                <span className="text-secondary"> {arContent.userFilename}</span>
              </Button>
            </div>
          );
        })}
        <div className="dropdown-divider" />
        <Button
          className="dropdown-item"
          onClick={props.onAddContent}
        >
          <span className="text-secondary font-size-12"> {props.anchorType === ARAnchorType.ImageAnchor
            ? "Add Image Anchor"
            : "Add Face Anchor"
          }</span>
        </Button>

      </DropdownMenu>
    </Dropdown>
  )
}

const ProjectEditorHeader = ({ project, arContents, onPublishProject }: ProjectEditorHeaderProps) => {
  const navigate = useNavigate();
  const [urlParams] = useSearchParams();
  const anchorType = urlParams.get('anchor_type');
  const detectionImageId = urlParams.get('detection_image_id');
  const faceAnchorId = urlParams.get('face_anchor_id')
  const pointCloudId = urlParams.get('point_cloud_id');
  // debugger;
  const detectionImages = filterARContentAndARAnchorType(arContents, ARAnchorType.ImageAnchor, ARContentType.DetectionImage);
  const faceAnchors = filterARContentAndARAnchorType(arContents, ARAnchorType.FaceAnchor, ARContentType.FaceParent);
  const [openProjectSettingModel, setOpenProjectSettingModel] = useState(false);
  const [isOpenAnchorTypeMenu, setIsOpenAnchorTypeMenu] = useState(false);
  const [isOpenAnchorMenu, setIsOpenAnchorMenu] = useState(false);
  const [isOpenPreviewLocationMenu, setIsOpenPreviewLocationMenu] = useState(false);
  const [isOpenPreviewMenu, setIsOpenPreviewMenu] = useState(false);
  const [pickedAnchor, setPickedAnchor] = useState<any>();
  const [generatedExpiringLink, setGeneratedExpiringLink] = useState(basePreviewLink)
  const [webSdkLink, setWebSDKLink] = useState("https://web-sdk.graffity.services/")
  const pickedDetectionImage = detectionImageId !== null && getContentFromARContentId(detectionImages, detectionImageId);
  const pickedFaceAnchor = faceAnchorId !== null && getContentFromARContentId(faceAnchors, faceAnchorId);
  // const [pickedPreviewLocation, setPickedPreviewLocation] = useState<PreviewLocation | null>(getDefaultPreviewLocation(project.previewLocations));

  const toggleFullscreen = () => {
    if (
      !document.fullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      }
    } else {
      if (document.fullscreenEnabled) {
        document.exitFullscreen();
      }
    }
  }

  const handleOpenPreviewMenu = () => {
    if (!isOpenPreviewMenu) {
      fetchSdkPreviewLink()
    }
    setIsOpenPreviewMenu(!isOpenPreviewMenu)
  }

  const getSdkMode = () => {
    if (pointCloudId) return SdkMode.PointCloudAnchor
    if (faceAnchorId) return SdkMode.FaceAnchor
    return SdkMode.WorldAnchor
  }

  const fetchSdkPreviewLink = () => {
    generateV2AccessToken(project._id || '', false).then((token) => {
      const sdkMode = getSdkMode()
      const queryParam = `mode=${sdkMode}`
      setWebSDKLink(`https://web-sdk.graffity.services/?token=${token}`)
      setGeneratedExpiringLink(`${basePreviewLink}ar/${token}`) // ?${queryParam}
    })
  }

  const setLastOpenedAnchorType = async (anchor: ARAnchorType) => {
    let updateProject = project;
    updateProject.lastOpenedAnchorType = anchor;
    await createOrUpdateProject(updateProject);
    navigate(`?anchor_type=${anchor}`);
    window.location.reload();
  }

  const changePreviewLocation = async (location: Location2D) => {
    navigate(`?anchor_type=${anchorType}&center=${location.coordinates[0]},${location.coordinates[1]}`);
    window.location.reload();
  }

  const onChangeDetectionImage = useCallback((arContentId: string) => {
    navigate(`?anchor_type=${anchorType}&detection_image_id=${arContentId}`);
  }, [anchorType, navigate])

  const onChangeFaceAnchor = useCallback((arContentId: string) => {
    navigate(`?anchor_type=${anchorType}&face_anchor_id=${arContentId}`);
  }, [anchorType, navigate])

  const onAddDetectionImage = () => {
    navigate(`?anchor_type=${anchorType}&detection_image_id=`);
  }

  const onAddFaceAnchor = () => {
    navigate(`?anchor_type=${anchorType}&face_anchor_id=`);
  }

  useEffect(() => {
    if (anchorType === ARAnchorType.ImageAnchor && detectionImageId === null) {
      const firstDetectionImage = arContents.find((arContent) => arContent.arContentType === ARContentType.DetectionImage)
      if (firstDetectionImage) {
        onChangeDetectionImage(firstDetectionImage.arContentId)
      }
    } else if (anchorType === ARAnchorType.FaceAnchor && faceAnchorId === null) {
      const firstFaceParent = arContents.find((arContent) => arContent.arContentType === ARContentType.FaceParent)
      if (firstFaceParent) {
        onChangeFaceAnchor(firstFaceParent.arContentId)
      }
    }
  }, [anchorType, arContents, detectionImageId, faceAnchorId, onChangeDetectionImage, onChangeFaceAnchor])

  useEffect(() => {
    const findAnchor = anchorTypes.find(e => e.type === project.lastOpenedAnchorType);
    if (findAnchor) {
      setPickedAnchor(findAnchor);
    }
  }, [detectionImageId, detectionImages, project.lastOpenedAnchorType]);

  return (
    <div id="project-editor-header">
      <ProjectSettingModal
        project={project}
        isOpenModal={openProjectSettingModel}
        toggleModal={() => setOpenProjectSettingModel(!openProjectSettingModel)}
      />

      <header id="project-editor-header">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/" className="logo logo-dark">
                <span className="logo-lg">
                  <img src={logoDark} alt="" height="36" />
                </span>
              </Link>
            </div>

            <div className="vertical-line"></div>

            <h5 className="project-name">{project.name}</h5>

            <button
              type="button"
              onClick={() => setOpenProjectSettingModel(true)}
              className="btn header-item"
              data-toggle="fullscreen"
            >
              <i className="bi bi-gear large-icon" />
            </button>
          </div>

          <div className="d-flex">
            <PublishModal
              project={project}
              onPublishProject={onPublishProject}
            />
            {
              isV2Token &&
              <Dropdown
                isOpen={isOpenPreviewMenu}
                toggle={handleOpenPreviewMenu}
                className="d-inline-block"
                id="preview-location-selector"
              >
                <DropdownToggle
                  className="btn header-item"
                  tag="button"
                  style={{ color: "#fff" }}
                >
                  <i className={`bi bi-play-fill font-size-20 align-middle me-1 text-primary`} />
                  <span className="d-none d-xl-inline-block me-1 align-middle" >{"Preview"}</span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  <Card>
                    <CardBody>
                      <Container className="preview-menu-container" fluid>
                        <Row className="preview-menu-item">
                          <QRCodeSVG
                            value={generatedExpiringLink}
                            size={220}
                          />
                        </Row>
                        <Row className="g-0">
                          <Input
                            readOnly
                            type="text"
                            value={generatedExpiringLink}
                            className="preview-menu-item"
                          />
                          <p className="preview-menu-item">
                            This link will be valid for 24 hours
                          </p>
                          <Button
                            color="primary"
                            onClick={() => {
                              navigator.clipboard.writeText(generatedExpiringLink);
                            }}
                            className="mb-1"
                          >
                            Copy Link
                          </Button>
                          <Button
                            color="light"
                            onClick={() => {
                              navigator.clipboard.writeText(webSdkLink);
                            }}
                          >
                            Copy Web SDK Link <Badge color="success">New</Badge>
                          </Button>
                          <p className="preview-menu-item">
                            Only android device is currently supported on Web SDK
                          </p>
                        </Row>
                      </Container>
                    </CardBody>
                  </Card>
                </DropdownMenu>
              </Dropdown>
            }
            {/* Detection Image Selector */}
            {(project.lastOpenedAnchorType === ARAnchorType.ImageAnchor) &&
              <AnchorSelector
                disabled={detectionImages.length === 0}
                isOpen={isOpenAnchorMenu}
                onDropdownToggle={() => setIsOpenAnchorMenu(!isOpenAnchorMenu)}
                title={!pickedDetectionImage ? "New Image" : pickedDetectionImage.userFilename}
                arContents={detectionImages}
                onContentClick={onChangeDetectionImage}
                onAddContent={onAddDetectionImage}
                anchorType={ARAnchorType.ImageAnchor}
              />
            }

            {/* Face Selector */}
            {(project.lastOpenedAnchorType === ARAnchorType.FaceAnchor) &&
              <AnchorSelector
                disabled={faceAnchors.length === 0}
                isOpen={isOpenAnchorMenu}
                onDropdownToggle={() => setIsOpenAnchorMenu(!isOpenAnchorMenu)}
                title={!pickedFaceAnchor ? "New Face" : pickedFaceAnchor.userFilename}
                arContents={faceAnchors}
                onContentClick={onChangeFaceAnchor}
                onAddContent={onAddFaceAnchor}
                anchorType={ARAnchorType.FaceAnchor}
              />
            }

            {/* Preview Locations Selector */}
            {(project.lastOpenedAnchorType === ARAnchorType.WorldAnchor) &&
              <Dropdown
                isOpen={isOpenPreviewLocationMenu}
                toggle={() => setIsOpenPreviewLocationMenu(!isOpenPreviewLocationMenu)}
                className="d-inline-block"
                id="preview-location-selector"
              >
                <DropdownToggle
                  className="btn header-item"
                  tag="button"
                  style={{ color: "#fff" }}
                >
                  <i className={`bi bi-arrows-move font-size-20 align-middle me-1 text-primary`} />
                  <span className="d-none d-xl-inline-block align-middle me-1 ms-1" >{"Change Center"}</span>
                  <i className="mdi mdi-chevron-down d-none align-middle d-xl-inline-block" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  {/* <div className="dropdown-divider"/> */}
                  {project.previewLocations.map((loc, i) => {
                    return (
                      <div key={`anchor-se-${i}`}>
                        <Button
                          className="dropdown-item"
                          onClick={() => changePreviewLocation(loc.location2D)}
                        >
                          {/* <i className={`bx ${anchor.img} font-size-16 align-middle me-1 text-primary`} /> */}
                          <span className="text-secondary"> {loc.name}</span>
                        </Button>
                      </div>
                    );
                  })}
                  <Button disabled className="dropdown-item">
                    <span className="text-secondary">Add in settings</span>
                  </Button>
                </DropdownMenu>
              </Dropdown>
            }

            {/* Anchor Type Selector */}
            {pickedAnchor &&
              <Dropdown
                isOpen={isOpenAnchorTypeMenu}
                toggle={() => setIsOpenAnchorTypeMenu(!isOpenAnchorTypeMenu)}
                className="d-inline-block"
                id="anchor-selector"
              >
                <DropdownToggle
                  className="btn header-item"
                  tag="button"
                  style={{ color: "#fff" }}
                >
                  <i className={`bx ${pickedAnchor["img"]} font-size-20 align-middle me-1 text-primary`} />
                  <span className="d-none d-xl-inline-block align-middle me-1 ms-1" >{pickedAnchor["anchorName"]}</span>
                  <i className="mdi mdi-chevron-down d-none align-middle d-xl-inline-block" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  {/* <div className="dropdown-divider"/> */}
                  {anchorTypes.map((anchor, i) => {
                    return (
                      <div key={`anchor-se-${i}`}>
                        <Button
                          className="dropdown-item"
                          disabled={anchor.disabled || (anchor === pickedAnchor)}
                          onClick={() => setLastOpenedAnchorType(anchor.type)}
                        >
                          <i className={`bx ${anchor.img} font-size-16 align-middle me-1 text-primary`} />
                          <span className="text-secondary"> {anchor.anchorName}</span>
                        </Button>
                      </div>
                    );
                  })}
                </DropdownMenu>

              </Dropdown>
            }

            {/* Toggle Full Screen */}
            <button
              type="button"
              onClick={() => {
                toggleFullscreen();
              }}
              className="btn header-item"
              data-toggle="fullscreen"
            >
              <i className="bi bi-fullscreen large-icon" />
            </button>

          </div>
        </div>
      </header>
    </div>
  );
};

export default ProjectEditorHeader;
