import { connect, useDispatch } from "react-redux"
import { Input } from "reactstrap"
import { ApplicationState } from "store/data"
import { OrganizationType } from "store/organization/types"
import { PermissionType } from "store/permissions/types"
import { changeOrganizationData } from "store/utils"

import "./organizationSelect.scss"

interface StateProps {
    organization: OrganizationType
    permissions: PermissionType[]
}

const OrganizationSelectComponent = (props: StateProps) => {
    const dispatch = useDispatch()
    return <div className="organizationSelect">
        <Input
            type="select"
            onChange={(e) => {
                changeOrganizationData(e.target.value, dispatch)
            }}
            value={props.organization._id}
        >
            {props.permissions.map(permission => 
                <option value={permission.organizationId}>
                    {permission.orgDisplayName}
                </option>
            )}
        </Input>
    </div>
}

const mapStateToProps = (state: ApplicationState): StateProps => {
    return {
        organization: state.Organization,
        permissions: state.Permissions,
    }
}

const OrganizationSelect = connect(mapStateToProps)(OrganizationSelectComponent)

export { OrganizationSelect }