import React, { useEffect, useRef, useState } from "react";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from 'mapbox-gl';
import { Label, Col, Input, Button, Row } from "reactstrap";
import { Location2D, PreviewLocation } from "../data";
import { SettingProps } from "./ProjectSetting";
import { createOrUpdateProject } from "../../../apis/project";
import { Box } from "components/Common/Layout/Layout";

// import "../Projects.css";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN!
const bangkokCoor = [100.501227, 13.757226]
const maxPreviewLocations = 50

const PreviewLocationSetting = ({
    project,
}: SettingProps) => {

    const [previewLocations, setPreviewLocations] = useState<PreviewLocation[]>();

    const mapContainer = useRef<HTMLDivElement>(null);
    const geocoderContainer = useRef<HTMLDivElement>(null);
    const [map, setMap] = useState<mapboxgl.Map>();
    const [marker, setMarker] = useState<mapboxgl.Marker>();
    const [geocoder, setGeocoder] = useState<MapboxGeocoder>();
    const [locationName, setLocationName] = useState<string>();
    const [locationCenter, setLocationCenter] = useState<Location2D>();

    const initMap = () => {
        const attachMap = (setMap: React.Dispatch<React.SetStateAction<any>>, mapContainer: React.RefObject<HTMLDivElement>) => {
            const mapInit = new mapboxgl.Map({
                container: mapContainer.current || '', // NO ERROR
                center: [bangkokCoor[0], bangkokCoor[1]],
                zoom: 10,
                // pitch: 60, // VIEW ORIENTATION
                customAttribution: "© Graffity Technologies Co., Ltd.",
                // doubleClickZoom: false,
                // interactive: false
            });
            // mapInit.addControl(new mapboxgl.FullscreenControl());
            const marker = new mapboxgl.Marker({
                draggable: true
            })
                .setLngLat([bangkokCoor[0], bangkokCoor[1]])
                .addTo(mapInit);

            setMarker(marker);
            setMap(mapInit);
        }

        !map && attachMap(setMap, mapContainer);
    }

    const addMapSearchBox = () => {
        if (!map) return;
        // const marker = new mapboxgl.Marker({
        //     color: "#1aa6b7"
        // });
        const geocoder = new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            mapboxgl: mapboxgl,
            // countries: 'th',
            // marker: marker,
            placeholder: "Search to pin",
            // marker: true,
        });
        // map.addControl(geocoder);
        geocoderContainer.current?.appendChild(geocoder.onAdd(map));
        setGeocoder(geocoder);

        map.on('click', (e) => {
            const coordinates = e.lngLat;
            marker?.setLngLat([coordinates.lng, coordinates.lat]);
            setLocationName("Custom Point");
            setLocationCenter({
                type: "Point",
                coordinates: [coordinates.lng, coordinates.lat],
            });
            geocoder?.clear();
        });
    }

    const onSearchBoxResult = () => {
        if (!geocoder || !marker) return;
        geocoder.on('result', function (result) {
            // console.log(result);
            marker.setLngLat(result["result"]["center"]);

            setLocationName(result["result"]["place_name_en-US"].split(", ")[0]);
            setLocationCenter({
                type: "Point",
                coordinates: result["result"]["center"],
            });
            // console.log(result["result"]["place_name_en-US"].split(", ")[0], result["result"]["center"]);
        });
    }

    const onMarkerDragEnd = () => {
        if (!marker) return;
        marker.on('dragend', () => {
            const lngLat = marker.getLngLat();
            // console.log("lngLat", lngLat)
            setLocationName("Custom Point");
            setLocationCenter({
                type: "Point",
                coordinates: [lngLat.lng, lngLat.lat],
            });
        });
    }

    const onSaveNewLocations = () => {
        if (previewLocations && locationName && locationCenter) {
            let newLocation: PreviewLocation = {
                name: locationName,
                description: "",
                location2D: locationCenter,
                altitude: 0,
                isDefault: false,
            }

            setPreviewLocations(previewLocations => [...previewLocations!, newLocation]);
        }
    }

    useEffect(() => {
        initMap();

        // patch rm empty location due to MongoDB gen empty object to fill [10]PreviewLocation
        let formatLocations = project.previewLocations.filter(e => e.name);
        setPreviewLocations(formatLocations);
    }, []);

    useEffect(() => {
        addMapSearchBox();
        if (map) {
            map.on('load', function () {
                map.resize();
            });
        }
    }, [map]);

    useEffect(() => {
        onSearchBoxResult();
    }, [geocoder]);

    useEffect(() => {
        onMarkerDragEnd();
    }, [marker])

    useEffect(() => {
        if (previewLocations) {
            let updateProject = project;
            // console.log("previewLocations", previewLocations)
            updateProject.previewLocations = previewLocations;
            createOrUpdateProject(updateProject);
        }
    }, [previewLocations])

    return (
        <React.Fragment>
            <Row className="h-100 g-0">
                <Col lg={6} className="pe-2">
                    <Box
                        background="light"
                        padding="XL"
                        borderColor="dark"
                        borderRadius="rounded"
                        className="h-100 border-opacity-10"
                    >
                        <Label
                            htmlFor="horizontal-firstname-Input"
                        >
                            Create & Edit Preview Location
                            <span className="text-muted text-label-desc ms-2">
                                (Maximum {maxPreviewLocations} locations)
                            </span>
                        </Label>
                        <div className="mb-2" ref={geocoderContainer}></div>
                        <Row className="mb-2">
                            <Col sm={6}>
                                <Label>
                                    Location Name
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control bg-white"
                                    id="project-place"
                                    placeholder="Preview Location Name"
                                    value={locationName}
                                    onChange={(e) => setLocationName(e.target.value)}
                                />
                            </Col>
                            <Col sm={3}>
                                <Label>
                                    Longitude
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control bg-white"
                                    id="project-longitude"
                                    placeholder="Longitude"
                                    value={locationCenter ? locationCenter.coordinates[0] : "0"}
                                    disabled
                                />
                            </Col>
                            <Col sm={3}>
                                <Label>
                                    Latitude
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control bg-white"
                                    id="project-latitude"
                                    placeholder="Latitude"
                                    value={locationCenter ? locationCenter.coordinates[1] : "0"}
                                    disabled
                                />
                            </Col>
                        </Row>

                        <div className="row mb-2">
                            <Col sm={12}>
                                <div ref={mapContainer} className="map-container-for-preview-locations" />
                            </Col>

                        </div>
                        <Button
                            color="primary"
                            block
                            onClick={onSaveNewLocations}
                            disabled={project.previewLocations.length > maxPreviewLocations || !locationName || !locationCenter}
                        >
                            Add preview location
                        </Button>
                    </Box>
                </Col>
                <Col lg={6} className="ps-2">
                    <Box
                        background="light"
                        padding="XL"
                        borderColor="dark"
                        borderRadius="rounded"
                        className="h-100 border-opacity-10"
                    >
                        <Label
                            htmlFor="horizontal-firstname-Input"
                            className="form-label"
                        >
                            Preview Locations ({previewLocations?.length}/{maxPreviewLocations})
                        </Label>
                        {previewLocations &&
                            <Box>
                                {previewLocations!.map((previewLocation, i) =>
                                    previewLocation.name &&
                                    <Box
                                        key={"preview-location-" + i}
                                        background="white"
                                        className="mb-2 p-2"
                                        borderRadius="rounded"
                                    >
                                        <p className="mb-1">{previewLocation.name ? previewLocation.name : "Location name"}</p>
                                        <p className="text-muted mb-0">
                                            {`Longitude: ${previewLocation.location2D.coordinates[0]
                                                ? previewLocation.location2D.coordinates[0]
                                                : "0"} | Latitude: ${previewLocation.location2D.coordinates[1]
                                                    ? previewLocation.location2D.coordinates[1]
                                                    : "0"}`}
                                        </p>
                                    </Box>

                                )}
                            </Box>
                        }
                    </Box>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default PreviewLocationSetting;