import { useEffect, useState } from 'react';
import { getMonthlyApiUsage, getGeoLogs, getHourlyUsage, getRawLogs } from 'apis/visualization'
import { Location2D } from '../Projects/data';
import { OrganizationSummary, getOrgSummary } from 'apis/organization';

export interface AggregatedData {
    groupId: number;
    dataCount: number;
}

export interface RequestLog {
    _id: String;
    projectRefId: string;
    timestamp: string;
    logType: string;
    actionType: string;
    arContentId: string;
    analyticId: string;
    positionGps: Location2D;
}

export const useApiUsageData = (organizationId: string): [AggregatedData[], boolean] => {

    const [apiDataState, setapiDataState] = useState<AggregatedData[]>([]);
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        const fetchDataFromApi = async () => {
            const obj = JSON.parse(localStorage.getItem("graffity-console-user")!);
            if (!obj.firebaseAuthUid) {
                return [];
            }

            try {
                setIsFetching(true);
                const apiData = await getMonthlyApiUsage(organizationId);
                setapiDataState(apiData);
                setIsFetching(false)
            } catch (e) {
                console.log(e);
                setIsFetching(false)
            }
        };

        if (!!organizationId) {
            fetchDataFromApi();
        }

    }, [organizationId]); // Runs once

    return [apiDataState, isFetching];
};

export const useHourlyUsageData = (organizationId: string): [AggregatedData[], boolean] => {
    // to duplicate for CTC specific usecase
    const [apiDataState, setapiDataState] = useState<AggregatedData[]>([]);
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        const fetchDataFromApi = async () => {
            try {
                setIsFetching(true);
                const apiData = await getHourlyUsage(organizationId);
                setapiDataState(apiData);
                setIsFetching(false)
            } catch (e) {
                console.log(e);
                setIsFetching(false)
            }
        };

        if (!!organizationId) {
            fetchDataFromApi();
        }

    }, [organizationId]); // Runs once

    return [apiDataState, isFetching];
};

export const useRawLogsData = (organizationId: string): [RequestLog[], boolean] => {

    const [rawLogsState, setRawLogsState] = useState<RequestLog[]>([]);
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        const fetchDataFromApi = async () => {
            try {
                setIsFetching(true);
                const rawLogsData = await getRawLogs(organizationId);
                setRawLogsState(rawLogsData);
                setIsFetching(false)
            } catch (e) {
                console.log(e);
                setIsFetching(false)
            }
        };

        if (!!organizationId) {
            fetchDataFromApi();
        }

    }, [organizationId]); // Runs once

    return [rawLogsState, isFetching];
};

export const useGeoLogsData = (organizationId: string): [RequestLog[], boolean] => {

    const [rawLogsState, setRawLogsState] = useState<RequestLog[]>([]);
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        const fetchDataFromApi = async () => {
            try {
                setIsFetching(true);
                const rawLogsData = await getGeoLogs(organizationId);
                setRawLogsState(rawLogsData);
                setIsFetching(false)
            } catch (e) {
                console.log(e);
                setIsFetching(false)
            }
        };

        if (!!organizationId) {
            fetchDataFromApi();
        }

    }, [organizationId]); // Runs once

    return [rawLogsState, isFetching];
};

export const useOrgSummaryData = (organizationId: string): [OrganizationSummary | undefined, boolean] => {

    const [apiDataState, setapiDataState] = useState<OrganizationSummary>();
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        const fetchDataFromApi = async () => {
            try {
                setIsFetching(true);
                const apiData = await getOrgSummary(organizationId);
                setapiDataState(apiData);
                setIsFetching(false)
            } catch (e) {
                console.log(e);
                setIsFetching(false)
            }
        };

        if (!!organizationId) {
            fetchDataFromApi();
        }

    }, [organizationId]); // Runs once

    return [apiDataState, isFetching];
};
