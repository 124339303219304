import { Navigate, Outlet, useLocation } from "react-router-dom"


export const PrivateRouteComponent = () => {
  let location = useLocation();

  if (!localStorage.getItem("graffity-console-user")) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return <Outlet />;
}

const PrivateRoute = PrivateRouteComponent

export { PrivateRoute }
